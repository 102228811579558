import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ISelector, ISitePageReference } from '../models/common';
import { IJobFilters, IJobOffer } from '../models/careers';
import { sleep } from './utilities.service';
import { TranslateService } from '@ngx-translate/core';

const OFFICES_TO_REMOVE = ["moscow", "mosca"];

const TRIBES__CORP_FUNCT = "Corporate Functions";

@Injectable({
  providedIn: 'root'
})
export class CareersService {

  private originalListFromApi?: { filters: IJobFilters; offers: IJobOffer[]; };
  private openPositions?: ISitePageReference[];
  private readonly selectors: ISelector[] = [
    { id: 'tribes', name: 'Tribes', options: [] },
    { id: 'professional_areas', name: 'Famiglie Professionali', options: [] },
    { id: 'location', name: 'Location', options: [] },
  ];

  private citiesListFromApi?: string[];

  loading: boolean = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly translate: TranslateService,
  ) { }

  private async originalList(): Promise<{ filters: IJobFilters; offers: IJobOffer[]; } | undefined> {
    if (this.loading) {
      await sleep(1000);
    }
    
    if (this.originalListFromApi) {
      return this.originalListFromApi;
    }

    this.loading = true;

    try {
      const [res1, res2] = await Promise.all([
        this.apiService.careers.list(),
        this.apiService.pages.jobOffers(),
      ]);
      this.originalListFromApi = res1;
      this.citiesListFromApi = res2?.filters?.find(f => f.id === "offices")?.items.map(i => i.label);
      return res1;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
    }

    return undefined;
  }

  public async list(): Promise<ISitePageReference[]> {
    if (!this.openPositions || !this.originalListFromApi) {
      const list = await this.originalList();
      if (list?.offers?.length) {
        this.openPositions = this.jobOffersToSitePageReferences(list.offers);
      }
    }
    
    return this.openPositions!;
  }

  public async filters(): Promise<ISelector[]> {
    if (!this.originalListFromApi) {
      await this.originalList();
    }

    this.selectors[0].options = this.originalListFromApi?.filters?.areas || [];
    this.selectors[1].options = this.originalListFromApi?.filters?.categories || [];

    // const cities = new Map();
    // for (const item of this.originalListFromApi?.offers || []) {
    //   if (!cities.has(item.subtitle)) {
    //     cities.set(item.subtitle, true);
    //     this.selectors[2].options.push({ name: item.subtitle });
    //   }
    // }
    this.selectors[2].options = this.citiesListFromApi?.map((city) => ({ name: city })) || [];

    /** forcing remove of some offices */
    this.selectors[2].options = this.selectors[2].options.filter(o => !OFFICES_TO_REMOVE.includes(o.name.toLowerCase()));

    this.selectors.forEach(selector => {
      switch (selector.id) {
        case "tribes": {
          selector.name = this.translate.instant('JOBS.TRIBES');

          /** forcing last position for "Corporate Functions" */
          const corporateFunctionsIndex = selector.options.findIndex(o => o.name === TRIBES__CORP_FUNCT);
          if (corporateFunctionsIndex !== -1) {
            const corporateFunctions = selector.options[corporateFunctionsIndex];
            selector.options.splice(corporateFunctionsIndex, 1);
            selector.options.sort((a, b) => a.name.localeCompare(b.name));
            selector.options.push(corporateFunctions!);
          }

          break;
        }
        case "professional_areas": selector.name = this.translate.instant('JOBS.PROFESSIONAL_AREAS'); break;
        case "location": selector.name = this.translate.instant('JOBS.LOCATION'); break;
        default: break;
      }
    });

    return this.selectors;
  }

  public async search(q: string): Promise<ISitePageReference[]> {
    try {
      const list = await this.apiService.careers.list({ q });
      
      return this.jobOffersToSitePageReferences(list.offers);
    } catch (ex) {
      console.error(ex);
    }

    return [];
  }

  public clearList() {
    this.originalListFromApi = undefined;
    this.openPositions = undefined;
  }

  private jobOffersToSitePageReferences(jobOffers: IJobOffer[]): ISitePageReference[] {
    const sitePageReferences: ISitePageReference[] = [];

    for (const item of jobOffers) {
      sitePageReferences.push({
        title: item.title,
        slug: item.id,

        jobArea: item.area,
        jobCategory: item.category,
        jobOffice: item.subtitle,
      });
    }

    return sitePageReferences;
  }
}
