import { Component, OnDestroy, OnInit } from '@angular/core';
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IJobOffer } from '../../../models/careers';
import { ApiService } from '../../../services/api.service';
import { deepClone, patchAnchors, scrollToTop } from '../../../services/utilities.service';
import { Subscription } from 'rxjs';
import { CareersService } from '../../../services/careers.service';
import { ISitePageMediaSection, ISitePageNews } from '../../../models/pages';
import { ISitePageReference } from '../../../models/common';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";
import { DISCOVER_OUR_TRIBE } from '../../../models/constants';

@Component({
  selector: 'app-open-positions-article-page',
  templateUrl: './open-positions-article-page.component.html',
  styleUrl: './open-positions-article-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SectionContactUsComponent, PromAnimatedButtonComponent, MediaSectionComponent],
})
export class OpenPositionsArticlePageComponent implements OnInit, OnDestroy {

  readonly categoryCssClass: string = "careers--open-position";

  get currentLang(): string {
    return this.translate.currentLang;
  }

  get discoverOurTribe(): ISitePageMediaSection {
    const res: ISitePageMediaSection = deepClone(DISCOVER_OUR_TRIBE);
    res.items[0].link!.route = `/${this.currentLang}${DISCOVER_OUR_TRIBE.items[0].link!.route}`;
    return res;
  }

  get jobID() {
    return this.activatedRoute.snapshot.paramMap.get('jobID');
  }

  activatedRouteParamMapSubscription?: Subscription;

  loading: boolean = false;
  pageDataErrorCode?: number;

  pageData?: IJobOffer;

  relatedOpenPositions?: ISitePageNews;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly api: ApiService,
    private readonly translate: TranslateService,
    private readonly careersService: CareersService,
  ) { }

  ngOnInit(): void {
    scrollToTop();
    
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('jobID')) {
        this.loadPage();
      }

      if (paramMap.has('lang')) {
        const lang = paramMap.get('lang');
        if (lang) {
          this.translate.use(lang);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
  }

  async loadPage() {
    if (!this.jobID) {
      console.error("No jobID");
      return;
    }

    this.loading = true;

    try {
      this.pageData = (await this.api.careers.get(+this.jobID))?.[0];
      // console.log(this.pageData);
      // console.log(this.pageData.body);

      this.formatPage();
      // this.patchAnchors();

      await this.loadRelated();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    } finally {
      this.loading = false;
      
      scrollToTop();
    }
  }

  async loadRelated() {
    try {
      const res: ISitePageReference[] = await this.careersService.list();
      // console.log(res);
      this.relatedOpenPositions = {
        items: (res.filter(x => x.jobArea === this.pageData?.area).splice(0, 4)) as any
      };
    } catch (ex) {
      console.error(ex);
    }
  }


  protected formatPage() {
    // this.formatMainTitle();
    this.formatBody();
  }

  private formatMainTitle() {
    if (!this.pageData?.title || !this.pageData?.title?.includes('&')) return;
    this.pageData.title = this.pageData.title?.replace(/ &/g, '<br>&');
    // console.log(this.pageData.title);
  }

  /** replace "h5" headers with "h2" headers */
  private formatBody() {
    if (!this.pageData?.body) return;
    this.pageData.body = this.pageData.body?.replace(/<h5/g, '<h2').replace(/<\/h5/g, '</h2');
  }
  
  private patchAnchors() {
    if (this.pageData?.body) {
      this.pageData.body = patchAnchors(this.pageData.body) ?? "";
    }
  }
}
