import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ISitePageCard } from '../../models/common';
import { PromCardComponent } from "../prom-card/prom-card.component";

@Component({
  selector: 'app-prom-cards-list',
  templateUrl: './prom-cards-list.component.html',
  styleUrl: './prom-cards-list.component.scss',
  standalone: true,
  imports: [PromCardComponent],
})
export class PromCardsListComponent implements OnInit, AfterViewInit {
  @Input() cards: ISitePageCard[] = [];
  @Input() size: 'full' | 'half' | 'third' = 'half';
  @Input() arrowColor: 'blue' | 'green' = 'blue';
  @Input() titleColor: 'default' | 'green' = 'default';
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';

  longestTitleIndex = -1;
  longestTitle = '';

  calculatedHeight: number = 150;

  ngOnInit(): void {
    this.cards?.forEach((item, index) => {
      if (item.title.length > this.longestTitle.length) {
        this.longestTitle = item.title;
        this.longestTitleIndex = index;
      }
    });
  }
    
  ngAfterViewInit() {
    const card = document.getElementById(`single-prom-card-${this.longestTitleIndex}`) as HTMLElement | null;
    if (card) {
      /* -50 to account for vertical padding */
      this.calculatedHeight = card.clientHeight - 50;
      // console.log("card's calculatedHeight:", this.calculatedHeight);
    }
  }
}
