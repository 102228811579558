import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../base-site-page.component';
import { ISitePage } from '../../models/pages';
import { PromAnimatedButtonComponent } from "../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafePipe } from '../../pipes/safe.pipe';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { CookieService } from '../../services/cookie.service';
import { PageNotFoundComponent } from "../page-not-found/page-not-found.component";
import { patchAnchors, sleep } from '../../services/utilities.service';
import { DialogContactComponent } from '../../components/dialog-contact/dialog-contact.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogNoticeComponent } from '../../components/dialog-notice/dialog-notice.component';
import { Debounce } from 'lodash-decorators';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrl: './article-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SafePipe, PromAnimatedButtonComponent, PageNotFoundComponent],
})
export class ArticlePageComponent extends BaseSitePageComponent {

  readonly categoryCssClass: string = "general-article";

  get whistleblowingLink() {
    return this.currentLang == 'it' ? 'https://www.prometeia.com/cdn/upload/2024/9/09bff654-41ec-4fb7-a28e-6d2b84b8fb82.pdf' : 'https://whistleblowing.prometeia.com/#/';
  }

  get whistleblowingLinkSIM() {
    return this.currentLang == 'it' ? 'https://www.prometeia.com/cdn/upload/2024/9/548a0900-6984-4702-99b8-df2636e5d530.pdf' : 'https://sim-whistleblowing.prometeia.com/#/';
  };

  get isSimPage(): boolean {
    const urlArray = window.location.href.replace(window.location.origin, '').split('/');
    return urlArray.includes('sim');
  }

  get routeDataScrollRequest() {
    return this.activatedRoute.snapshot.params?.['scrollTo'];
  }

  override pageData?: ISitePage & { body: string; attachment?: string; isContactPage?: boolean; contactUsEmail?: string };

  private routerSubscription?: Subscription;

  get pageButton() {
    switch (this.slug) {
      case 'ethics':
      case 'ethics-sim':
        return {
          label: {
            en: 'Model 231 - General Part',
            it: 'Modello 231 - Parte Generale',
          },
          attachment: this.isSimPage
            ? '/cdn2/site/sim/MODELLO 231 SIM PARTE GENERALE 19 dicembre 2022.pdf'
            : '/cdn2/site/general/Modello 231 - Parte Generale.pdf',
        };

      default:
        return undefined;
    }
  }

  constructor(
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly api: ApiService,
    protected override readonly translate: TranslateService,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    private readonly dialog: MatDialog,
    private readonly GA: GoogleAnalyticsService,
  ) {
    super(activatedRoute, api, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.router.url.includes('#')) {
      const hash = this.router.url.split('#')[1];
      this.scrollToAnchor(hash);
    } else if (this.routeDataScrollRequest) {
      this.scrollToAnchor(this.routeDataScrollRequest);
    }

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(event.urlAfterRedirects.includes("#")) {
          setTimeout(() => {
            window.scroll(0, window.scrollY - 200);
          }, 1);
        }
      }
    });
  }

  @Debounce(1)
  async scrollToAnchor(hash: string) {
    let tries = 0;
    while (tries < 10 && !document.querySelector(`#${hash}`)) {
      tries++;
      await sleep(100);
    }
    const element = document.querySelector(`#${hash}`);
    if (element) {
      window.scroll({ top: element.getBoundingClientRect().top + window.scrollY - 300, left: 0, behavior: 'smooth' });
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routerSubscription?.unsubscribe();
  }

  @Debounce(1)
  override async loadPage() {
    if (!this.slug) {
      return;
    }

    this.loading = true;
    try {
      this.pageData = await this.api.pages.getBySlug(this.slug);
      this.loading = false;

      this.formatPage();
      this.patchAnchors();

      if (this.pageData?.isContactPage) {
        this.openContactDialog();
      }
      this.trackWithGA();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    } finally {
      this.loading = false;
    }
  }

  private patchAnchors() {
    if (this.pageData?.body) {
      this.pageData.body = patchAnchors(this.pageData.body) ?? "";
    }
  }

  @Debounce(10)
  private trackWithGA() {
    if (!this.pageData) { return; }

    if (this.cookieService.canTrackWithGA()) {
      if (this.pageData.isContactPage) {
        const qs = new URLSearchParams(window.location.search);
        const queryParams = {
          campaign: qs.get('campaign') ?? undefined,
          leadSource: qs.get('trafficSrc') ?? undefined,
        };
        this.GA.trackEventViewItem(queryParams);
      }

      /** force page view (for some reason, it doesn't happen automatically) */
      this.GA.forcePageView();
    }
  }


  @Debounce(10)
  public async openContactDialog(theme: 'default' | 'yellow' | 'red' = 'default', contactUsEmail?: string) {
    const dialogRef = this.dialog.open(DialogContactComponent, {
      id: 'modal-dialog-contact',

      minWidth: 'min(750px, 90vw)',
      // maxWidth: '60%',

      height: 'auto',
      minHeight: '80%',
      maxHeight: '100%',

      data: { privacyPolicyLink: `/${this.translate.currentLang}/article/privacy-policy` },
    });
    dialogRef.afterClosed().subscribe(async (filledForm) => {
      if (filledForm) {
        try {
          await this.api.contacts.sendEmail({
            slug: this.slug ?? "",
            root: this.isSimPage ? "sim" : "",

            email: contactUsEmail ?? this.pageData?.contactUsEmail,

            form: filledForm,
          });

          this.dialog.open(DialogNoticeComponent, {
            id: 'modal-dialog-notice',

            data: {
              category: this.translate.instant('FORMS.THANKS_FOR_CONTACT'),
              title: this.translate.instant('FORMS.THANKS_NOTICE'),
            },
          });
        } catch (ex: any | { response: { data: { error: { name: string, status: number, code: string, message: string } } } }) {
          if (ex?.response?.data?.error?.message) {
            console.error(ex.response.data.error.message);
          } else {
            console.error(ex);
          }
        }
      }
    });
  }


  /** ARTICLE__COOKIE */
  reopenCookiesSettings() {
    setTimeout(() => {
      this.cookieService.forceReopenCookieModal = true;
    }, 1);
  }
}
