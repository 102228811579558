<!-- <ng-content></ng-content> -->

@if (list?.length) {
    <div class="accordion theme--{{theme}} bgc--{{bgColor}} padding--{{paddingSize}}">
        @for (item of list; track $index) {
            <div class="accordion-item default-card-border" [ngClass]="{'open': item.isOpen, 'closed': item.isOpen === false}">
                <div class="header" (click)="item.isOpen = !item.isOpen;">
                    <h4 class="title" [innerHTML]="item.title"></h4>

                    <button mat-icon-button class="accordion-toggle">
                        @if (item.isOpen) {
                            @switch (bgColor) {
                                @case ('deep-blue') {
                                    <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__white.svg" alt="" />
                                }
                                @default {
                                    <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__blue.svg" alt="" />
                                }
                            }
                        }
                        @else {
                            @switch (bgColor) {
                                @case ('deep-blue') {
                                    <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__white.svg" alt="" />
                                }
                                @default {
                                    <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__blue.svg" alt="" />
                                }
                            }
                        }
                    </button>
                </div>

                <div class="content">
                    @for (person of item.items; track $index) {
                        <div [ngClass]="{'person': true, 'no-avatar': !person.avatar}">
                            <div class="avatar-and-bio">
                                @if (person.avatar) {
                                    <div class="avatar-wrap">
                                        @if (person.avatar) {
                                            <img [src]="person.avatar" />
                                        }
                                    </div>
                                }
                    
                                <div class="text-column">
                                    <p class="name-and-job-position">
                                        <span class="name">{{person.name}}</span>
                                        @if (person.position) {
                                            <span class="job-position">, {{person.position}}</span>
                                        }
                                    </p>
                                    @if (person.body?.startsWith('<p>')) {
                                        <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}" [innerHTML]="person.body"></div>   
                                    }
                                    @else {
                                        <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}"><p [innerHTML]="person.body"></p></div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}

