import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prom-borded-button',
  templateUrl: './prom-borded-button.component.html',
  styleUrl: './prom-borded-button.component.scss',
  standalone: true,
  imports: [],
})
export class PromBordedButtonComponent {
  @Input() label?: string;
  @Input() url?: any;
  @Input() arrow?: 'down' | 'right' | 'none' = 'none';
}
