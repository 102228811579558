export enum LocaleEnum {
    IT = 'it',
    EN = 'en'
}

export const SUPPORTED_LANGS = Object.values(LocaleEnum);

export const DEFAULT_LANG = LocaleEnum.IT;

export enum NewsCategoriesEnum {
    Ideas = 'ideas',
    News = 'news',
    CaseStudies = 'case-studies',
    Events = 'events',
    Awards = 'awards',
}

export enum NewsTypesEnum {
    Article = 'article',
    Video = 'video',
    Podcast = 'podcast',
    Report = 'report',
    Dataviz = 'dataviz',
}

export enum NewsTypesWithIconEnum {
    Video = 'video',
    Podcast = 'podcast',
    Report = 'report',
    Dataviz = 'dataviz',
}

export type ButtonTheme = 'deep-blue' | 'fluo-green' | 'fluo-yellow' | 'sim-red' | 'sim-white';


export interface ISitePageLink {
    label: string;
    url?: string;
    route?: string;
}

export interface ISitePageCard {
    title: string;
    body: string;
    link?: ISitePageLink;
    download?: ISitePageLink;
}

export interface ISitePageFlippingCard {
    title: string;
    body: string;
    cover?: string;
}

export interface ISitePageReference {
    slug: string;
    title: string;
    subtitle?: string;


    /** for Careers */

    jobArea?: string;
    jobCategory?: string;
    jobOffice?: string;
}

export interface IMediaSectionItem {
    type: 'text' | 'video' | 'image' | 'text+image' | 'text+link' | 'insight' | 'insight-list' | 'full-section' | 'image-list';
    subTheme?: 'reversed'; // "reversed" for "full-section"

    /** default: "full" */
    size?: 'half' | 'full' | 'third';
    
    title?: string;
    body?: string;

    download?: ISitePageLink;

    /** external URL or internal route */
    link?: ISitePageLink;

    /** video or image URL */
    url?: string;

    /** image URL (used in "full-section" type) */
    imageUrl?: string;
    
    /** cover image for video in URL */
    cover?: string;

    /** for insights */
    insight?: INewsReference;

    /** for customers > "Training" section */
    contactFormEmail?: string;

    
    theme?: 'white' | 'deep-blue' | 'fluo-green' | 'electric-blue' | 'sim-red';
}

export interface IMediaListSectionItem {
    type?: "image-list";
    
    title: string;
    body: string;
    items: {
        image: string,
        route?: string,
        url?: string,
    }[];
}

export interface INewsReference extends ISitePageReference {
    category: string;
    types?: NewsTypesWithIconEnum[];

    heroImage?: string;
    eventDate?: Date;
    publicationDate?: Date;
}

export interface INewslettersItem {
    title: string;
    slug: string;
    body: string;
    img: string;

    isOpen?: boolean;
}

export interface ISelectorOptions {
    name: string;
    code?: number;
    selected?: boolean;
}

export interface ISelector {
    id?: string;
    name: string;
    options: ISelectorOptions[];
}

export interface ICarouselItem {
    quote: string;
    label: string;
    details: string;
    link?: ISitePageLink;
    cover: string;
}

export interface IPersonBio {
    name: string;
    firstName?: string;
    lastName?: string;

    position?: string;
    avatar?: string;
    body?: string;

    /**
     * used in "About Us > Our World" (Leadership Team),
     * 
     * here some people can have a short bio;
     * 
     * if "biographies" is set, "body" (see above) is ignored
    */
    biographies?: {
        avatar?: string;
        name: string;
        body?: string;
    }[];
}

export interface IPeopleGroupBios {
    title: string;
    items: IPersonBio[];

    /** if true, all items are collapsed (only avatar and name are visible) */
    collapsed?: boolean;
}

export interface IPeopleSection {
    title: string;
    body: string;
    imageUrl?:string;
    items: IPeopleGroupBios[];
}
