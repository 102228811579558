<div class="ar-dialog-auth">
    <button class="icon-close" mat-icon-button (click)="onNoClick()"><mat-icon aria-hidden="false" aria-label="close" fontIcon="close"></mat-icon></button>

    <h2 class="neutra2_book text--primary ar-dialog-auth--title">{{data.title}}</h2>

    <app-alumni-auth [pool]="pool"></app-alumni-auth>
    @if(data.textPrivacy){
        <p class="neutra2_book text--on-light ar-dialog-auth--text"  [innerHtml]="data.textPrivacy | safe:'html'"></p>
    }
</div>
        
