<div class="footer">
    <div class="footer-content footer-content--bottom flex-row flex-row--space-between">
        <!-- <div class="logo-wrap">
            <img class="logo" src="/assets/images/Prometeia50_Sim_NEG.png" [routerLink]="['/sim/' + currentLang]"/>
        </div> -->
        
        <div class="copyright" style="margin-left: 40px;">
            <p>{{currentYear}} © Prometeia All rights reserved</p>
            <p>Prometeia s.p.a. P.IVA 03118330376</p>
        </div>

        <div class="flex-row">
            @if (footerData?.length) {
                <div class="footer-links">
                    @for (footerLink of footerData; track $index) {
                        <p class="grid-item">
                            <a href="/sim/{{currentLang}}/{{footerLink.route}}" target="_blank">{{footerLink.label}}</a>
                            <img src="/assets/icons/external_link__red.svg" alt="" class="external-link">
                        </p>
                    }

                    @if (currentLang === 'it') {
                        @for (footerLink of itaFooterData; track $index) {
                            <p class="grid-item">
                                @if (footerLink.route) {
                                    <a href="/{{currentLang}}/{{footerLink.route}}" target="_blank">{{footerLink.label}}</a>
                                    <img src="/assets/icons/external_link__red.svg" alt="" class="external-link">
                                }
                                @else if (footerLink.url) {
                                    <a href="{{footerLink.url}}" target="_blank" download>{{footerLink.label}}</a>
                                    <img src="/assets/icons/down_arrow__red.svg" alt="" class="external-link">
                                }
                            </p>
                        }
                    }
                </div>
            }

            <div class="award-logo-wrap">
                <img class="logo" src="/cdn2/site/general/LEED_badge_NEG.png"/>
            </div>

            <div class="actions">
                <div class="scroll-to-top" (click)="scrollToTop()">
                    <div class="scroll-to-top--icon arrow">
                        <img class="simplified-arrow-up" src="/assets/icons/down_simplified_arrow__red.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile-footer">
    <div class="footer-content">
        <!-- <div class="logo-wrap">
            <img class="logo" src="/assets/images/Prometeia50_Sim_NEG.png" [routerLink]="['/sim/' + currentLang]"/>
        </div> -->

        <div style="margin-top: 40px;">
            <div class="btn-container reserved-area">
                <a [href]="arURL" target="_blank">
                    <app-prom-animated-button [arrow]="'top-right'" [bgColor]="'dark'" [theme]="'sim-red'">
                        {{'AR.TITLE' | translate | uppercase}}
                    </app-prom-animated-button>
                </a>
            </div>
        </div>

        @if (footerData?.length) {
            <div class="footer-links">
                @for (footerLink of footerData; track $index) {
                    <p>
                        <a href="/{{currentLang}}/{{footerLink.route}}" target="_blank">{{footerLink.label}}</a>
                        <img src="/assets/icons/external_link__red.svg" alt="" class="external-link">
                    </p>
                }
            </div>
        }

        <div class="award-logo-wrap">
            <img class="logo" src="/cdn2/site/general/LEED_badge_NEG.png"/>
        </div>

        <div class="copyright">
            <p>{{currentYear}} © Prometeia All rights reserved</p>
            <p>Prometeia s.p.a. P.IVA 03118330376</p>
        </div>
    </div>
</div>
