import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestClientService } from './rest-client.service';
import { AxiosInstance } from 'axios';
import { IARAttachment } from '../models/reservedArea';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiArService {
    public offlineException: EventEmitter<HttpErrorResponse> = new EventEmitter();
    public serverErrorException: EventEmitter<HttpErrorResponse> = new EventEmitter();
    private restClient?: AxiosInstance;

    get isRestClientReady(): boolean {
        return this.restClient != undefined;
    }
    constructor(private readonly http: HttpClient,
        private readonly restClientService: RestClientService,) {
            this.init();
    }

    public async init() {
        await this.restClientService.defaultInit();
    //    this.restClientService.init({ baseUrl:'https://2024.prometeia.3logic.it'+environment.api.baseEndpoint });
        this.restClient = this.restClientService.restClient;
    }

    public libraries = {
        login: (token: string) => this.restClient?.post(`/ar/login`, { token: token }),
        logout: () => this.restClient?.post(`/ar/logout`, {}),
        get: <T>(slug: string) => this.restClient?.get<T>(`/libraries/${slug}`),
        getContent: (folder: string) => this.restClient?.get<IARAttachment[]>(`/libraries/ar/folders/content/${folder}`),
    }

}