@if (news?.length) {
    <section class="news-headlines bgc--{{bgColor}}">
        <div class="news-cards" data-aos="fade-up" [attr.data-aos-offset]="dataAosOffset">
            @for (newsItem of news; track $index) {
                <a class="unstyled-anchor" [routerLink]="['/' + currentLang + '/' + articleRoot + '/' + newsItem.slug]">
                    <div class="news-card news-category--{{newsItem.category | kebabCase}}">
                        @if (newsItem.heroImage) {
                            <div class="news-bg">
                                <img src="{{newsItem.heroImage}}" alt="{{newsItem.title}}">
                            </div>
                        }
                        @else if (newsItem.category === 'events') {
                            <div class="news-bg">
                                <img src="/cdn2/site/insights/news-events-bg.png" alt="{{newsItem.title}}">
                            </div>
                        }
                        <div [ngClass]="{'news-colored-filter': true, 'no-image': !newsItem.heroImage}"></div>
                        <div class="news-border"></div>
    
                        <div class="news-info">
                            <h5 class="news-info--category">{{newsItem.category.replace('-', ' ') | uppercase}}</h5>
                            <h4>{{newsItem.title}}</h4>
                            @if (newsItem.category === 'events') {
                                <h5>{{newsItem.eventDate | date: ('DATE_FORMAT_W_SPACE' | translate)}}</h5>
                            }
                            @else {
                                <h5 class="news-info--pub-date">{{newsItem.publicationDate | date: ('DATE_FORMAT_W_SPACE' | translate)}}</h5>
                            }
                        </div>
    
                        <div class="footer">
                            @if (newsItem.types?.length) {
                                <div class="news-icons flex-row">
                                    @for (icon of newsItem.types; track $index) {
                                        @if (supportedIcons.includes(icon)) {
                                            <img class="icon icon-white" src="/assets/icons/insights/Type_{{icon | uppercase}}_NEG.png" alt="{{icon}}-icon">
                                            <img class="icon icon-blue" src="/assets/icons/insights/Type_{{icon | uppercase}}_POS.png" alt="{{icon}}-icon">
                                        }
                                    }
                                </div>
                            }
        
                            <div class="go-to-news">
                                <img class="arrow arrow-blue" src="/assets/icons/right_arrow__blue.svg" alt="">
                                <img class="arrow arrow-green" src="/assets/icons/right_arrow__green.svg" alt="">
                                <img class="arrow arrow-white" src="/assets/icons/right_arrow__white_large.svg" alt="">
                            </div>
                        </div>
                    </div>
                </a>
            }
        </div>

        @if (showSeeMoreButton) {
            <div class="btn-container">
                <a class="unstyled-anchor" [routerLink]="['/' + currentLang, 'about-us', 'insights']">
                    <app-prom-animated-button [arrow]="'right'" [theme]="bgColor === 'white' ? 'deep-blue' : 'fluo-green'" [bgColor]="bgColor === 'white' ? 'light' : 'dark'">
                        {{'GENERAL.SEE_MORE' | translate | uppercase}}
                    </app-prom-animated-button>
                </a>
            </div>
        }
    </section>
}
