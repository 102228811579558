@if (item) {
    <div class="cookie-detail">
        <table>
            <tbody>
                <tr>
                    <td class="open-state-cell interactive" (click)="isOpen = !isOpen">
                        @if (!isOpen) {
                            <mat-icon class="toggle">expand_more</mat-icon>
                        }
                        @else {
                            <mat-icon class="toggle">expand_less</mat-icon>
                        }
                    </td>
                    <td class="company font__inter_bold interactive" (click)="isOpen = !isOpen">
                        {{item.company}}
                    </td>
                    <td class="slide-toggle-cell">
                        <div class="mat-slide-container">
                            <mat-slide-toggle [(ngModel)]="item.accepted" [disabled]="item.id == 'COOKIE_MAIN'" (change)="slideValChange()"></mat-slide-toggle>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        @if (isOpen) {
            <table class="details">
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>LOCAL:{{item.id}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'COOKIES.DOMAIN' | translate }}</td>
                        <td>{{item.domain}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'COOKIES.MORE_INFO' | translate }}</td>
                        <td>
                            @for (link of item.links; let linkIndex = $index; track linkIndex) {
                                <a class="text--primary" [href]="link.url" target="_blank">{{link.label}}</a>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">{{item.description}}</td>
                    </tr>
                </tbody>
            </table>
        }
    </div>
}
