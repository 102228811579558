@if (card) {
    <!-- EXTERNAL LINK -->
    @if (this.card.link?.url) {
        <a class="unstyled-anchor" href="{{card.link!.url}}" target="_blank">
            <div id="single-prom-card-{{cardIndex}}{{sectionID ? '-' + sectionID : ''}}"
                class="p-card interactive default-card-border size-{{size}} theme--{{theme}}"
                [ngClass]="{
                    'forced-card-height': forcedCardHeight !== 'auto',
                    'with-arrow': card.download || size === 'full',
                    'with-external-link': card.link && card.link.url
                }"
                [ngStyle]="{'height': forcedCardHeight + 'px'}"
                data-aos="fade-up"
            >
                <ng-container [ngTemplateOutlet]="pCardContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
            </div>
        </a>
    }
    <!-- INTERNAL ROUTE -->
    @else if (this.card.link?.route) {
        <a class="unstyled-anchor" [routerLink]="card.link!.route">
            <div id="single-prom-card-{{cardIndex}}{{sectionID ? '-' + sectionID : ''}}"
                class="p-card interactive default-card-border size-{{size}} theme--{{theme}}"
                [ngClass]="{
                    'forced-card-height': forcedCardHeight !== 'auto',
                    'with-arrow': card.download || size === 'full',
                    'with-external-link': card.link && card.link.url
                }"
                [ngStyle]="{'height': forcedCardHeight + 'px'}"
                data-aos="fade-up"
            >
                <ng-container [ngTemplateOutlet]="pCardContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
            </div>
        </a>
    }
    <!-- SIMPLE CARD (no link, no route) -->
    @else {
        <div id="single-prom-card-{{cardIndex}}{{sectionID ? '-' + sectionID : ''}}"
            class="p-card default-card-border size-{{size}} theme--{{theme}}"
            [ngClass]="{
                'forced-card-height': forcedCardHeight !== 'auto',
                'with-arrow': card.download || size === 'full',
                'with-external-link': card.link && card.link.url
            }"
            [ngStyle]="{'height': forcedCardHeight + 'px'}"
            data-aos="fade-up"
        >
            <ng-container [ngTemplateOutlet]="pCardContent" [ngTemplateOutletContext]="{ card: card }"></ng-container>
        </div>
    }
}


<!-- CARD CONTENT TEMPLATE -->
<ng-template #pCardContent let-card='card'>
    <div class="p-card--header">
        <h4 class="p-card--title text--{{titleColor}}">{{card.title}}</h4>
    </div>
    
    <div class="p-card--content">
        @if (card.body.trim().startsWith('<p>')) {
            <div class="inner-html--p-card--body p-card--body" [innerHTML]="card.body"></div>
        }
        @else {
            <div class="inner-html--p-card--body p-card--body"><p [innerHTML]="card.body"></p></div>
        }
    </div>

    @if (card.download || size === 'full') {
        <div class="p-card--footer">
            @if (card.download) {
                <div class="download-icon download-icon-{{arrowColor}}">
                    <div class="arrow-wrap">
                        <a [href]="card.download.url" target="_blank" [download]="card.download.label">
                            <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="download_arrow">
                        </a>
                    </div>
                </div>
            }

            @if (card.link && card.link.url) {
                <div class="external-link-icon external-link-icon-{{arrowColor}}">
                    <div class="arrow-wrap">
                        <a [href]="card.link.url" target="_blank">
                            <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="external_link_arrow">
                        </a>
                    </div>
                </div>
            }
            @else if (card.link && card.link.route) {
                <div class="internal-route-icon internal-route-icon-{{arrowColor}}">
                    <div class="arrow-wrap">
                        <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="internal_route_arrow">
                    </div>
                </div>
            }
        </div>
    }
</ng-template>
