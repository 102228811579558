@if (card) {
    <div class="flipping-card" id="single-flipping-card-{{cardIndex}}" data-aos="fade-up">
        <div class="front-face">
            <img [src]="card.cover" alt="card_image">
            <h4 class="card-title" [innerHTML]="cardFrontTitle"></h4>
        </div>
    
        <div class="back-face">
            <h4 class="card-title" [innerHTML]="card.title"></h4>
            
            @if (card.body.trim().startsWith('<p>')) {
                <div class="card-body" [innerHTML]="card.body"></div>
            }
            @else {
                <div class="card-body"><p [innerHTML]="card.body"></p></div>
            }
        </div>
    </div>
}
