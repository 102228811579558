import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ISitePageReference } from '../../models/common';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-routes-to-pages',
  templateUrl: './routes-to-pages.component.html',
  styleUrl: './routes-to-pages.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class RoutesToPagesComponent implements OnInit, AfterViewInit {
  @Input() items?: ISitePageReference[];
  @Input() baseRoute?: string;
  @Input() size: 'full' | 'thirds' = 'full';
  @Input() arrowColor: 'blue' | 'green' | 'yellow'  = 'green';
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';

  longestTitleIndex = -1;
  longestTitle = '';

  private _calculatedHeight: number = 150;

  set calculatedHeight(value: number) {
    this._calculatedHeight = value;
  }

  get calculatedHeight(): string {
    return this._calculatedHeight + "px";
  };

  ngOnInit(): void {
    /** if the theme is yellow, force the arrow color to yellow too */
    if (this.theme === 'yellow') {
      this.arrowColor = 'yellow';
    }

    this.items?.forEach((item, index) => {
      if (item.title.length > this.longestTitle.length) {
        this.longestTitle = item.title;
        this.longestTitleIndex = index;
      }
    });
  }
    
  ngAfterViewInit() {
    const card = document.getElementById(`single-route-card-${this.longestTitleIndex}`) as HTMLElement | null;
    if (card) {
      /* -100 to account for vertical padding */
      this.calculatedHeight = card.clientHeight - 100;
    }
  }
}
