@if (!loading && pageData) {
    <div class="page about-us--social-responsibility">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content">
                    <div class="category"><h2>Our Team</h2></div>
                    <div class="title"><h1>Lorem ipsum dolor sit amet, consectetuer</h1></div>
                    <!-- <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div> -->
                </div>

                <app-long-arrow-down></app-long-arrow-down>
            </section>

            @if (pageData.peopleSection) {
                @for (peopleGroup of pageData.peopleSection.items; track $index) {
                    <section class="people-group">
                        <div class="section-content">
                            <h3>{{peopleGroup.title}}</h3>

                            <div [ngClass]="{'people-list': true, 'collapsed': peopleGroup.collapsed, 'expanded': !peopleGroup.collapsed}">
                                @for (person of peopleGroup.items; track $index) {
                                    @if (peopleGroup.collapsed) {
                                        <div class="person interactive" (click)="onPersonClick(person)">
                                            <div class="avatar-wrap">
                                                <img [src]="person.avatar" />
                                            </div>
                                            <p class="name-and-job-position">
                                                <span class="name">{{person.name}}</span>
                                                @if (person.position) {
                                                    <span class="job-position">, {{person.position}}</span>
                                                }
                                            </p>
                                        </div>
                                    }
                                    @else {
                                        <div class="person">
                                            <div class="avatar-and-bio">
                                                @if (person.avatar) {
                                                    <div class="avatar-wrap">
                                                        @if (person.avatar) {
                                                            <img [src]="person.avatar" />
                                                        }
                                                    </div>
                                                }
                
                                                <div>
                                                    <p class="name-and-job-position">
                                                        <span class="name">{{person.name}}</span>
                                                        @if (person.position) {
                                                            <span class="job-position">, {{person.position}}</span>
                                                        }
                                                    </p>
                                                    @if (person.body?.startsWith('<p>')) {
                                                        <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}" [innerHTML]="person.body"></div>   
                                                    }
                                                    @else {
                                                        <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}"><p [innerHTML]="person.body"></p></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    </section>
                }
            }
        </div>
    </div>
}
