import { Component } from '@angular/core';
import { scrollToTop } from '../../services/utilities.service';
import { ISitePageLink } from '../../models/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer-sim',
  templateUrl: './footer-sim.component.html',
  styleUrl: './footer-sim.component.scss',
  standalone: true,
  imports: [RouterModule],
})
export class FooterSimComponent {

  footerData?: ISitePageLink[];
  itaFooterData?: ISitePageLink[];

  get currentLang(): string {
    return this.translate.currentLang;
  }

  currentYear = new Date().getFullYear();

  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadFooterData();
  }

  private async loadFooterData() {
    /**
      Company Information
      Code of Ethics & Model 231
      Whistleblowing
      Privacy Policy
      Cookies settings and policy
      Statuto (voce da inserire solo nella versione ITA, al link si apre direttamente il pdf sempre in una nuova scheda)
    */
    this.footerData = [ //mock data
      { label: "Company Information", route: "article/company-information-sim" },
      { label: "Code of Ethics & Model 231", route: "article/ethics" },
      { label: "Whistleblowing", route: "article/whistleblowing-prometeia-sim" },
      { label: "Privacy Policy", route: "article/privacy-policy-sim" },
      { label: "Cookies settings and policy", route: "article/cookies" },
    ]
    this.itaFooterData = [ //mock data
      { label: "Statuto", url: "https://www.prometeia.3logic.it/cdn/FILES/footer/Statuto%2028_04_2023.pdf" },
    ]
  }

  scrollToTop() {
    scrollToTop({behavior: "smooth"});
  }
}
