@if (!loading && pageData) {
    <div class="page about-us--social-responsibility">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    
                    <div class="spontaneous-application-btn">
                        <a [href]="pageData.report.url" target="_blank">
                            <app-prom-animated-button class="button" [theme]="'fluo-green'" [bgColor]="'dark'" [arrow]="'down'">
                                {{pageData.report.label | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                </div>

                <app-long-arrow-down></app-long-arrow-down>
            </section>

            @if (pageData.carousel) {
                <section class="carousel">
                    <!-- <app-carousel [slides]="pageData.carousel.items" [quotationMarkColor]="'green'"></app-carousel> -->
                    <app-quote-section [slide]="pageData.carousel.items[0]" [quotationMarkColor]="'green'"></app-quote-section>
                </section>
            }

            <section class="values">
                <div class="section-content">
                    <div class="title"><h3 [innerHTML]="pageData.values.title"></h3></div>
                    
                    @if (pageData.values.items.length) {
                        <div class="values-list" data-aos="fade-up">
                            @for (item of pageData.values.items; track $index) {
                                <div class="values-list-item">
                                    <app-image-card [card]="item" [borderColor]="'electric-blue'" [imageSize]="'small'" [cardHeight]="'tall'" [useMarginInBody]="true"></app-image-card>
                                </div>
                            }
                        </div>
                    }
                </div>
            </section>

            <section class="epc environment">
                <div class="epc-background"></div>
                <div class="epc-colored-filter"></div>

                <div class="section-content">
                    <div class="title"><h3 [innerHTML]="pageData.environment.title ?? 'Environment'"></h3></div>
                    
                    @if (pageData.environment.body.startsWith('<p>')) {
                        <div class="body" [innerHTML]="pageData.environment.body"></div>
                    }
                    @else {
                        <div class="body"><p [innerHTML]="pageData.environment.body"></p></div>
                    }

                    @if (pageData.environment.items) {
                        <app-prom-cards-list [cards]="pageData.environment.items" [size]="'third'" [arrowColor]="'green'" [titleColor]="'green'"></app-prom-cards-list>
                    }
                </div>
            </section>

            <section class="epc people">
                <div class="epc-background"></div>
                <div class="epc-colored-filter"></div>

                <div class="section-content">
                    <div class="title"><h3 [innerHTML]="pageData.people.title ?? 'People'"></h3></div>
                    
                    @if (pageData.people.body.startsWith('<p>')) {
                        <div class="body" [innerHTML]="pageData.people.body"></div>
                    }
                    @else {
                        <div class="body"><p [innerHTML]="pageData.people.body"></p></div>
                    }

                    @if (pageData.people.items) {
                        <app-prom-cards-list [cards]="pageData.people.items" [size]="'third'" [arrowColor]="'green'" [titleColor]="'green'"></app-prom-cards-list>
                    }
                </div>
            </section>

            <section class="epc community">
                <div class="epc-background"></div>
                <div class="epc-colored-filter"></div>

                <div class="section-content">
                    <div class="title"><h3 [innerHTML]="pageData.community.title ?? 'Community'"></h3></div>
                    
                    @if (pageData.community.body.startsWith('<p>')) {
                        <div class="body" [innerHTML]="pageData.community.body"></div>
                    }
                    @else {
                        <div class="body"><p [innerHTML]="pageData.community.body"></p></div>
                    }

                    @if (pageData.community.items) {
                        <app-prom-cards-list [cards]="pageData.community.items" [size]="'third'" [arrowColor]="'green'" [titleColor]="'green'"></app-prom-cards-list>
                    }
                </div>
            </section>

            <section class="esg">
                <div class="section-content">
                    <h3 class="text--green" [innerHTML]="pageData.community.title ?? 'Our ESG Expertise & Solutions'"></h3>

                    @if (pageData.esg.body.startsWith('<p>')) {
                        <div class="body" data-aos="fade-up" [innerHTML]="pageData.esg.body"></div>
                    }
                    @else {
                        <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.esg.body"></p></div>
                    }
                    
                    @if (pageData.esg.items) {
                        <app-prom-cards-list [cards]="pageData.esg.items" [size]="'full'" [arrowColor]="'green'"></app-prom-cards-list>
                    }
                </div>
            </section>

            <section class="end-banner">
                <div class="section-content">
                    <div class="title"><h3 [innerHTML]="endBanner.title"></h3></div>

                    <div class="button">
                        <a href="{{endBanner.download?.url}}" target="_blank" download>
                            <app-prom-animated-button class="button" [theme]="'fluo-green'" [bgColor]="'dark'" [arrow]="'down'">
                                {{'Download' | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    </div>
}
