import pkg from '../../package.json';

/** STAGING */
export const environment = {
    production: true,
    appVersion: pkg.version + '-s',
    api: {
        baseEndpoint: '/api/web',
        appEndpoint: 'https://ws-atlante-career.prometeia.3logic.it/corporate',
        // _appEndpoint: 'https://wsapp.prometeia.com/corporate',
    },
    aws: {
        ar: {
            CONFIG_COGNITO_ALUMNI: require('../aws-config/dev/aws-export-alumni.json'),
            CONFIG_COGNITO_AR : require('../aws-config/dev/aws-export-AR.json'),
        }
    },
    GoogleAnalytics: {
      TRACKING_CODE: 'G-KF0Z2EZ89L'
    }
};
