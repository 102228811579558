@if (!loading && pageData) {
    <div class="page careers--our-tribe">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.title"></h2></div>
                    <div class="title"><h1>Join Our Tribe</h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <app-long-arrow-down [theme]="'fluo-yellow'"></app-long-arrow-down>
            </section>

            @if (pageData.numbersSection) {
                @let promAll = pageData.numbersSection;
                <section class="prom-all" [ngStyle]="{'background-image': 'url(' + 'https://www.prometeia.com/cdn/careers/PromOgni_BG.png' + ')'}">
                    <div class="section-content">
                        <div class="flex-row">
                            <div class="text-block">
                                @if (promAll.title) {
                                    <h3>
                                        @for (s of promAll.title.split('∀'); track $index) {
                                            @if (s === '') {
                                                <span class="all-symbol text--yellow">∀</span>
                                            }
                                            @else {
                                                <span>{{s}}</span>
                                            }
                                        }
                                    </h3>
                                }
                                <div class="body" data-aos="fade-up"><p [innerHTML]="promAll.body"></p></div>
                            </div>
    
                            <div class="numbers-block">
                                @for (item of promAll.items; track $index) {
                                    <div class="numbers--item" data-aos="fade-up">
                                        <h1 class="value text--yellow" [innerHTML]="item.value"></h1>
                                        <h4 class="label" [innerHTML]="item.label"></h4>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }

            @if (pageData.carousel) {
                <section class="carousel">
                    <app-carousel [slides]="pageData.carousel.items"></app-carousel>
                </section>
            }

            @if (pageData.body) {
                <section class="our-tribes">
                    <div class="section-content">
                        <h3>Our Tribes</h3>
                        @if (pageData.body.startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.body"></p></div>
                        }
                        
                        @if (pageData.cards) {
                            <!-- <app-section-reports-list [reports]="pageData.flippingCards.items"></app-section-reports-list> -->
                            <div class="flipping-cards">
                                @for (card of pageData.flippingCards.items; track $index) {
                                    <div class="flipping-cards--card">
                                        <app-flipping-card [card]="card"></app-flipping-card>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </section>
            }

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection" data-aos="fade-up"></app-media-section>
                </section>
            }

            @if (pageData.cards) {
                <section class="value-proposition">
                    <div class="section-content">
                        <h3>Value Proposition</h3>
                        <app-prom-cards-list [cards]="pageData.cards.items" [size]="'third'" [theme]="'yellow'"></app-prom-cards-list>
                    </div>
                </section>
            }

            @if (openPositions) {
                <section class="open-positions">
                    <div class="section-content">
                        <h3>Open Positions</h3>

                        <!-- <div class="selectors-container">
                            <app-selector-ship [selectors]="selectors" [theme]="'careers'" (selectionChangedEvent)="onOpenPositionsFiltersSelectionChanged($event)" (stringSearchEvent)="onStringSearch($event)" (clearSearchEvent)="onClearSearch()"></app-selector-ship>
                        </div> -->

                        @if (openPositions.length) {
                            <app-routes-to-pages [baseRoute]="'careers/open-positions/'" [items]="openPositions" [size]="'full'" [theme]="'yellow'"></app-routes-to-pages>
                        }
                        @else if (!loadingOpenPositions) {
                            <h3 class="empty-list">{{'EMPTY_LISTS.EMPTY' | translate}}</h3>
                        }

                        @if (loadingOpenPositions) {
                            <div class="dots">
                                <app-dots-loader></app-dots-loader>
                            </div>
                        }

                        <div class="see-more">
                            <app-prom-animated-button [theme]="'fluo-yellow'" [bgColor]="'dark'" [arrow]="'right'" [routerLink]="['/' + currentLang, 'careers', 'open-positions']">
                                {{'GENERAL.SEE_MORE' | translate | uppercase}}
                            </app-prom-animated-button>
                        </div>
                    </div>

                </section>
            }

            @if (pageData.mediaSectionBottom) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSectionBottom" data-aos="fade-up"></app-media-section>
                </section>
            }

            <app-section-contact-us [theme]="'yellow'" [text]="'Any question?<br>Get in touch with our team!'"></app-section-contact-us>
        </div>
    </div>
}
