import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from '../services/cookie.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Sanitize HTML
 */
@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform {
    /**
     * Pipe Constructor
     *
     * @param _sanitizer: DomSanitezer
     */
    // tslint:disable-next-line
    constructor(
        protected readonly _sanitizer: DomSanitizer,
        private readonly cookieService: CookieService,
        private readonly translate: TranslateService,
    ) {}

    /**
     * Transform
     *
     * @param value: string
     * @param type: string
     */
    transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        // console.log("pipe", type, value);

        const enableSpreakerPlayer: boolean = false;

        switch (type) {
            case 'html':
                let v = value;
                // if (false) {
                //     v = v.replace(/<iframe\s+.*?\s+src=(".*?").*?<\/iframe>/g, "<p>" + "CONTENT_NOT_AVAIBLE" + "</p>");
                // }

                /** Tableau (if cookies are not setted, the content will be hidden) */
                if (v && !this.cookieService.canShowTableau()) {
                    const msg = this.translate.instant("SAFE_PIPE.TABLEAU_NOT_AVAILABLE");
                    v = v.replace(/<iframe class="tableauViz"\s+.*?\s+src=(".*?").*?<\/iframe>/g, "<p><i>*" + msg + "*</i></p>");
                }

                /** YouTube (replace YT url with the "no-cookie" version) */
                if (v) {
                    v = v.replace(/src="https:\/\/www.youtube.com\/embed\//g, `src="https://www.youtube-nocookie.com/embed/`);
                }

                /** Spreaker */
                if (enableSpreakerPlayer && v && v.startsWith('https://www.spreaker.com')) {
                    const spreakerEpisodeCode = v.split('--')[1]
                    v = `<iframe src="https://widget.spreaker.com/player?episode_id=${spreakerEpisodeCode}&theme=dark&chapters-image=true" width="100%" height="200px" frameborder="0"></iframe>`;
                }

                return this._sanitizer.bypassSecurityTrustHtml(v);
            case 'style':
                return this._sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this._sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this._sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                const x = this._sanitizer.sanitize(SecurityContext.URL, value);
                return x ? this._sanitizer.bypassSecurityTrustResourceUrl(x) : "";
            default:
                return this._sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}