import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DialogContactComponent } from "../dialog-contact/dialog-contact.component";
import { DialogNoticeComponent } from "../dialog-notice/dialog-notice.component";
import { ApiService } from "../../services/api.service";
import { HeaderDataService } from "../../services/header-data.service";

@Component({
  selector: 'app-contact-modal',
  template: '',
  styleUrls: [],
  standalone: true,
  imports: [TranslateModule],
})
export class ContactModalComponent {

    get currentLang(): string {
        return this.translate.currentLang;
    }

    get currentRoute(): string {
        return window.location.pathname;
    }

    get isSimPage(): boolean {
        const urlArray = window.location.href.replace(window.location.origin, '').split('/');
        return urlArray.includes('sim');
    }

    get slug(): string {
        const urlArray = window.location.href.replace(window.location.origin, '').split('/');
        if (urlArray.length < 4 && urlArray.includes('sim')) {
            return "sim-home";
        } else if (urlArray.length < 3) {
            return "spa-home";
        }
        return urlArray[3];
    }

    get root(): string | undefined {
        const urlArray = window.location.href.replace(window.location.origin, '').split('/');
        if (urlArray.includes('sim')) {
            return "sim";
        } else if (urlArray.length < 3) {
            return "spa";
        }
        return urlArray[2];
    }

    constructor(
        protected readonly dialog: MatDialog,
        protected readonly translate: TranslateService,
        protected readonly api: ApiService,
        protected readonly headerDataService: HeaderDataService,
    ) {}

    protected async openContactDialog(theme: 'default' | 'yellow' | 'red' = 'default', contactUsEmail?: string) {
        const dialogRef = this.dialog.open(DialogContactComponent, {
            id: 'modal-dialog-contact',

            minWidth: 'min(750px, 90vw)',
            // maxWidth: '60%',
            
            height: 'auto',
            minHeight: '80%',
            maxHeight: '100%',
            
            data: {
                privacyPolicyLink: `/${this.translate.currentLang}/article/privacy-policy`,
                useWhiteTheme: theme === 'red',
                isCareersForm: theme == 'yellow'
            },
        });
        dialogRef.afterClosed().subscribe( async (filledForm) => {
            // console.log({
            //     slug: this.slug,
            //     root: this.isSimPage ? 'sim' : this.root || '',

            //     email: contactUsEmail,
            // });

            if (filledForm) {
                try {
                    await this.api.contacts.sendEmail({
                        slug: this.slug,
                        root: this.isSimPage ? 'sim' : this.root || '',

                        email: contactUsEmail,

                        form: filledForm,
                    });

                    this.dialog.open(DialogNoticeComponent, {
                        id: 'modal-dialog-notice',
                        
                        data: {
                            category: this.translate.instant('FORMS.THANKS_FOR_CONTACT'),
                            title: this.translate.instant('FORMS.THANKS_NOTICE'),
                        },
                    });
                } catch (ex: any | {response: {data: {error: {name: string, status: number, code: string, message: string}}}}) {
                    if (ex?.response?.data?.error?.message) {
                        console.error(ex.response.data.error.message);
                    } else {
                        console.error(ex);
                    }
                }
            }
        });
    }
}
