@if (mediaSection) {
    <div class="media-section in-{{pageRoot}}">
        <app-video-embedder [source]="activeVideoSection" [isOpenEvt]="openVideoSignal"></app-video-embedder>
        
        <div class="items row">
            @for (item of mediaSection.items; track $index) {
                @if (item.type != 'full-section') {
                    <div [ngClass]="{
                            'col-12': item.size === 'full',
                            'col-6': item.size === 'half' || (item.type === 'insight' && !item.size),
                            'col-4': item.size === 'third',
                            'theme--deep-blue': ( !item.theme && ['text'].includes(item.type) && ($index + 1 < mediaSection.items.length) && ['text'].includes(mediaSection.items[$index + 1].type) ) || item.theme === 'deep-blue'
                        }"
                        class="flex-vertical-centered theme--{{item.theme ?? 'default'}} {{item.insight ? 'news-card news-category--' + ((item?.insight?.category ?? '') | kebabCase) : ''}}">
    
                        @switch (item.type) {
                            @case ('text') {
                                <div id="media-section-item-{{$index}}" class="media-text" data-aos="fade-up">
                                    <h3 [innerHTML]="item.title"></h3>
            
                                    @if (item.download) {
                                        <div class="flex-vertical-centered">
                                            <a class="prom-borded-button-wrapper" [href]="item.download.url" target="_blank" download style="font-size: 1em;">
                                                <app-prom-animated-button [arrow]="'down'" [theme]="getButtonTheme(item)">
                                                    {{item.download.label | uppercase}}
                                                </app-prom-animated-button>
                                            </a>
                                        </div>
                                    }
    
                                    @if (item.link?.route) {
                                        <div class="flex-vertical-centered">
                                            <app-prom-animated-button class="prom-borded-button-wrapper" [arrow]="'right'" [theme]="getButtonTheme(item)" [bgColor]="item.theme && ['deep-blue', 'electric-blue'].includes(item.theme) ? 'dark' : 'light'" [routerLink]="['/' + item.link?.route]">
                                                {{item.link!.label| uppercase}}
                                            </app-prom-animated-button>
                                        </div>
                                    }
                                </div>
                            }
                            @case ('image') {
                                <div id="media-section-item-{{$index}}" class="media-image">
                                    <img src="{{item.url}}" alt="media_image">
                                    
                                    @if (item.title && item.title.length > 0) {
                                        <div class="text-wrap">
                                            <div class="text-wrap-content">
                                                <h1 [innerHTML]="item.title"></h1>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            @case ('video') {
                                <!-- <app-video-embedder [source]="item" [isOpenEvt]="openVideoSignal"></app-video-embedder> -->
                                <div id="media-section-item-{{$index}}" [ngClass]="{'media-video': true, 'with-cover-image': item.cover}" (click)="openVideo(item)">
                                    @if (item.cover) {
                                        <div class="media-colored-filter"></div>
                                        <div class="play-btn-wrapper">
                                            <img class="play-btn" [src]="playSymbol" alt="play_button">
                                        </div>
                                    }
    
                                    @if (item.cover) {
                                        <div class="cover-wrap">
                                            <div class="media-cover-image" style="background: url('{{item.cover}}'); background-size: cover; background-position: center;"></div>
                                        </div>
                                    }
                                    @else if (item.url && item.url.includes('.com')) {
                                        <iframe width="{{videoWidth($index)}}" height="{{videoHeight($index)}}" [src]="item.url | safe: 'resourceUrl'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    }
                                    @else if (item.url?.endsWith('.mp4')) {
                                        <video width="{{videoWidth($index)}}" height="{{videoHeight($index)}}" controls [src]="item.url"></video>
                                    }
                                </div>
                            }
                            @case ('text+image') {
                                <div id="media-section-item-{{$index}}" class="media-text-image" data-aos="fade-up">
                                    <h3>{{item.title}}</h3>
            
                                    <div class="body" data-aos="fade-up"><p [innerHTML]="item.body"></p></div>
    
                                    <div class="image-wrap">
                                        <img src="{{item.url}}" alt="media_image">
                                    </div>
                                </div>
                            }
                            @case ('text+link') {
                                <div id="media-section-item-{{$index}}" class="media-text-link" data-aos="fade-up">
                                    <h3>{{item.title}}</h3>
            
                                    @if (item.body?.startsWith('<p>')) {
                                        <div class="body" data-aos="fade-up" [innerHTML]="item.body"></div>
                                    }
                                    @else {
                                        <div class="body" data-aos="fade-up"><p [innerHTML]="item.body"></p></div>
                                    }
    
                                    @if (item.link?.url) {
                                        <div class="flex-vertical-centered">
                                            <a class="prom-borded-button-wrapper" [href]="item.link!.url" target="_blank" style="font-size: 1em;">
                                                <app-prom-animated-button [arrow]="'top-right'" [theme]="getButtonTheme(item)">
                                                    {{item.link!.label | uppercase}}
                                                </app-prom-animated-button>
                                            </a>
                                        </div>
                                    }
                                    @else if (item.link?.route) {
                                        <div class="flex-vertical-centered">
                                            <app-prom-animated-button class="prom-borded-button-wrapper" [arrow]="'right'" [theme]="getButtonTheme(item)" [routerLink]="item.link!.route">
                                                {{item.link!.label | uppercase}}
                                            </app-prom-animated-button>
                                        </div>
                                    }
                                </div>
                            }

                            <!-- IN HOME PAGE -->
                            @case ('insight') {
                                @let newsItem = item.insight;
                                @if (newsItem) {
                                    <div id="media-section-item-{{$index}}" class="media-insight" data-aos="fade-up" [routerLink]="[articleRoot, newsItem.slug]">
                                        @if (newsItem.heroImage) {
                                            <div class="news-bg">
                                                <img src="{{newsItem.heroImage}}" alt="{{newsItem.title}}">
                                            </div>
                                        }
                                        @else if (newsItem.category === 'events') {
                                            <div class="news-bg">
                                                <img src="/assets/images/news-events-bg.png" alt="{{newsItem.title}}">
                                            </div>
                                        }
                                        <div [ngClass]="{'news-colored-filter': true, 'no-image': !newsItem.heroImage}"></div>
                                        <div class="news-border"></div>
    
                                        <div id="news-info-{{$index}}" class="news-info">
                                            <h5 class="news-info--category">{{newsItem.category | uppercase}}</h5>
                                            <h3 class="news-info--title">{{newsItem.title}}</h3>
                                            <h3 class="news-info--event-date">{{newsItem.eventDate | date: ('DATE_FORMAT_W_SPACE' | translate)}}</h3>
                                            @if (newsItem.category === 'events') {
                                                <p class="news-info--subtitle">{{newsItem.subtitle}}</p>
                                            }
                                        </div>
    
                                        <div class="footer" [ngStyle]="{'position': getInsightFooterPosition($index)}">
                                            @if (newsItem.types?.length) {
                                                <div class="news-icons flex-row">
                                                    @for (icon of newsItem.types; track $index) {
                                                        @if (supportedIcons.includes(icon)) {
                                                            <img class="icon icon-white" src="/assets/icons/insights/Type_{{icon | uppercase}}_NEG.png" alt="{{icon}}-icon">
                                                            <img class="icon icon-blue" src="/assets/icons/insights/Type_{{icon | uppercase}}_POS.png" alt="{{icon}}-icon">
                                                        }
                                                    }
                                                </div>
                                            }
                        
                                            <div class="go-to-news">
                                                <img class="arrow arrow-blue" src="/assets/icons/right_arrow__blue.svg" alt="">
                                                <img class="arrow arrow-green" src="/assets/icons/right_arrow__green.svg" alt="">
                                                <img class="arrow arrow-white" src="/assets/icons/right_arrow__white_large.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                }
                            }
                            @case ('insight-list') {
                                @if (insightList?.length) {
                                    <div id="media-section-item-{{$index}}" class="media-insight-list" data-aos="fade-up">
                                        <app-section-news-headlines [news]="insightList" [bgColor]="item.theme ?? 'white'" [showSeeMoreButton]="false" [currentLang]="currentLang"></app-section-news-headlines>
                                    </div>
                                }
                            }

                            @default { }
                        }
                    </div>
                }
                @else {
                    <div class="col-12 media-full-section theme--{{item.theme ?? 'default'}}" [ngStyle]="{'background-image': item.cover ? 'url(' + item.cover + ')' : undefined}">
                        <div [ngClass]="{'row': item.imageUrl, 'reversed': item.subTheme === 'reversed'}">
                            @if (item.imageUrl) {
                                <div class="col-6 image-column">
                                    <div class="image-wrap">
                                        <img src="{{item.imageUrl}}" alt="image" data-aos="fade-up">
                                    </div>
                                </div>
                            }
                            
                            <div [ngClass]="{'col-6 text-column': item.imageUrl, 'single-column': !item.imageUrl}">
                                <div class="col-content">
                                    <h3 class="title" data-aos="fade-up">{{item.title}}</h3>
        
                                    @if (item.body?.startsWith('<p>')) {
                                        <div class="body" data-aos="fade-up" [innerHTML]="item.body"></div>
                                    }
                                    @else {
                                        <div class="body" data-aos="fade-up"><p [innerHTML]="item.body"></p></div>
                                    }
    
                                    <div class="buttons">
                                        @if (item.link?.route) {
                                            <div class="flex-vertical-centered">
                                                <app-prom-animated-button class="prom-borded-button-wrapper" [arrow]="'right'" [theme]="getButtonTheme(item)" [bgColor]="item.theme && ['deep-blue'].includes(item.theme) ? 'dark' : 'light'" [routerLink]="item.link!.route">
                                                    {{item.link!.label | uppercase}}
                                                </app-prom-animated-button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>
    </div>
}


<!-- 
<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/7XXu_-eoxHo?si=BN332jXxJp2N3mo6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
 -->