import { Routes } from '@angular/router';
import redirectsJSON from './redirects.json';
import { DEFAULT_LANG } from './models/common';

export const redirects: Routes = redirectsJSON.Table1.map(r => {
    const ogRoute = r.permalink.replace('https://www.prometeia.it/', '').replace('https://www.prometeia.com/', '');

    return {
        path: ogRoute,
        pathMatch: 'full' as "full" | "prefix" | undefined,
        redirectTo: ogRoute.startsWith('en/') ? `en/about-us/insights/article/${r.slug}` : `${DEFAULT_LANG}/about-us/insights/article/${r.slug}`
    }
});

export const simRedirects: Routes = [
    /** HOME */
    { path: ':lang/financial-advisory', pathMatch: 'full', redirectTo: `sim/:lang` },

    /** ANTEO */
    { path: ':lang/financial-advisory_anteo-archive', pathMatch: 'full', redirectTo: `sim/:lang/archive/anteo` },
    { path: ':lang/financial-advisory_anteo/:anteoIssue', pathMatch: 'full', redirectTo: `sim/:lang/archive/anteo` },
    { path: ':lang/financial-advisory_anteo-article/:anteoArticleID', pathMatch: 'full', redirectTo: `sim/:lang/anteo/article/:anteoArticleID` },

    /** IN-FORMATION PATHS */
    { path: ':lang/financial-advisory_information-paths-archive', pathMatch: 'full', redirectTo: `sim/:lang/archive/in-formation-paths` },
    { path: ':lang/financial-advisory_information-paths/:articleID', pathMatch: 'full', redirectTo: `sim/:lang/archive/in-formation-paths` },
];
