@if (loading) {
    <div class="page-loading"></div>
}
@else if (pageData) {
    <div class="page insights-article {{categoryCssClass}}">
        <div class="cover-image">
            <img [src]="pageData.heroImage">
        </div>
        <div class="cover-colored-filter"></div>
        
        <div class="article-and-related">
            <div class="title-box"></div>

            <div class="article-content">
                <div class="top-infos">
                    <div class="category"><h2 [innerHTML]="pageData.category.replace('-', ' ')"></h2></div>

                    @if (pageData.author && pageData.category === 'ideas') {
                        <div class="authors"><p [innerHTML]="pageData.author"></p></div>
                    }
                </div>
                
                <br>
    
                @if (categoryCssClass === 'events' && pageData.eventDate) {
                    <div class="event-date"><h1>{{pageData.eventDate | date: ('DATE_FORMAT_W_SPACE' | translate)}}</h1></div>
                }
                <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                @if (pageData.subtitle) {
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                }
                @if (categoryCssClass !== 'events' && pageData.publicationDate) {
                    <div class="date"><p>{{pageData.publicationDate | date: ('DATE_FORMAT_W_SPACE' | translate)}}</p></div>
                }
    
                <div class="body inner-html inner-html--insights-article" data-aos="fade-up" [innerHTML]="pageData.body | safe : 'html'"></div>
                
                @if (pageData.attachment) {
                    <div class="download-btn">
                        <a [href]="pageData.attachment" target="_blank" download>
                            <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                {{'GENERAL.DOWNLOAD' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                }
            </div>
    
            <div class="related-wrapper">
                <div class="related-list">
                    @if (pageData.related?.items?.length) {
                        <p class="related-list--title">Related Insights</p>
            
                        @for (related of pageData.related!.items; track $index) {
                            <div class="related-list--item">
                                <p class="category">{{related.category | uppercase}}</p>
                                <p class="title" [routerLink]="['/about-us/insights/article', related.slug]">{{related.title}}</p>
                            </div>
                        }
                    }
                </div>
            </div>
        </div>

        <div class="page-content">
            <app-section-contact-us [text]="'Any questions?<br>Get in touch with our team!'"></app-section-contact-us>
    
            @if (newsList) {
                <app-section-news-headlines [news]="newsList.items" [currentLang]="currentLang"></app-section-news-headlines>
            }       
        </div>
    </div>
}
@else if (pageDataErrorCode) {
    <app-page-not-found></app-page-not-found>
}
@else {
    <div class="page-loading"></div>
}
