import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { ISitePageMediaSection, PageRoots } from '../../../models/pages';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { RoutesToPagesComponent } from "../../../components/routes-to-pages/routes-to-pages.component";
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { ICareersPageOpenPositions } from '../../../models/careers';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { CareersService } from '../../../services/careers.service';
import { ISelector, ISitePageReference } from '../../../models/common';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IShipSelectorSelection, SelectorShipComponent } from "../../../components/selector-ship/selector-ship.component";
import { deepClone, stringSimilarity } from '../../../services/utilities.service';
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";
import { DISCOVER_OUR_TRIBE } from '../../../models/constants';

const ROOT_SLUG: PageRoots = 'careers';

const OPEN_POSITION_TO_SHOW: number = 10;
const OPEN_POSITION_INCREMENT: number = 3;

@Component({
  selector: 'app-open-positions-page',
  templateUrl: './open-positions-page.component.html',
  styleUrl: './open-positions-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatProgressSpinnerModule, LongArrowDownComponent, RoutesToPagesComponent, SectionContactUsComponent, PromAnimatedButtonComponent, SelectorShipComponent, MediaSectionComponent],
})
export class OpenPositionsPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;

  readonly spontaneousApplicationURL: string = "https://prometeia.wd3.myworkdayjobs.com/Prometeia_External_Career_Portal/job/Bologna/Candidatura-Spontanea_JR100037";
  
  get discoverOurTribe(): ISitePageMediaSection {
    const res: ISitePageMediaSection = deepClone(DISCOVER_OUR_TRIBE);
    res.items[0].link!.route = `/${this.currentLang}${DISCOVER_OUR_TRIBE.items[0].link!.route}`;
    return res;
  }

  override pageData?: Partial<ICareersPageOpenPositions> = {
    category: 'Open Positions',
    title: 'We promote differences and consider them a value.',
  }

  private _openPositions?: ISitePageReference[];
  private _filteredOpenPositions?: ISitePageReference[];
  openPositionsLimit: number = OPEN_POSITION_TO_SHOW;
  get openPositions(): ISitePageReference[] | undefined {
    return this._filteredOpenPositions?.slice(0, this.openPositionsLimit);
  }

  get canLoadMore(): boolean {
    return this.openPositionsLimit < (this._filteredOpenPositions?.length ?? 0);
  }

  selectors: ISelector[] = [
    { name: 'Tribes', options: [] },
    { name: 'Famiglie Professionali', options: [] },
    { name: 'Location', options: [] },
  ];

  constructor(
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly api: ApiService,
    protected override readonly translate: TranslateService,
    private readonly careersService: CareersService,
  ) {
    super(activatedRoute, api, translate);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.loadOpenPositions();
    this.loadFilters();
  }

  loadingOpenPositions: boolean = false;
  private async loadOpenPositions() {
    this.loadingOpenPositions = true;

    try {
      const res = await this.careersService.list();
      // console.log(res);
  
      this._openPositions = res;
      this._filteredOpenPositions = res;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loadingOpenPositions = false;
    }
  }

  private async loadFilters() {
    try {
      const res = await this.careersService.filters();
      this.selectors = res;
    } catch (ex) {
      console.error(ex);
    }
  }


  onOpenPositionsFiltersSelectionChanged(filterSelection: IShipSelectorSelection[]) {
    /** all filters has been disabled */
    if (filterSelection.every(selector => selector.selected.length === 0)) {
      this._filteredOpenPositions = deepClone(this._openPositions);
      return;
    }


    /**
     * Tribes == jobArea
     * Famiglie Professionali == jobCategory
     * Location == jobOffice
    */
    this._filteredOpenPositions = this._openPositions?.filter((openPosition) => {
      const tribesSelected = filterSelection.find((selection) => selection.name === 'Tribes');
      const professionalFamilies = filterSelection.find((selection) => selection.name === 'Famiglie Professionali');
      const locationsSelected = filterSelection.find((selection) => selection.name === 'Location');

      if (tribesSelected && professionalFamilies && locationsSelected) {
        return (
          tribesSelected.selected.length === 0 || (openPosition.jobArea !== undefined && tribesSelected.selected.includes(openPosition.jobArea))
        ) && (
          professionalFamilies.selected.length === 0 || (openPosition.jobCategory !== undefined && professionalFamilies.selected.includes(openPosition.jobCategory))
        ) && (
          locationsSelected.selected.length === 0 || (openPosition.jobOffice !== undefined && this.checkCitySimilarity(locationsSelected.selected, openPosition.jobOffice))
        );
      } else {
        return false;
      }
    });
  }

  async onStringSearch(stringSearch: string) {
    // console.log(stringSearch, "in", this._openPositions);
    this._filteredOpenPositions = await this.careersService.search(stringSearch);

    this.openPositionsLimit = OPEN_POSITION_TO_SHOW;
  }

  onClearSearch() {
    // console.log("clear");
    this._filteredOpenPositions = deepClone(this._openPositions);

    this.openPositionsLimit = OPEN_POSITION_TO_SHOW;
  }

  onLoadMoreClick() {
    this.openPositionsLimit += OPEN_POSITION_INCREMENT;
  }


  private checkCitySimilarity(selectedCities: string[], office: string): boolean {
    const requiredSimilaryCoefficient = 0.8;

    /** replace all ",", "or" and space with "/" to uniformize the offices, then split them by "/" */
    const jobOffices = office.replaceAll(',', '/').replaceAll('or', '/').replaceAll(' ', '/').split('/').map(o => o.trim());
    // console.log(jobOffices);
    
    for (const jobOffice of jobOffices) {
      for (const selectedCity of selectedCities) {
        /** if the selected city is similar to the office (start or end with the other one) */
        if (jobOffice.startsWith(selectedCity) || selectedCity.startsWith(jobOffice) || jobOffice.endsWith(selectedCity) || selectedCity.endsWith(jobOffice)) {
          return true;
        }

        /** checks string similarity using the ad hoc function */
        const similarity = stringSimilarity(selectedCity, jobOffice);
        if (similarity > requiredSimilaryCoefficient) {
          return true;
        }

        // console.log(selectedCity, jobOffice, similarity);
      }
    }

    return false;
  }

}
