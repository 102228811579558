import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { INewslettersItem } from '../../models/common';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { MatDialog } from '@angular/material/dialog';
import { DialogContactComponent } from '../dialog-contact/dialog-contact.component';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrl: './newsletters.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule, PromAnimatedButtonComponent],
})
export class NewslettersComponent {
  @Input() newsletters?: INewslettersItem[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly api: ApiService,
    private readonly translate: TranslateService,
  ) { }

  async openNewsletterDialog(newsletter: INewslettersItem) {
    const newsletterData = await this.loadNewsletterForm(newsletter);
    if (!newsletterData || !newsletterData.slug) {
      return;
    }

    const dialogRef = this.dialog.open(DialogContactComponent, {
      id: 'modal-dialog-contact',

      minWidth: '750px',
      // maxWidth: '60%',
      
      height: 'auto',
      minHeight: '80%',
      maxHeight: '100%',
      
      data: {/* title: `Newsletter - ${newsletterData.title}`, */ list: newsletterData.sections, privacyPolicyLink: `/${this.translate.currentLang}/article/privacy-policy`},
    });
    dialogRef.afterClosed().subscribe( async (res) => {
      console.log(res);
    });
  }


  private async loadNewsletterForm(newsletter: INewslettersItem) {
    // newsletter-form-fields__risk_news / newsletter-form-fields__trending_topics / newsletter-form-fields__anteo

    try {
      const res = await this.api.newsletters.get(newsletter.slug.replaceAll('-', '_'));
      return res;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }
}
