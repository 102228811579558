import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SectionContactUsComponent } from "../../components/section-contact-us/section-contact-us.component";
import { GradientTextWrapperComponent } from "../../components/gradient-text-wrapper/gradient-text-wrapper.component";
import { getYoutubeID, scrollToTop, sleep } from '../../services/utilities.service';
import { MediaSectionComponent } from "../../components/media-section/media-section.component";
import { TranslateService } from '@ngx-translate/core';
import { ISitePageNews } from '../../models/pages';
import { ApiService } from '../../services/api.service';
import { IMediaSectionItem } from '../../models/common';
import { VIDEO_SOURCES } from '../../models/constants';
import { Subscription } from 'rxjs';
import { Debounce } from 'lodash-decorators';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, SectionContactUsComponent, GradientTextWrapperComponent, MediaSectionComponent],
})
export class HomePageComponent implements OnInit {

  readonly videoSrc = VIDEO_SOURCES.HOME;
  readonly insightsFilters = { skip: 0, limit: 4, categories: 'ideas;news;awards;events', home: true };

  pageData?: {
    title: string,
    highlightedTitle: string,
    items: IMediaSectionItem[],
  };

  insights: ISitePageNews = { items: [] };

  get currentLang(): string {
    return this.translate.currentLang;
  }

  get introData(): { title: string, highlightedTitle: string } | undefined {
    return this.pageData ?? {
      title: "The future belongs to people who can",
      highlightedTitle: "transform the present"
    }
  }

  activatedRouteParamMapSubscription?: Subscription;
  onLangChangeSubscription?: Subscription;

  constructor (
    private readonly translate: TranslateService,
    private readonly api: ApiService,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.staticSlugInit();

    scrollToTop();
  }

  /** initialization for pages where the slug is not passed as a parameter */
  protected staticSlugInit() {
    scrollToTop();
    
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('lang')) {
        const lang = paramMap.get('lang');
        if (lang) {
          this.translate.use(lang);
        }
      }

      setTimeout(() => {
        this.loadPage();
        scrollToTop();
      }, 1);
    });

    this.onLangChangeSubscription = this.translate.onLangChange.subscribe(() => {
      // console.log('onLangChange');
      this.loadPage();
      scrollToTop();
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
    this.onLangChangeSubscription?.unsubscribe();
  }

  @Debounce(1)
  private async loadPage() {

    try {
      // this.pageData = await this.api.pages.home() as any;
      const [home, news] = await Promise.all([
        this.api.pages.home(),
        this.api.pages.insights(this.insightsFilters),
      ]);

      this.pageData = home as any;
      this.insights = news;

      this.formatPage();
    } catch (ex) {
      console.error();
    }

    // console.log(this.pageData);
  }

  private formatPage() {
    this.editYoutubeVideoLinks();
  }

  private editYoutubeVideoLinks() {
    this.pageData?.items?.forEach((item: IMediaSectionItem) => {
      if (item.type === 'video' && item.url && !item.url.endsWith('.mp4')) {
        // console.log("YT ID:", getYoutubeID(item.url));
        item.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(item.url)}`;
      }
    });
  }

  // private async loadInsightList() {
  //   await sleep(1);
    
  //   try {
  //     const filters = { skip: 0, limit: 4, categories: 'ideas;news;awards;events', home: true };
  //     const news: ISitePageNews = await this.api.pages.insights(filters);
  //     this.insights = news;
  //   } catch (ex) {
  //     console.error(ex);
  //   }
  // }

}
