import { Component, Input } from '@angular/core';
import { ISitePageFlippingCard } from '../../models/common';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrl: './image-card.component.scss',
  standalone: true,
  imports: [],
})
export class ImageCardComponent {
  @Input() card?: ISitePageFlippingCard;
  @Input() borderColor: 'electric-blue' | 'red' = 'red';
  @Input() imageSize: 'default' | 'small' = 'default';
  @Input() cardHeight: 'default' | 'tall' = 'default';
  @Input() useMarginInBody: boolean = false;
}
