@if (card) {
    <div class="image-card default-card-border border-color--{{borderColor}} size--{{cardHeight}}" [ngStyle]="{'height': calculatedHeight ? calculatedHeight + 'px' : 'auto'}">
        <img class="card-image size--{{imageSize}}" [src]="card.cover" alt="card_image">
        <div class="card-info">
            @if (card.title) {
                <h4 class="card-title" [innerHTML]="card.title"></h4>
            }

            @if (card.body.trim().startsWith('<p>')) {
                <div [ngClass]="{'card-body': true, 'use-margin-in-body': useMarginInBody, 'inner-html--sim-general-article': borderColor === 'red'}" [innerHTML]="card.body"></div>
            }
            @else {
                <div [ngClass]="{'card-body': true, 'use-margin-in-body': useMarginInBody, 'inner-html--sim-general-article': borderColor === 'red'}"><p [innerHTML]="card.body"></p></div>}
        </div>
    </div>
}
