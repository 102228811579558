import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Location } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { PromAnimatedButtonComponent } from "../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { scrollToTop } from '../../services/utilities.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, RouterModule, MatInputModule, MatIconModule, MatButtonModule, PromAnimatedButtonComponent],
})
export class SearchPageComponent implements OnInit {

  get currentLang(): string {
    return this.translate.currentLang;
  }

  get searchQueryString(): string {
    const search = this.activatedRoute.snapshot.queryParamMap.get('q');
    if (!search) return '';
    return search;
  }

  searchBoxInput = new FormControl('', [Validators.required, Validators.minLength(3)]);

  searchResults: {id: string,title: string, maxItemsToShow: number, increment: number, list: {
    slug: string,

    pageTitle?: string,

    title?: string,
    pubDate?: Date,
  }[]}[] = [
    { id: 'all', title: 'All-site', list: [], maxItemsToShow: 8, increment: 8 },
    { id: 'insights', title: 'Insights', list: [], maxItemsToShow: 6, increment: 6 },
  ];

  constructor(
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly api: ApiService,
    private readonly location: Location,
  ) { }

  ngOnInit(): void {
    // console.log("searching", this.searchQueryString);

    if (this.searchQueryString) {
      this.searchBoxInput.setValue(this.searchQueryString);

      this.loadSearchResults();
    }
  }

  loading: boolean = false;
  private async loadSearchResults() {
    const queryString = this.searchBoxInput.value;
    if (!queryString) return;

    this.loading = true;

    try {
      // const [allSite, insights] = await Promise.all([
      //   this.api.searchResults.allSite(queryString),
      //   this.api.searchResults.insights(queryString, 0, this.searchResults.find(r => r.id === 'insights')!.increment + 1),
      // ]);

      // this.searchResults[0].list = allSite;
      // this.searchResults[1].list = insights;

      // this.searchResults.forEach(r => {
      //   r.list.forEach(item => {
      //     item.slug = item.slug.replaceAll('__', '/');
      //   });
      // });

      this.searchResults[0].list = [
        { pageTitle: 'Code of ethics', slug: '' },
        { pageTitle: 'Services for Wealth & Asset Managers', title: 'Expertise', slug: '' },
        { pageTitle: 'ESG & Climate', title: 'Solutions', slug: '' },
        { pageTitle: 'Wealth Management & Financial Distribution', title: 'Expertise', slug: '' },

        { pageTitle: 'Code of ethics', slug: '' },
        { pageTitle: 'Services for Wealth & Asset Managers', title: 'Expertise', slug: '' },
        { pageTitle: 'ESG & Climate', title: 'Solutions', slug: '' },
        { pageTitle: 'Wealth Management & Financial Distribution', title: 'Expertise', slug: '' },

        { pageTitle: 'Code of ethics', slug: '' },
      ];

      this.searchResults[1].list = [
        { title: 'Prometeia Brief - Italy in the Global economy May 2024', pubDate: new Date('2024-05-01'), slug: '' },
        { title: 'Household choices on mortgages and what to expect from 2023', pubDate: new Date('2023-05-01'), slug: '' },

        { title: 'Prometeia Brief - Italy in the Global economy May 2024', pubDate: new Date('2024-05-01'), slug: '' },
        { title: 'Household choices on mortgages and what to expect from 2023', pubDate: new Date('2023-05-01'), slug: '' },

        { title: 'Prometeia Brief - Italy in the Global economy May 2024', pubDate: new Date('2024-05-01'), slug: '' },
        { title: 'Household choices on mortgages and what to expect from 2023', pubDate: new Date('2023-05-01'), slug: '' },

        { title: 'Prometeia Brief - Italy in the Global economy May 2024', pubDate: new Date('2024-05-01'), slug: '' },
      ];
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
      scrollToTop();
    }
  }

  submitSearch() {
    if (!this.searchBoxInput.valid) {
      console.error('Invalid search input');
      return;
    }

    this.router.navigate([`/${this.currentLang}/search`], { queryParams: { q: this.searchBoxInput.value }, replaceUrl: true });
  }

  onLoadMoreClick(id: string) {
    const r = this.searchResults.find(r => r.id === id);
    if (!r) return;

    if (id === 'all') {
      r.maxItemsToShow += r.increment;
      return;
    }

    if (r.list.length > r.maxItemsToShow) {
      /* with the previous API call we have more items than max (-> increment max) */
      r.maxItemsToShow += 6;
    } else {
      /* with the previous API call we don't have more items than max (-> new API call) */
      console.log("altro caso");

      if (id === 'insights') {
        this.api.searchResults.insights(this.searchBoxInput.value!, r.maxItemsToShow + 1, r.increment + 1).then((insights) => {
          r.list = r.list.concat(insights);
        });

        r.maxItemsToShow += r.increment;
      }
    }
  }

  goBack() {
    this.location.back();
  }

}
