import { Component, Input } from '@angular/core';
import { ISitePageCard } from '../../models/common';
import { PromCardComponent } from "../prom-card/prom-card.component";

@Component({
  selector: 'app-prom-cards-list',
  templateUrl: './prom-cards-list.component.html',
  styleUrl: './prom-cards-list.component.scss',
  standalone: true,
  imports: [PromCardComponent],
})
export class PromCardsListComponent {
  @Input() cards: ISitePageCard[] = [];
  @Input() size: 'full' | 'half' | 'third' = 'half';
  @Input() arrowColor: 'blue' | 'green' = 'blue';
  @Input() theme: 'default' | 'yellow' = 'default';
}
