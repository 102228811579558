@if (!loading && pageData) {
    <div class="page sim-home">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <app-long-arrow-down [theme]="'white'"></app-long-arrow-down>
            </section>

            @if (pageData.anteo) {
                <section class="anteo">
                    <div class="section-content">
                        <h3>{{pageData.anteo.title}}</h3>
                        <div class="row">
                            <div class="col-7">
                                @if (pageData.anteo.body.trim().startsWith('<p>')) {
                                    <div class="body" data-aos="fade-up" [innerHTML]="pageData.anteo.body"></div>
                                }
                                @else {
                                    <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.anteo.body"></p></div>
                                }

                                <div class="archive" data-aos="fade-up">
                                    <a class="unstyled-link" [routerLink]="['/sim/' + currentLang, 'archive', 'anteo']">
                                        <app-prom-animated-button [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'right'">
                                            {{'ANTEO.ARCHIVE' | translate | uppercase}}
                                        </app-prom-animated-button>
                                    </a>
                                </div>
                            </div>

                            <div class="col-5">
                                <div class="image-wrap">
                                    <img class="interactive" src="{{pageData.anteo.imageUrl}}" alt="anteo_cover" data-aos="fade-up" [routerLink]="['/sim/' + currentLang, 'archive', 'anteo']" [queryParams]="{latest: true}">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="anteo-newsletter">
                    <div class="section-content flex-row" data-aos="fade-up">
                        @if (pageData.anteo?.newsletterText) {
                            <h3 class="anteo-newsletter--text" [innerHTML]="pageData.anteo?.newsletterText"></h3>
                        }
                        @else {
                            <h3 class="anteo-newsletter--text">
                                {{'ANTEO.SUBSCRIBE_TO_NEWSLETTER' | translate}}
                            </h3>
                        }

                        <div class="anteo-newsletter--sign-up" data-aos="fade-up">
                            <app-prom-animated-button [theme]="'sim-white'" [arrow]="'right'" (click)="openNewsletterDialog()">
                                {{'ANTEO.SUBSCRIBE' | translate | uppercase}}
                            </app-prom-animated-button>
                        </div>
                    </div>
                </section>
            }

            @if (latestInFormationPath) {
                <section class="in-formation-paths">
                    <div class="section-content">
                        <h3>
                            {{'IN_FORMATION_PATHS.TITLE' | translate}}
                        </h3>
    
                        <div class="body">
                            <p>
                                {{'IN_FORMATION_PATHS.TEXT' | translate}}
                            </p>
                        </div>
    
                        <div class="in-formation-paths--card interactive flex-row flex-row--space-between" data-aos="fade-up" [routerLink]="['/sim/' + currentLang, 'in-formation-paths', 'latest']">
                            <div>
                                <p class="edition">{{latestInFormationPath.category}}</p>
                                <h4 class="title">{{latestInFormationPath.title}}</h4>
                                <p class="subtitle">{{latestInFormationPath.subtitle | uppercase}} {{latestInFormationPath.year}}</p>
                            </div>
    
                            <div class="arrow-column">
                                <img class="arrow" src="/assets/icons/right_arrow__white2.svg">
                            </div>
                        </div>
                        
                        <div class="archive" data-aos="fade-up">
                            <a class="unstyled-link" [routerLink]="['/sim/' + currentLang, 'archive', 'in-formation-paths']">
                                <app-prom-animated-button [theme]="'sim-red'" [bgColor]="'dark'" [arrow]="'right'">
                                    {{'IN_FORMATION_PATHS.ARCHIVE' | translate | uppercase}}
                                </app-prom-animated-button>
                            </a>
                        </div>
                    </div>
                </section>
            }

            @if (pageData.consulence) {
                <section class="consulence">
                    <div class="section-content">
                        <h3>{{pageData.consulence.title}}</h3>
                        
                        @if (pageData.consulence.items?.length) {
                            <app-routes-to-pages [baseRoute]="'sim/' + currentLang + '/page/'" [items]="pageData.consulence.items" [size]="'thirds'" [theme]="'red'" [arrowColor]="'blue'"></app-routes-to-pages>
                        }

                        @if (pageData.consulence.links.length) {
                            <div class="sim-links-list-container">
                                <app-links-list [links]="pageData.consulence.links"></app-links-list>
                            </div>
                        }
                    </div>
                </section>
            }

            <app-reserved-area-list-item [block]="reserveAreaBlock" [buttonTheme]="'sim-red'" [currentLang]="currentLang" [index]="0"></app-reserved-area-list-item>

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection"></app-media-section>
                </section>
            }

            @if (pageData.peopleSection) {
                <section class="corporate-bodies">
                    <div class="section-content">
                        <h3>{{pageData.peopleSection.title}}</h3>
                        @if (pageData.peopleSection.body?.trim().startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.peopleSection.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.peopleSection.body"></p></div>
                        }
                        
                        @if (pageData.peopleSection.items.length) {
                            <div class="accordion-container" data-aos="fade-up">
                                <app-people-accordion [list]="pageData.peopleSection.items" [theme]="'sim-red'" [bgColor]="'deep-blue'" [paddingSize]="'small'"></app-people-accordion>
                            </div>
                        }
                    </div>
                </section>
            }

            @if (pageData.sustainability) {
                <section class="sustainability">
                    <div class="section-content">
                        <h3>{{pageData.sustainability.title}}</h3>
                        <div class="body" data-aos="fade-up" [innerHTML]="pageData.sustainability.body"></div>

                        @if (pageData.sustainability.links.length) {
                            <div class="sim-links-list-container">
                                <app-links-list [links]="pageData.sustainability.links"></app-links-list>
                            </div>
                        }
                    </div>
                </section>
            }

            @if (pageData.legalDocuments) {
                <section class="legal-documents">
                    <div class="section-content">
                        <h3>{{pageData.legalDocuments.title}}</h3>
                        
                        @if (pageData.legalDocuments.links.length) {
                            <div class="sim-links-list-container">
                                <app-links-list [links]="pageData.legalDocuments.links"></app-links-list>
                            </div>
                        }
                    </div>
                </section>
            }

            @if (pageData.offices) {
                <section class="offices">
                    <div class="section-content">
                        <h3>{{'OFFICES.TITLE' | translate}}</h3>
                        
                        @if (pageData.offices.items.length) {
                            <div class="offices-list" data-aos="fade-up">
                                @for (item of pageData.offices.items; track $index) {
                                    <div class="office">
                                        <app-image-card [card]="item"></app-image-card>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </section>
            }

            <app-section-contact-us [theme]="'red'" [text]="'Any questions?<br>Get in touch with our team!'" [email]="contactUsEmail"></app-section-contact-us>
        </div>
    </div>
}
