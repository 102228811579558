import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICookiesListItem } from '../privacy-and-cookies.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-cookie-detail',
  templateUrl: './cookie-detail.component.html',
  styleUrl: './cookie-detail.component.scss',
  standalone: true,
  imports: [TranslateModule, MatIconModule, MatSlideToggleModule, FormsModule],
})
export class CookieDetailComponent {
  @Input() item?: ICookiesListItem;
  @Output() editSingleCookiePref = new EventEmitter<boolean>();

  isOpen: boolean = false;

  slideValChange() {
    this.editSingleCookiePref.emit(this.item?.accepted ?? false);
  }
}
