import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { ConsoleLogger, I18n, Hub, sessionStorage} from 'aws-amplify/utils';
import { getCurrentUser, signOut, signUp, fetchAuthSession } from 'aws-amplify/auth';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { LocaleEnum } from '../models/common';
import { CookieService } from 'ngx-cookie-service';

export type AwsExportsType = 'alumni' | 'ar';

const logger = new ConsoleLogger('Event-Amplify');


@Injectable({  
  providedIn: 'root'
})
export class AmplifyAuthService {

  status: any = {
    islogged : false,
    token : undefined
  }

  languageIT = {
    'Back to Sign In' : 'Indietro',
    'Forgot your password?' : 'Hai dimenticato la password?',
    'Send Code' : 'Invia codice',
    'New password' : 'Nuova password',
    'Enter your new password' : 'Inserisci la nuova password',
    'Submit' : 'Invia',
    'Verification code' : 'Codice di verifica ricevuto via email',
    'Enter code' : 'Inserisci il codice ricevuto',
    'Change Password' : 'Cambio password',
    'Change' : 'Invia'
  }

  private poolARStatusSource = new BehaviorSubject(this.status);
  poolARStatus = this.poolARStatusSource.asObservable();

  private poolAlumniStatusSource = new BehaviorSubject(this.status);
  poolAlumniStatus = this.poolAlumniStatusSource.asObservable();

  private poolConfigStatusDefault: any = environment.aws.ar.CONFIG_COGNITO_ALUMNI;
  private poolConfigStatusSource = new BehaviorSubject(this.poolConfigStatusDefault);
  poolConfigStatus = this.poolConfigStatusSource.asObservable();

  listener = (data: any) => {
    console.log('PRIMA', data);
      switch (data.payload.event) {
        case 'signedIn':
            this.setState(data.payload?.event);
            logger.info('user signed in');
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signedOut':
            this.setState(data.payload?.event);
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':

            logger.info('the Auth module is configured');
    }
    console.log('event service amplify', data.payload);
  }

  constructor(public translate: TranslateService, private cookieService: CookieService) {
    I18n.putVocabulariesForLanguage('it', this.languageIT);
    I18n.setLanguage(this.translate.currentLang?.toLowerCase() || LocaleEnum.IT);
    Hub.listen('auth', this.listener);
    this.translate.onLangChange.subscribe(() => {console.log('reload language', this.translate.currentLang.toLowerCase()); I18n.setLanguage(this.translate.currentLang.toLowerCase())});
  }

  private getFileConfig(pool: AwsExportsType){
    return (pool == 'alumni' && environment.aws.ar.CONFIG_COGNITO_ALUMNI) ||  (pool == 'ar' &&  environment.aws.ar.CONFIG_COGNITO_AR) || null; 
  }

  async setState(status: string) {
    const token = (await fetchAuthSession()).tokens?.idToken;
    const accessToken = (await fetchAuthSession()).tokens?.accessToken.toString()
    const s = {
      islogged: status === 'signedIn' ? true : false,
      token: status === 'signedIn' ? accessToken : undefined
    };
    
    if(token?.payload.iss){
      this.getPool(token?.payload.iss) === 'alumni' ? this.poolAlumniStatusSource.next( s ) : this.poolARStatusSource.next( s );  
    }
   
  }

  private getPool(iss: string){
    if(iss.includes(environment.aws.ar.CONFIG_COGNITO_ALUMNI.aws_user_pools_id)) return 'alumni';
    else if(iss.includes(environment.aws.ar.CONFIG_COGNITO_AR.aws_user_pools_id)) return 'ar';
    else return null;
  }
   
 

  public config(pool: any) {      
    let configPoll = this.getFileConfig(pool);
    if(configPoll && this.poolConfigStatusDefault !== configPoll ) {
      Amplify.configure(configPoll);
      this.poolConfigStatusDefault = configPoll;
      this.poolConfigStatusSource.next( configPoll );
    }
    // console.log('change pool', pool);
  }

  async isLoggedIn(pool : AwsExportsType){
    try{
      
      this.config(pool);

      const islogged = await getCurrentUser().then(() => { return true; }).catch(() => {  return false; });
      
      
      //let islogged = await Auth.currentAuthenticatedUser().then(() => { return true; }).catch(() => {  return false; });
      
      return islogged;
    } 
    catch (error) {
      console.log('error isLoggedIn: ', error);
      return false;
    }    
  }

  async logout(pool: AwsExportsType){  
    try {
      this.config(pool);
      this.setState('signedOut');
      return await signOut();
    } 
    catch (error) {
        console.log('error signing out: ', error);
        return false;
    }
  }

  async signUp( params: any, pool: AwsExportsType ) {
    this.config(pool);
    try {
        const { userId } = await signUp(params);
        return {
          success: true,
          result: userId
        } 
    } catch (error) {
        console.log('error signing up:', error);
        return {
          success: false,
          result: error
        };
    }
  }

  async getToken(pool: AwsExportsType){
    this.config(pool);
    return await (await fetchAuthSession()).tokens?.accessToken.toString();
   // return (await (await currentSession()).getAccessToken()).getJwtToken();
  }

  async setIsLoggedAR(token: string){   
    if(await this.isLoggedIn('ar')) await this.logout('ar');
    return this.cookieService.get(token) ? true : false; 
  }

  async isLoggedAR(token: string){
    return this.cookieService.get(token) ? true : false;
  }

  async removeTokenAR(token: string){
    this.cookieService.delete(token);
  }

}
