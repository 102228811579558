<div class="alphabet-selector">
    @for (letter of alphabetLetters; track $index) {
        <span
                [ngClass]="{
                    'alphabet-selector--letter': true,
                    'enabled': enabledLetters.includes(letter),
                    'active': letter === selectedLetter
                }"
                (click)="selectLetter(letter)">
            {{letter}}
        </span>
    }
</div>
