{
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_ZnDluSa3V",
    "aws_user_pools_web_client_id": "4higs9mar833m6qm0rfv8htokd",
    "oauth": {
        "domain": "prometeia-alumni.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.prometeia.it/alumni-authenticated",
        "redirectSignOut": "https://www.prometeia.it/alumni-disconnected",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}