@if (loading) {
    <div class="page-loading"></div>
}
@else if (pageData) {
    <div class="page insights-article {{categoryCssClass}}">
        <div class="cover-colored-filter"></div>
        
        <div class="article-and-related">
            <div class="title-box"></div>

            <div class="article-content">
                <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>

                @if (pageData.isContactPage) {
                    <div class="btn-container contact-us">
                        <app-prom-animated-button [glowOnClick]="true" [bgColor]="'light'" (click)="openContactDialog()">
                            {{'GENERAL.REQUEST_DEMO' | translate | uppercase}}
                        </app-prom-animated-button>
                    </div>
                    <!-- <p class="debug">
                        l'indirizzo email di contatto è: <a href="mailto:{{pageData.contactUsEmail}}">{{pageData.contactUsEmail}}</a>
                    </p> -->
                }

                @if (slug && ['whistleblowing-prometeia-spa', 'whistleblowing-prometeia-sim', 'cookies', 'cookies-sim'].includes(slug)) {
                    @switch (slug) {
                        @case ('whistleblowing-prometeia-spa') {
                            <div class="download-btn">
                                <a href="{{whistleblowingLink}}" target="_blank">
                                    <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'">
                                        {{'FORMS.SUBMIT_REPORT' | translate | uppercase}}
                                    </app-prom-animated-button>
                                </a>
                            </div>
                        }
                        @case ('whistleblowing-prometeia-sim') {
                            <div class="download-btn">
                                <a href="{{whistleblowingLinkSIM}}" target="_blank">
                                    <app-prom-animated-button class="button" [theme]="'sim-red'" [bgColor]="'light'">
                                        {{'FORMS.SUBMIT_REPORT' | translate | uppercase}}
                                    </app-prom-animated-button>
                                </a>
                            </div>
                        }
                        @case ('cookies') {
                            <div class="download-btn">
                                <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" (click)="reopenCookiesSettings()">
                                    {{'COOKIES SETTINGS'}}
                                </app-prom-animated-button>
                            </div>
                        }
                        @case ('cookies-sim') {
                            <div class="download-btn">
                                <app-prom-animated-button class="button" [theme]="'sim-red'" [bgColor]="'light'" (click)="reopenCookiesSettings()">
                                    {{'COOKIES SETTINGS'}}
                                </app-prom-animated-button>
                            </div>
                        }
                    }
                }
    
                @if (pageData.body.includes('|__BUTTON_HERE__|') && pageButton) {
                    @let bodies = pageData.body.split('|__BUTTON_HERE__|');

                    @for (bodyFragment of bodies; track $index; let isLast = $last) {
                        <div class="body inner-html inner-html--insights-article {{isSimPage ? 'inner-html--sim-general-article' : ''}}" data-aos="fade-up" [innerHTML]="bodyFragment | safe : 'html'"></div>
                    
                        @if (!isLast) {
                            <div class="download-btn">
                                <a [href]="pageButton.attachment" target="_blank" download>
                                    <app-prom-animated-button class="button" [theme]="isSimPage ? 'sim-red' : 'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                        @if (currentLang === 'en') {
                                            {{pageButton.label.en | uppercase}}
                                        }
                                        @else {
                                            {{pageButton.label.it | uppercase}}
                                        }
                                    </app-prom-animated-button>
                                </a>
                            </div>
                        }
                    }
                }
                @else {
                    <div class="body inner-html inner-html--insights-article {{isSimPage ? 'inner-html--sim-general-article' : ''}}" data-aos="fade-up" [innerHTML]="pageData.body | safe : 'html'"></div>
                }

                @if (slug && ['whistleblowing-prometeia-spa', 'whistleblowing-prometeia-sim'].includes(slug)) {
                    @switch (slug) {
                        @case ('whistleblowing-prometeia-spa') {
                            <div class="download-btn">
                                <a href="{{whistleblowingLink}}" target="_blank">
                                    <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'">
                                        {{'FORMS.SUBMIT_REPORT' | translate | uppercase}}
                                    </app-prom-animated-button>
                                </a>
                            </div>
                        }
                        @case ('whistleblowing-prometeia-sim') {
                            <div class="download-btn">
                                <a href="{{whistleblowingLinkSIM}}" target="_blank">
                                    <app-prom-animated-button class="button" [theme]="'sim-red'" [bgColor]="'light'">
                                        {{'FORMS.SUBMIT_REPORT' | translate | uppercase}}
                                    </app-prom-animated-button>
                                </a>
                            </div>
                        }
                    }
                }
                
                @if (pageData.attachment) {
                    <div class="download-btn">
                        <a [href]="pageData.attachment" target="_blank" download>
                            <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                {{'GENERAL.DOWNLOAD' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                }
            </div>
    
            <div class="related-wrapper">
                <div class="related-list"></div>
            </div>
        </div>
    </div>
}
@else if (pageDataErrorCode) {
    <app-page-not-found></app-page-not-found>
}
@else {
    <div class="page-loading"></div>
}
