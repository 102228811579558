<section class="reports-list bgc--{{bgColor}}">
    <div class="section-content">
        <h3>Reports</h3>

        <div class="report-cards">
            @for (report of reports; track $index) {
                <div class="report-card" data-aos="fade-up" [routerLink]="[report.route]">
                    <div class="front-face">
                        <!-- <h4 class="card-title title-color--{{report.titleColor || 'blue'}}">{{report.title | uppercase}}</h4> -->
                        <img [src]="report.img" alt="report_image">
                    </div>

                    <div class="back-face">
                        <h4 class="card-title">{{report.title}}</h4>
                        <p class="card-body">{{report.body}}</p>
                    </div>
                </div>
            }
        </div>
    </div>
</section>
