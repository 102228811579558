import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { IAboutUsPageSocialResponsibility } from '../../../models/about-us';
import { IMediaSectionItem, LocaleEnum } from '../../../models/common';
import { PageRoots } from '../../../models/pages';
import { scrollToTop } from '../../../services/utilities.service';
import { CommonModule } from '@angular/common';
import { PromCardsListComponent } from "../../../components/prom-cards-list/prom-cards-list.component";
import { ImageCardComponent } from "../../../components/image-card/image-card.component";
import { QuoteSectionComponent } from "../../../components/quote-section/quote-section.component";
import { VIDEO_SOURCES } from '../../../models/constants';

const ROOT_SLUG: PageRoots = 'about-us';

@Component({
  selector: 'app-social-responsibility-page',
  templateUrl: './social-responsibility-page.component.html',
  styleUrl: './social-responsibility-page.component.scss',
  standalone: true,
  imports: [CommonModule, LongArrowDownComponent, PromAnimatedButtonComponent, PromCardsListComponent, ImageCardComponent, QuoteSectionComponent],
})
export class SocialResponsibilityPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SOURCES.SOCIAL_RESPONSIBILITY;

  override get slug() {
    return "social-responsibility";
  }

  override pageData?: IAboutUsPageSocialResponsibility;

  get endBanner(): IMediaSectionItem {
    return {
      type: 'text',
      size: 'full',
      theme: 'electric-blue',
      title: "Vuoi saperne di più? Scarica la Relazione di Sostenibilità",
      download: {
        label: "Download",
        url: this.pageData?.report.url
      }
    };
  }

  override ngOnInit(): void {
    super.staticSlugInit();
  }

  override async loadPage() { //TODO remove
    // console.log(ROOT_SLUG, "->", this.activatedRoute.snapshot.paramMap.get('slug'));

    this.pageData = PAGE_DATA_MOCK;
  }

}

const PAGE_DATA_MOCK: IAboutUsPageSocialResponsibility = {
  "root": "about-us",
  "category": "",
  "slug": "social-responsibility",
  "locale": LocaleEnum.IT,
  "title": "Prometeia Corporate Social Responsibility",
  "subtitle": "Cresciamo nel rispetto dell'ambiente",

  "report": {
    "label": "relazione di sostenibilità",
    "url": "https://www.prometeia.com/cdn/upload/2024/5/d4360785-f5ab-4414-9d4d-6c44839031d9.pdf",
  },

  "carousel": {
    "items": [{
      "quote": "Prometeia persegue una crescita sostenibile nel tempo, creando valore per tutti gli stakeholder, applicando i valori radicati nella sua storia, nella sua identità e nel suo lavoro quotidiano",
      "label": "Angelo Tantazzi",
      "details": "Presidente",
      "cover": "/assets/images/prom-gradient.png"
    }]
  },

  "values": {
    "title": "Valori e principi ispiratori",
    "items": [
      {
        "cover": "/assets/images/prom-gradient.png",
        "title": "",
        "body": "<p>Dal 2022, la Costituzione italiana riconosce (art. 9 e art. 41) la tutela dell'ambiente, della biodiversità e degli ecosistemi e richiede che le attività imprenditoriali siano svolte senza danneggiare l'ambiente e la salute.</p>",
      },
      {
        "cover": "/assets/images/prom-gradient.png",
        "title": "",
        "body": "<p>Prometeia segue la legislazione europea in materia di sostenibilità e supporta in modo proattivo i propri clienti nella sua attuazione.</p><p>Prometeia persegue la neutralità climatica entro il 2050 e la just transition del Green Deal UE.</p>",
      },
      {
        "cover": "/assets/images/prom-gradient.png",
        "title": "",
        "body": `<p>Firmataria dell'UNGC dal 2018, Prometeia incorpora nel suo comportamento aziendale i "Dieci principi del Global Compact delle Nazioni Unite" su diritti umani, lavoro, ambiente e lotta alla corruzione.</p>`,
      },
      {
        "cover": "/assets/images/prom-gradient.png",
        "title": "",
        "body": `<p>Firmataria dei PRI dal 2015,
                  Prometeia fa propri i "Sei Principi
                  PRI" nella sua attività di fornitore di
                  servizi agli Investitori Istituzionali
                  attraverso Prometeia Advisor Sim.</p>`,
      },
      {
        "cover": "/assets/images/prom-gradient.png",
        "title": "",
        "body": `<p>Prometeia si ispira all'Agenda 2030
                  per lo Sviluppo Sostenibile, adottata
                  dalle Nazioni Unite nel 2015, che
                  fornisce un progetto condiviso per la
                  pace e la prosperità delle persone e
                  del pianeta, oggi e nel futuro.</p>`,
      }
    ]
  },

  "environment": {
    "body": "Cresciamo nel rispetto dell'ambiente",
    "items": [
      {
        "title": "LEED Gold",
        "body": "La nuova sede del Gruppo ha ottenuto il riconoscimento internazionale LEED® Gold."
      },
      {
        "title": "HQ",
        "body": "Riduzione del 45% del consumo di acqua e del 31% del consumo di energia. Disponibilità di luce naturale per il 78% delle aree occupate."
      },
      {
        "title": "Energie rinnovabili",
        "body": "5% energia consumata e autoprodotta da impianto fotovoltaico; consumo energetico in quota parte da rinnovabili."
      },
      {
        "title": "Tecnologie",
        "body": "Virtualizzazione e ambienti cloud sono utilizzati per ottenere risparmi energetici, con sistemi di stampa e videochiamate."
      },
      {
        "title": "Trasporti",
        "body": "Flotta aziendale con veicoli elettrici; strutture che incentivano il car pooling e bike."
      },
      {
        "title": "Mobilità",
        "body": "Sedi con buona accessibilità e basse emissioni di CO2 e PM10."
      },
      {
        "title": "Riforestazione",
        "body": "«Foresta Prometeia» in Camerun e Progetto «PrintReleaf» in Madagascar e Repubblica Dominicana."
      },
    ]
  },
  "people": {
    "body": "Le persone sono la nostra risorsa più importante",
    "items": [
      {
        "title": "Formazione",
        "body": "Oltre 63.000 ore erogate. Formazione ESG dedicata con 3.500 ore erogate e percorso «ESG Essential a Prometeia» per tutti i dipendenti.",
      }
    ]
  },
  "community": {
    "body": "Sosteniamo le comunità in cui operiamo",
    "items": [
      {
        "title": "Materialità e stakeholder engagement",
        "body": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
      }
    ]
  },

  "esg": {
    "title": "Our ESG Expertise & Solutions",
    "body": "La nostra offerta di analisi ESG per valutare la sostenibilità dei portafogli e gestire i rischi climatici e ambientali.",
    "items": [
      {
        "title": "ESG Data",
        "body": "Offriamo analisi complete per valutare la sostenibilità dei portafogli finanziari e stimare gli impatti dei rischi climatici. Le nostre soluzioni aiutano i gestori patrimoniali a fornire consulenze informate e conformi e ad analizzare la rilevanza dei rischi climatici nei portafogli gestiti.",
        "link": {
          "label": "",
          "route": "/it/solutions/esg-data"
        },
      },
      {
        "title": "Climate & Environmental Risk Management",
        "body": "Offriamo competenze e soluzioni che consentono alle banche di allineare il proprio portafoglio e affrontare gli stress test climatici, dalla raccolta dati al report finale, come richiesto dalla BCE, sia per quanto riguarda il rischio fisico che il rischio di transizione.",
        "link": {
          "label": "",
          "route": "/it/expertise/climate-environmental-risk-management"
        },
      },
      {
        "title": "Sustainability for Institutional Investors",
        "body": "Forniamo agli investitori istituzionali soluzioni per affrontare le sfide dello sviluppo sostenibile e integrare i fattori ESG allʼinterno di una gestione finanziaria efficiente, attraverso modelli proprietari che permettono il monitoraggio costante e il reporting.",
        "link": {
          "label": "",
          "url": "https://www.prometeia.com/esg/sustainability-for-institutional-investors/"
        },
      },
    ]
  }
}
