/**
  @example ```console.log("YT1", getYoutubeID('https://www.youtube.com/watch?v=8ZLmSNI9G5M&t=1s'));```
  @example ```console.log("YT2", getYoutubeID('https://youtu.be/8ZLmSNI9G5M?si=11z8lX8UjueVj2aB'));```
  @example ```console.log("YT3", getYoutubeID('https://www.youtube.com/watch?v=8ZLmSNI9G5M&t=1s&list=PL1F4Dp9hUwX0p5HsKz2h6ZBzq0VpWVn0N'));```
  @example ```console.log("YT4", getYoutubeID('https://www.youtube.com/embed/8ZLmSNI9G5M?si=11z8lX8UjueVj2aB'));```
  @returns {string | null} The ID of the YouTube video (eg. "8ZLmSNI9G5M"), or the regEx doesn't match
*/
export function getYoutubeID(url?: string): string | null {
  if (!url) { return null; }
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function roundTo(num: number, places: number, type: "floor" | "ceil" | "round" = "round") {
  const factor = 10 ** places;
  switch (type) {
    case "floor":   return Math.floor(num * factor) / factor;
    case "ceil":    return Math.ceil(num * factor) / factor;
    default:        return Math.round(num * factor) / factor;
  }   
}

export function scrollToTop(options?: {behavior?: ScrollBehavior}) {
  if (options) {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: options.behavior ?? 'auto',
    });
  } else {
    window.scroll(0, 0);
  }
}

export function beautifyString(originalString: string): string {
  if (!originalString) { return ''; }
  return originalString.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
}

export function deepClone(obj: any) {
  return structuredClone(obj);
}


/**
 * @description Calculate the similarity between two strings, based on the edit distance.
 * @param {string} s1 The first string.
 * @param {string} s2 The second string.
 * @example ```console.log( "Similarity", similarity('Stack Overflow','Stack Ovrflw') );``` should return `0.8571428571428571`
 * @returns {number} The similarity between the two strings, between 0 (completely dissimilar) and 1 (completely similar).
*/
export function stringSimilarity(s1: string, s2: string): number {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength + "");
}




/** SUPPORT FUNCTIONS */

function editDistance(s1: string, s2: string) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}
