import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { SectionNewsHeadlinesComponent } from "../../../components/section-news-headlines/section-news-headlines.component";
import { RouterModule } from '@angular/router';
import { ISitePageNews } from '../../../models/pages';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { PageNotFoundComponent } from "../../page-not-found/page-not-found.component";
import { SafePipe } from "../../../pipes/safe.pipe";
import { Debounce } from 'lodash-decorators';
import { patchAnchors } from '../../../services/utilities.service';

@Component({
  selector: 'app-insights-article-page',
  templateUrl: './insights-article-page.component.html',
  styleUrl: './insights-article-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SafePipe, SectionContactUsComponent, SectionNewsHeadlinesComponent, PromAnimatedButtonComponent, PageNotFoundComponent],
})
export class InsightsArticlePageComponent extends BaseSitePageComponent {

  get categoryCssClass(): string {
    if (!this.pageData) return "";
    return this.pageData.category!.toLowerCase().replaceAll(" ", "-");
  }

  newsList?: ISitePageNews;

  @Debounce(10)
  override async loadPage() {
    if (!this.slug) {
      return;
    }

    this.loading = true;
    try {
      this.pageData = await this.api.pages.getInsightsArticle(this.slug);
      // this.patchAnchors();
      this.loadNewsList();
    } catch (ex: any) {
      this.pageData = undefined;
      console.error(ex);
      this.pageDataErrorCode = ex.status;
    } finally {
      this.loading = false;
    }
  }

  private async loadNewsList() {
    if (!this.slug || !this.pageData) {
      return;
    }

    try {
      const res = this.pageData.category == 'case-studies' ? await this.api.pages.latestCaseStudiesInsights() : await this.api.pages.latestInsights();
      const sameNewsIndex = res.items.findIndex(x => x.slug == this.slug);
      if (sameNewsIndex > -1) {
        /** remove the same news from the list, if it exists */
        res.items.splice(sameNewsIndex, 1);
      } else {
        /** if the same news doesn't exist, it removes the last one from the list to avoid more than 4 "insights" */
        res.items.pop();
      }
      this.newsList = res;
    } catch (ex) {
      console.error(ex);
    }
  }

  private patchAnchors() {
    if (this.pageData?.body) {
      this.pageData.body = patchAnchors(this.pageData.body) ?? "";
    }
  }

}
