import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

// doc: https://michalsnik.github.io/aos/
import * as AOS from 'aos';

import { ApiService } from './services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleEnum, DEFAULT_LANG, SUPPORTED_LANGS } from './models/common';
import { RestClientService } from './services/rest-client.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'Prometeia';

  localeFromRouter?: string;

  constructor(
    private readonly api: ApiService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly restClientService: RestClientService,
  ) {
    this.initAPI();
    this.initTranslations();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const localeFromRouter = event.urlAfterRedirects.split("/")[1];
        if (SUPPORTED_LANGS.includes(localeFromRouter as LocaleEnum)) {
          this.localeFromRouter = localeFromRouter;
          if (localeFromRouter !== this.translate.currentLang) {
            // console.log('NavigationEnd', localeFromRouter, this.translate.currentLang);
            this.translate.use(localeFromRouter);
          }
        }
      }
    });
  }

  ngOnInit() {
    // cheatsheet here: https://easings.net/
    AOS.init({
      easing: 'ease',
      duration: 1000,
      offset: -100,
      delay: 250,
      once: false
    });
  }

  async initAPI() {
    /** tries to init the rest client with a 100ms delay */
    while (!this.api.isRestClientReady) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  initTranslations() {
    this.translate.addLangs(Object.values(LocaleEnum));
    this.translate.setDefaultLang(DEFAULT_LANG);
    
    const currentUrl = this.router.url != '/' ? this.router.url : `/${window.location.href.split("/")[3]}/`;
    if (currentUrl.startsWith('/it/')) {
      this.translate.use('it');
    } else if (currentUrl.startsWith('/en/')) {
      this.translate.use('en');
    } else {
      this.translate.use(DEFAULT_LANG);
    }
  }
}
