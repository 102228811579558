<app-video-embedder [source]="slideVideoItem" [isOpenEvt]="openVideoSignal"></app-video-embedder>

<div class="carousel">
    <owl-carousel-o [options]="customOptions">
        @for (slide of slides; track $index) {
            <ng-template carouselSlide [id]="getSlideID(slide)">
                <div class="slide-content">
                    <img class="cover" [src]="slide.cover" alt="{{slide.label}} slide">
                    
                    <div class="content">
                        <h2 class="content--quote">
                            <span class="text--yellow">“</span>
                            <br>
                            {{slide.quote}}
                        </h2>
    
                        <h4 class="content--label-and-details"><span class="content--label">{{slide.label}}</span>, {{slide.details}}</h4>

                        <div class="button-wrap">
                            <app-prom-animated-button [theme]="'fluo-yellow'" [bgColor]="'dark'" [arrow]="'right'" [carouselFix]="true" (click)="openVideo(slide)">
                                {{slide.link.label | uppercase}}
                            </app-prom-animated-button>
                        </div>
                    </div>
                </div>
            </ng-template>
        }
    </owl-carousel-o>
</div>
