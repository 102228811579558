import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IPeopleGroupBios, IPersonBio } from '../../models/common';
import { DialogPersonBioComponent } from '../dialog-person-bio/dialog-person-bio.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-people-accordion',
  templateUrl: './people-accordion.component.html',
  styleUrl: './people-accordion.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class PeopleAccordionComponent {
  @Input() list?: (IPeopleGroupBios & { isOpen?: boolean})[];
  @Input() theme: 'default' | 'sim-red' = 'default';
  @Input() bgColor: 'deep-blue' | 'white' = 'white';
  @Input() paddingSize: 'default' | 'small' = 'default';

  constructor(
    private readonly dialog: MatDialog,
  ) { }

  onPersonClick(person: any) {
    // console.log('onPersonClick', person);

    const dialogRef = this.dialog.open(DialogPersonBioComponent, {
      id: 'modal-dialog-person',

      minWidth: 'min(750px, 90vw)',
      // maxWidth: '60%',
      
      // height: 'auto',
      // minHeight: '80%',
      // maxHeight: '100%',
      
      data: {bio: person},
    });
    // dialogRef.afterClosed().subscribe( async (res) => {});
  }
}
