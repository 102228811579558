import { Component, Input } from '@angular/core';
import { ISitePageCard } from '../../models/common';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-prom-card',
  templateUrl: './prom-card.component.html',
  styleUrl: './prom-card.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class PromCardComponent {
  @Input() card?: ISitePageCard;
  @Input() size: 'full' | 'half' | 'third' = 'half';
  @Input() arrowColor: 'blue' | 'green' = 'blue';
  @Input() titleColor: 'default' | 'green' = 'default';
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';

  @Input() cardIndex: number = -1;
  @Input() forcedCardHeight: number | 'auto' = 'auto';

  /** used for pages with multiple prom-cards-list */
  @Input() sectionID?: string;

  constructor(
    private readonly router: Router,
  ) {}

  goToCardURL() {
    const url = this.card?.link?.url;
    const route = this.card?.link?.route;
    if (url) {
      window.open(url, '_blank');
    } else if (route) {
      this.router.navigate([route]);
    }
  }
}
