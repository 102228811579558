import { Component, Input } from '@angular/core';
import { ISitePageFlippingCard } from '../../models/common';

@Component({
  selector: 'app-flipping-card',
  templateUrl: './flipping-card.component.html',
  styleUrl: './flipping-card.component.scss',
  standalone: true,
  imports: [],
})
export class FlippingCardComponent {
  @Input() card?: ISitePageFlippingCard;
}
