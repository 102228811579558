import { Component } from '@angular/core';
import { scrollToTop } from '../../services/utilities.service';
import { ISitePageLink } from '../../models/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FOOTER_DATA_SIM, FOOTER_DATA_SIM__ITA } from '../../models/constants';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer-sim',
  templateUrl: './footer-sim.component.html',
  styleUrl: './footer-sim.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, PromAnimatedButtonComponent],
})
export class FooterSimComponent {

  readonly arURL: string = "https://am.advisor.prometeia.net/";

  footerData?: ISitePageLink[];
  itaFooterData?: ISitePageLink[];

  get currentLang(): string {
    return this.translate.currentLang;
  }

  currentYear = new Date().getFullYear();

  constructor(
    private readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadFooterData();
  }

  private async loadFooterData() {
    this.footerData = FOOTER_DATA_SIM;
    this.itaFooterData = FOOTER_DATA_SIM__ITA;
  }

  scrollToTop() {
    scrollToTop({behavior: "smooth"});
  }
}
