<div class="page home">
    <div class="page-content">
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>

        <section class="intro">
            <div class="section-content" data-aos="fade-up">
                @if (introData) {
                    <div class="title">
                        <h1 class="regular-title" [innerHTML]="introData.title"></h1>
                        <app-gradient-text-wrapper [animated]="true">
                            <h1>{{introData.highlightedTitle}}</h1>
                        </app-gradient-text-wrapper>
                    </div>
                }
            </div>

            <!-- <app-long-arrow-down></app-long-arrow-down> -->
        </section>

        <!-- <section style="color: rgb(34, 34, 34); padding-left: 40px;">
            <p>
                Esempio <u>Expertise</u>:
                <a [routerLink]="['expertise', 'credit-intelligence-optimization-strategy']">credit-intelligence-optimization-strategy</a>
            </p>

            <p>
                Esempio <u>Expertise</u> (Research):
                <a [routerLink]="['expertise', 'commodities-strategic-inputs']">commodities-strategic-inputs</a>
            </p>
    
            <p>
                Esempio <u>Solutions</u>:
                <a [routerLink]="['solutions', 'wealth-management']">wealth-management</a>
            </p>
    
            <p>
                Esempio <u>Customers</u>:
                <a [routerLink]="['customers', 'wealth-managers-financial-advisors']">wealth-managers-financial-advisors</a>
            </p>
        </section> -->

        <div class="home-content">
            <app-media-section [mediaSection]="pageData" [insightList]="insights.items"></app-media-section>
        </div>

        <app-section-contact-us></app-section-contact-us>
    </div>
</div>
