import { Component, Input } from '@angular/core';
import { SectionContactUsComponent } from '../section-contact-us/section-contact-us.component';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CONTACT_US_EMAILS } from '../../models/constants';
import { DialogContactComponent } from '../dialog-contact/dialog-contact.component';
import { DialogNoticeComponent } from '../dialog-notice/dialog-notice.component';
import { convertFormData, NewslettersSlugs, NewsletterSubscriptionForm } from '../newsletters/newsletters.component';

@Component({
  selector: 'app-section-contact-us-sim',
  templateUrl: './section-contact-us-sim.component.html',
  styleUrl: './section-contact-us-sim.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, PromAnimatedButtonComponent],
})
export class SectionContactUsSimComponent extends SectionContactUsComponent {

  override theme: 'default' | 'yellow' | 'red' = "red";
  override email: string = CONTACT_US_EMAILS.SIM;

  readonly text1 = "Get in touch<br>with our team";
  readonly text2 = "Subscribe to our<br>Anteo newsletter";
  @Input() anteoNewsletterText?: string;



  /** Anteo Newsletter */

  private readonly newsletterSlug = "anteo";

  async openNewsletterDialog() {
    const newsletterData = await this.loadNewsletterForm();
    if (!newsletterData || !newsletterData.slug) {
      return;
    }

    const dialogRef = this.dialog.open(DialogContactComponent, {
      id: 'modal-dialog-contact',

      minWidth: 'min(750px, 90vw)',
      // maxWidth: '60%',
      
      height: 'auto',
      minHeight: '80%',
      maxHeight: '100%',
      
      data: {
        /* title: `Newsletter - ${newsletterData.title}`, */
        list: newsletterData.sections, privacyPolicyLink: `/${this.translate.currentLang}/article/privacy-policy`,
        useWhiteTheme: true,
      },
    });
    dialogRef.afterClosed().subscribe( async (res) => {
      if (res) {
        // console.log("from form:", res);

        this.subscribeToNewsletter(this.newsletterSlug, convertFormData(res));
      }
    });
  }

  private async loadNewsletterForm() {
    // newsletter-form-fields__risk_news / newsletter-form-fields__trending_topics / newsletter-form-fields__anteo

    try {
      const res = await this.api.newsletters.get(this.newsletterSlug);

      /** rename position field */
      const positionField = res.sections.find((field: {id: string, title: string}) => field.id === 'position');
      if (positionField) {
        positionField.title = this.translate.instant('FORMS.POSITION');
      }

      /** move e-mail field after first name and last name fields */
      const emailFieldIndex: number = res.sections.findIndex((field: {id: string, title: string}) => field.id === 'email' || field.id === 'e-mail');
      if (emailFieldIndex !== -1) {
        const emailField = res.sections[emailFieldIndex];
        res.sections.splice(emailFieldIndex, 1);
        res.sections.splice(2, 0, emailField);
      }

      /** sets required fields */
      const defaultRequiredFields: string[] = ["name", "firstName", "lastName", "email", "e-mail"];
      const anteoRequiredFields: string[] = ["name", "firstName", "lastName", "email", "e-mail", "company", "positionCategory", "positionCategory_other"];
      const requiredFields: string[] = this.newsletterSlug === 'anteo' ? anteoRequiredFields : defaultRequiredFields;
      res.sections.forEach((field: {id: string, title: string, minValueLen: number, isRequired?: boolean}) => {
        field.isRequired = requiredFields.includes(field.id);
        // console.log(field);
      });

      return res;
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  private async subscribeToNewsletter(slug: string, formData: NewsletterSubscriptionForm) {
    const subscription: string = JSON.stringify(formData);
    try {
      const resp = await this.api.newsletters.post(NewslettersSlugs.Anteo, subscription);

      // if (resp?.status == 200 || resp?.status == "200 OK" || resp?.status == "OK") {
        this.dialog.open(DialogNoticeComponent, {
          id: 'modal-dialog-notice',
          
          data: {
            category: this.translate.instant('FORMS.NEWSLETTER_SUBSCRIBED'),
            title: "",
          },
        });
      // }
    } catch (ex: any) {
      const err = ex?.error?.error;
      // console.log("catched", err);

      if (err?.code && err.code == 3) {
        console.error("already registered");
      } else if (err?.code && err.code == 2) {
        console.error("double check the fields");
      } else /* if (err.status == 500) */ {
        console.error("try again later");
      }
    }
  }

}
