<div class="page search-page">
    <div class="page-content">
        <div class="search-box-wrap">
            <div class="search-box-spacer desktop-only"></div>
            <div class="search-box-close mobile-only">
                <button mat-icon-button (click)="goBack()">
                    <mat-icon aria-hidden="false" aria-label="close-search" fontIcon="close"></mat-icon>
                </button>
            </div>

            <div class="search-box-input">
                <mat-form-field class="search-box-input-element">
                    <input id="search-input" type="text" matInput [formControl]="searchBoxInput" (keyup.enter)="submitSearch()">
                </mat-form-field>
            </div>

            <div class="search-box-close desktop-only">
                <button mat-icon-button (click)="goBack()">
                    <mat-icon aria-hidden="false" aria-label="close-search" fontIcon="close"></mat-icon>
                </button>
            </div>
            <div class="search-box-go mobile-only">
                <button (click)="submitSearch()">
                    <img class="search-mobile-btn arrow" src="/assets/icons/right_arrow__white.svg"/>
                </button>
            </div>
        </div>

        @for(searchResult of searchResults; track searchResult.id) {
            @if (searchResult.list.length) {
                <div class="search-result-block {{searchResult.id}}-block">
                    <h3>
                        {{searchResult.title}}
                        <!-- <span>({{searchResult.list.length}})</span> -->
                    </h3>
    
                    <div class="search-result-list">
                        @for(searchItem of searchResult.list; track $index) {
                            @if ($index < searchResult.maxItemsToShow) {
                                <p>
                                    @if (searchResult.id === 'all') {
                                        <a [routerLink]="['/' + currentLang + '/' + (searchItem.root ?? searchItem.baseRoute ?? 'article') + '/' + searchItem.slug]">
                                            <span class="search-result-list--item-title">
                                                {{searchItem.title}}
                                            </span>

                                            @if (searchItem.root || searchItem.baseRoute) {
                                                <span class="search-result-list--item-separator">|</span>

                                                <span class="search-result-list--item-category italic">
                                                    @if (searchItem.root) {
                                                        {{removeKebabCase(searchItem.root)}} 
                                                    }
                                                    @if (searchItem.baseRoute) {
                                                        {{removeKebabCase(searchItem.baseRoute)}}
                                                    }
                                                </span>
                                            }
                                        </a>
                                    }
                                    @else {
                                        <a [routerLink]="['/' + currentLang, 'about-us', 'insights', 'article', searchItem.slug]">
                                            @if (searchItem.category) {
                                                <span class="search-result-list--item-category italic">
                                                    {{removeKebabCase(searchItem.category)}}
                                                </span>
    
                                                <span class="search-result-list--item-separator">|</span>
                                            }

                                            <span class="search-result-list--item-title">
                                                {{searchItem.title}}
                                            </span>

                                            @if (searchItem.publicationDate) {
                                                <span class="search-result-list--item-separator">|</span>
                                                
                                                <span class="search-result-list--item-date italic">
                                                    {{searchItem.publicationDate | date : ('DATE_FORMAT_W_SPACE' | translate)}}
                                                </span>
                                            }
                                        </a>
                                    }
                                </p>
                            }
                        }

                        @if (searchResult.list.length > searchResult.maxItemsToShow) {
                            <div class="more">
                                <app-prom-animated-button [theme]="'in-search-page'" [bgColor]="'dark'" (click)="onLoadMoreClick(searchResult.id)">
                                    {{'GENERAL.LOAD_MORE' | translate | uppercase}}
                                </app-prom-animated-button>
                            </div>
                        }
                    </div>
                </div>
            }
        }

        @if (isAllEmpty) {
            <div class="search-result-block no-results">
                @if (!loading) {
                    <div class="search-result-list">
                        <p>
                            {{'EMPTY_LISTS.EMPTY_WITH_SELECTED_FILTERS' | translate}}
                        </p>
                    </div>
                }
            </div>
        }
    </div>
</div>
