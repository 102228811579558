import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../base-site-page.component';
import { ISitePage } from '../../models/pages';
import { LocaleEnum } from '../../models/common';
import { PromAnimatedButtonComponent } from "../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrl: './article-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, PromAnimatedButtonComponent],
})
export class ArticlePageComponent extends BaseSitePageComponent {

  readonly categoryCssClass: string = "general-article";

  override pageData?: ISitePage & { body: string; attachment?: string; };

  override async loadPage() {
    if (!this.slug) {
      return;
    }

    this.pageData = PAGE_DATA_MOCK;

    // try {
    //   this.pageData = await this.api.pages.getInsightsArticle(this.slug);
    // } catch (ex) {
    //   console.error(ex);
    // }
  }

}


const PAGE_DATA_MOCK: ISitePage & { body: string } = {
  locale: LocaleEnum.IT,
  root: 'general-articles',
  slug: 'company-information',
  title: 'Company Information',
  subtitle: '',
  body: `<p><b>Registered office:</b> Piazza Trento e Trieste, 3 - 40137 Bologna (BO)</p>
        <p><b>Share capital:</b> € 906,263 i.v.</p>
        <p><b>Registration with the register of companies at the CCIAA of Bologna:</b> no. 03118330376</p>
        <p><b>Tax code - VAT number:</b> no. 03118330376</p>`,
}
