@if (!loading && pageData) {
    <div class="page expertise">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <app-long-arrow-down></app-long-arrow-down>
            </section>
            
            @if (pageData.body) {
                <section class="what-we-know">
                    <div class="section-content">
                        <h3>What we know</h3>
                        @if (pageData.body.startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.body"></p></div>
                        }

                        @if (pageData.cards) {
                            <app-prom-cards-list [cards]="pageData.cards.items"></app-prom-cards-list>
                        }
                    </div>
                </section>
            }

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection" data-aos="fade-up"></app-media-section>
                </section>
            }

            @if (isAlternateExpertisePage && pageData.flippingCards) {
                <app-section-reports-list [reports]="pageData.flippingCards.items"></app-section-reports-list>
            }

            @if (pageData.solutions) {
                <section class="solutions">
                    <div class="section-content">
                        <h3>Our Solutions</h3>
                        <app-routes-to-pages [baseRoute]="'solutions'" [items]="pageData.solutions.items" [size]="'full'"></app-routes-to-pages>
                    </div>
                </section>
            }

            <app-section-contact-us></app-section-contact-us>

            @if (pageData.news) {
                <app-section-news-headlines [news]="pageData.news.items" [currentLang]="currentLang"></app-section-news-headlines>
            }
        </div>
    </div>
}
