import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocaleEnum } from '../../models/common';
import { RestClientService } from '../../services/rest-client.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PromAnimatedButtonComponent } from '../prom-animated-button/prom-animated-button.component';
import { MobileHeaderMenuComponent } from "../mobile-header-menu/mobile-header-menu.component";
import { IHeaderRow } from '../../models/header';
import { CONTACT_US_EMAILS } from '../../models/constants';
import { ContactModalComponent } from '../section-contact-us/contact-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { HeaderDataService } from '../../services/header-data.service';

@Component({
  selector: 'app-header-sim',
  templateUrl: './header-sim.component.html',
  styleUrl: './header-sim.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatButtonModule, MatIconModule, PromAnimatedButtonComponent, MobileHeaderMenuComponent],
})
export class HeaderSimComponent extends ContactModalComponent {

  readonly arURL: string = "https://am.advisor.prometeia.net/";

  readonly simHeaderData: IHeaderRow[] = [
    {
      label: "Prometeia SPA",
      slug: "spa",
      url: "/",
    }
  ];

  isLanguagesOpen: boolean = false;

  constructor(
    private readonly restClient: RestClientService,
    private readonly router: Router,
    protected override readonly dialog: MatDialog,
    protected override readonly translate: TranslateService,
    protected override readonly api: ApiService,
    protected override readonly headerDataService: HeaderDataService,
  ) {
    super(dialog, translate, api, headerDataService);
  }

  changeLanguage(newLocale?: string) {
    const loc = newLocale ?? (this.translate.currentLang === LocaleEnum.EN ? LocaleEnum.IT : LocaleEnum.EN);
    this.isLanguagesOpen = false;
    this.translate.use(loc);
    // this.translate.reloadLang(this.translate.currentLang);
    this.restClient.setupLocalization(newLocale);
    this.reloadCurrentRoute(loc);
  }

  reloadCurrentRoute(newLocale: string) {
    let currentUrl = this.router.url;
    if (currentUrl.length == 7) {   // home-page (SIM)
      currentUrl = currentUrl == '/sim/it' ? currentUrl.replace("/sim/it", `/sim/${newLocale}`) : currentUrl.replace("/sim/en", `/sim/${newLocale}`);
    } else {
      currentUrl = currentUrl.startsWith("/sim/it/") ? currentUrl.replace("/sim/it/", `/sim/${newLocale}/`) : currentUrl.replace("/sim/en/", `/sim/${newLocale}/`);
    }

    this.router.navigateByUrl(currentUrl);
  }

  protected override openContactDialog() {
    return super.openContactDialog('red', CONTACT_US_EMAILS.SIM);
  }

}
