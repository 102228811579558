import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { SectionContactUsSimComponent } from "../../../components/section-contact-us-sim/section-contact-us-sim.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { scrollToTop } from '../../../services/utilities.service';

@Component({
  selector: 'app-sim-article',
  templateUrl: './sim-article.component.html',
  styleUrl: './sim-article.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SectionContactUsSimComponent, PromAnimatedButtonComponent],
})
export class SimArticleComponent extends BaseSitePageComponent {

  get baseRoute() {
    if (window.location.href.includes('in-formation-paths/latest')) {
      return 'in-formation-paths';
    }

    return this.activatedRoute.snapshot.paramMap.get('baseRoute');
  }

  override get slug() {
    if (window.location.href.includes('in-formation-paths/latest')) {
      return 'in-formation-paths';
    }
    
    return this.activatedRoute.snapshot.paramMap.get('slug');
  }

  override ngOnInit(): void {
    if (!window.location.href.includes('in-formation-paths/latest')) {
      super.ngOnInit();
    } else {
      super.staticSlugInit();
    }
  }

  override async loadPage() {
    if (!this.slug) {
      return;
    }

    try {
      switch (this.baseRoute) {
        case 'anteo':
          this.pageData = (await this.api.simPages.anteoArticle(this.slug)).news;
          break;
        case 'in-formation-paths':
          this.pageData = await this.api.simPages.get({ baseRoute: 'sim-event', slug: 'latest' });
          break;
        default:
          this.pageData = undefined;
      }
    } catch (ex) {
      console.error(ex);
    }
  }

}
