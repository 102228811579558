@if (!loading && pageData) {
    <div class="page careers--open-positions">
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.category"></h2></div>
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    
                    <div class="spontaneous-application-btn">
                        <a [href]="spontaneousApplicationURL" target="_blank">
                            <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'top-right'">
                                {{'CAREERS.OPEN_POSITIONS.SPONTANEOUS_APPLICATION' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                </div>

                <app-long-arrow-down [theme]="'fluo-yellow'"></app-long-arrow-down>
            </section>

            @if (openPositions) {
                <section class="open-positions">
                    <div class="section-content">
                        <div class="selectors-container">
                            <app-selector-ship [selectors]="selectors" [theme]="'careers'" (selectionChangedEvent)="onOpenPositionsFiltersSelectionChanged($event)" (stringSearchEvent)="onStringSearch($event)" (clearSearchEvent)="onClearSearch()"></app-selector-ship>
                        </div>

                        @if (openPositions.length) {
                            <app-routes-to-pages [baseRoute]="'careers/open-positions/'" [items]="openPositions" [size]="'full'" [theme]="'yellow'"></app-routes-to-pages>
                        }
                        @else if (!loadingOpenPositions) {
                            <h3 class="empty-list">{{'EMPTY_LISTS.EMPTY' | translate}}</h3>
                        }

                        @if (loadingOpenPositions) {
                            <div class="spinner">
                                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                            </div>
                        }
                        @else if (canLoadMore) {
                            <div class="load-more">
                                <app-prom-animated-button [theme]="'deep-blue'" [bgColor]="'dark'" [forceHoverColor]="'fluo-yellow'" (click)="onLoadMoreClick()">
                                    {{'GENERAL.LOAD_MORE' | translate | uppercase}}
                                </app-prom-animated-button>
                            </div>
                        }
                    </div>

                </section>
            }

            <app-media-section [mediaSection]="discoverOurTribe" [pageRoot]="'careers'" [currentLang]="currentLang"></app-media-section>

            <app-section-contact-us [theme]="'yellow'" [text]="'Any question?<br>Get in touch with our team!'"></app-section-contact-us>
        </div>
    </div>
}
