import { Injectable } from '@angular/core';
import { IShipSelector } from '../components/selector-ship/selector-ship.component';

@Injectable({
  providedIn: 'root'
})
export class SelectorShipService {

  /** eg: cache.insights will contain insights' filters's cache */
  private selectorsCache: any = {};

  constructor() { }

  public updateCache(id: string, selectors: IShipSelector[]) {
    // console.log(id, selectors);
    this.selectorsCache[id] = selectors;
  }

  public loadCache(id: string): IShipSelector[] | undefined {
    return this.selectorsCache[id];
  }
}
