@if (!loading && pageData) {
    <div class="page customers">
        <!-- <div class="intro-background"></div>
        <div class="intro-colored-filter"></div> -->
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <!-- <div class="category"><h2 [innerHTML]="pageData.category"></h2></div> -->
                    <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <div class="desktop-only section-content-image" [ngStyle]="{'background-image': 'url(' + pageData.heroImage + ')'}"></div>

                <div class="desktop-only section-content-colored-filter"></div>

                <app-long-arrow-down></app-long-arrow-down>
            </section>

            @if (pageData.intro) {
                <section class="extra-intro">
                    <div class="section-content">
                        @if (pageData.intro.title?.length) {
                            <h3>{{pageData.intro.title}}</h3>
                        }

                        @if (pageData.intro.body.startsWith('<p>')) {
                            <div class="body" data-aos="fade-up" [innerHTML]="pageData.intro.body"></div>
                        }
                        @else {
                            <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.intro.body"></p></div>
                        }
                    </div>
                </section>
            }

            @if (pageData.news) {
                <app-section-news-headlines [news]="pageData.news.items" [currentLang]="currentLang"></app-section-news-headlines>
            }

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection" data-aos="fade-up"></app-media-section>
                </section>
            }
            
            @if (pageData.expertise) {
                <section class="professional-services">
                    <div class="section-content">
                        <h3>Professional Services</h3>
                        <app-routes-to-pages [baseRoute]="'expertise'" [items]="pageData.expertise.items" [size]="'thirds'"></app-routes-to-pages>
                    </div>
                </section>
            }
            @if (pageData.expertiseResearch) {
                <section class="research">
                    <div class="section-content">
                        <h3>Research</h3>
                        <app-routes-to-pages [baseRoute]="'expertise'" [items]="pageData.expertiseResearch.items" [size]="'thirds'"></app-routes-to-pages>
                    </div>
                </section>
            }

            @if (pageData.solutions) {
                <section class="solutions">
                    <div class="section-content">
                        <h3>How we help</h3>
                        <app-routes-to-pages [baseRoute]="'solutions'" [items]="pageData.solutions.items" [size]="'full'"></app-routes-to-pages>
                    </div>
                </section>
            }

            <app-section-contact-us></app-section-contact-us>
        </div>
    </div>
}
