import { Injectable } from '@angular/core';
import { Debounce } from 'lodash-decorators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  
  private gtagAlreadyAdded: boolean = false;

  constructor() { }

  /** Google Analytics 
   * 
   * https://medium.com/madhash/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e
  */
  async createGTAG(gCode: string) {
    // console.log("createTag - already created?", this.gtagAlreadyAdded);
    if (!this.gtagAlreadyAdded && gCode) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${gCode}`;
      document.head.appendChild(gTagManagerScript);
  
      // register google analytics
      const gaScript = document.createElement('script');
      /** 
       * 'send_page_view': false = evita la prima chiamata, quando i dati non sono pronti
       * (le visite vengono tracciate in main-layout.component.ts)
       * 
       * 'anonymize_ip': true (vedi sotto)
       * https://developers.google.com/analytics/devguides/collection/gtagjs/ip-anonymization
       * 
       * gtag viene inizializzato in main-layout.component.ts durante la OnInit
      */
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
      `;
      document.head.appendChild(gaScript);

      this.initGTAG(gCode);

      this.gtagAlreadyAdded = true;
      // console.log("createGTAG done?", this.gtagAlreadyAdded, document.head.getElementsByTagName("script"));
    }
  }

  private initGTAG(gCode: string) {
    if(gCode) {
      // console.log("initGTAG()");
      gtag('js', new Date());
      gtag('config', `${gCode}`, {
        'send_page_view': false,
        'anonymize_ip': true 
      });

      // console.log("gtag initialized", gtag);
    }
  }

  public disable(gCode: string) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
    (window as any)[`ga-disable-${gCode}`] = true;
    console.log("Disabled gtag", (window as any)[`ga-disable-${gCode}`]);
  }

  public isActive() {
    return this.gtagAlreadyAdded;
  }

  // public pageView(title: string) {
  //   // config viene fatto in fase di setup iniziale (in app.component.ts)
  //   // gtag('js', new Date());
  //   // gtag('config', gCode/* , { 'page_title': title, 'page_path': window.location.pathname } */ );

  //   if (!this.gtagAlreadyAdded) {
  //     // console.log("gtag not initialized, cannot track page view");
  //     return;
  //   }
    
  //   /** https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
  //   // gtag('event', 'page_view', {'event_callback': function() {
  //   //   console.log("page view callback", title);
  //   // }});
  // }

  /** eg: in downloads */
  // public viewItem(label: string) {
  //   if (!this.gtagAlreadyAdded) {
  //     console.log("gtag not initialized, cannot track view item");
  //     return;
  //   }

  //   /** https://developers.google.com/analytics/devguides/collection/ga4/reference/events */
  //   gtag('event', 'view_item', {
  //     'event_label': label,
  //     'event_category': 'download',
  //     // 'event_callback': function() {
  //     //   console.log("view item callback", label);
  //     // }
  //   });
  // }

  public trackEventGenerateLead(params: {campaign: string, leadSource: string}) {
    this.trackEvent('generate_lead', {
      'lead_source': params.leadSource
    });
  }

  /** used in article pages if isContactPage set as "true", when tha page has been visited */
  public trackEventViewItem(params: {campaign?: string, leadSource?: string, url?: string}) {
    this.trackEvent('view_item', {
      'items': [
        {
          'item_id': params.campaign,
          'item_name': params.campaign,
          'affiliation': params.leadSource,
          // 'location_id': params.url
        }
      ]
    });
  }

  /** used in every form, on a successful submit */
  public trackEventSelectItem(params: {campaign?: string, leadSource?: string, url?: string}) {
    this.trackEvent('select_item', {
      'items': [
        {
          'item_id': params.campaign,
          'item_name': params.campaign,
          'affiliation': params.leadSource,
          // 'location_id': params.url
        }
      ]
    });
  }

  @Debounce(1000)
  public forcePageView() {
    // console.log("forcePageView() -->", document.title);
    this.trackEvent('page_view');
  }

  private trackEvent(eventName: string, params?: any) {
    if (!this.gtagAlreadyAdded) {
      console.log("gtag not initialized, cannot track event");
      return;
    }

    /** https://developers.google.com/analytics/devguides/collection/ga4/reference/events */
    gtag('event', eventName, params);
  }
}
