<div class="prom-borded-button arrow-{{arrow}}">
    <a class="link" [href]="url" target="_blank" download>
        {{label}}
        @switch (arrow) {
            @case ('down') {
                <span class="arrow-bg"><img src="/assets/icons/down_arrow.svg"/></span>
            }
            @case ('right') {
                <span class="arrow-bg"><img src="/assets/icons/right_arrow__white.svg"/></span>
            }
            @default { }
        }
    </a>
</div>
