import { Component } from '@angular/core';
import { ISitePageMediaSection } from '../../models/pages';
import { PromAnimatedButtonComponent } from "../../components/prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { scrollToTop } from '../../services/utilities.service';
import { Subscription } from 'rxjs';
import { AmplifyAuthService } from '../../services/amplify-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ArDialogAuthComponent } from '../../components/ar-dialog-auth/ar-dialog-auth.component';
import { DialogComponent } from '../../components/dialog/dialog.component';

@Component({
  selector: 'app-reserved-areas-list-page',
  templateUrl: './reserved-areas-list-page.component.html',
  styleUrl: './reserved-areas-list-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, PromAnimatedButtonComponent],
})
export class ReservedAreasListPageComponent {

  get currentLang(): string {
    return this.translate.currentLang;
  }

  pageData: {
    mediaSection: ISitePageMediaSection;
  } = {
    mediaSection: {
      items: [
        /** SPA - start */
        {
          type: 'text',
          size: 'full',
          title: 'Appia',
          body: `<p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Nulla sed
                quam in orci scelerisque dignissim.</p>`,
          url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
          cover: '/assets/images/tmp_AR_Appia.png',
          link: {
            label: 'Login',
            url: 'https://home-appia.prometeia.com/',
          }
        },
        {
          type: 'text',
          size: 'full',
          title: 'Banking & Markets Data Hub',
          body: `<p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Nulla sed
                quam in orci scelerisque dignissim.</p>`,
          url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
          link: {
            label: 'Login',
            url: 'https://banking-marketsdatahub.prometeia.it/login/',
          }
        },
        {
          type: 'text',
          size: 'full',
          title: 'Macro Intelligence',
          body: `<p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Nulla sed
                quam in orci scelerisque dignissim.</p>`,
          url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
          cover: '/assets/images/tmp_AR_Macro_Intelligence.png',
          link: {
            label: 'Login',
            url: 'https://macro.prometeia.it/MacroIntelligence/#/login',
          }
        },
        {
          type: 'text',
          size: 'full',
          title: 'Settori Industriali, Economie Locali & Commodity',
          body: `<p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Nulla sed
                quam in orci scelerisque dignissim.</p>`,
          url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
          link: {
            label: 'Login',
            route: 'reserved-area',
          }
        },
        /** SPA - end */

        /** SIM - start */
        {
          type: 'text',
          size: 'full',
          title: 'Asset Management Platform',
          body: `<p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Nulla sed
                quam in orci scelerisque dignissim.</p>`,
          url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
          cover: '/assets/images/tmp_AR_SIM_Asset.png',
          link: {
            label: 'Login',
            url: 'https://am.advisor.prometeia.net/flex/cm/pages/CommunityLoginOut.php/L/EN/BL/aHR0cDovL2FtLmFkdmlzb3IucHJvbWV0ZWlhLm5ldC9mbGV4L2NtL3BhZ2VzL1NlcnZlQkxPQi5waHAvU1VfL2hvbWU%3D',
          }
        },
        {
          type: 'text',
          size: 'full',
          title: 'Fondi pensione',
          body: `<p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Nulla sed
                quam in orci scelerisque dignissim.</p>`,
          url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
          cover: '/assets/images/tmp_AR_SIM_Pensioni.png',
          link: {
            label: 'Login',
            route: 'reserved-area',
          }
        }
        /** SIM - end */
      ]
    }
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly amplifyService: AmplifyAuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    scrollToTop();
  }

  scrollToTop = scrollToTop;


  /** RESERVED AREA */
  private COOKIE_TOKEN_AR : string = 'prom-ar-token';

  isLoggedAR : boolean = false;
  showLogout : boolean = false;

  private routerEventsSubscription?: Subscription;

  private destroyAR() {
    this.checkPoolStatus();
    this.routerEventsSubscription?.unsubscribe();
  }

  private async checkPoolStatus() {
    this.isLoggedAR = await this.amplifyService.isLoggedAR(this.COOKIE_TOKEN_AR);
    this.showLogout = this.isLoggedAR && window.location.href.includes('reserved-area');    
  }

  async login() {    
    const dialogRef = this.dialog.open(ArDialogAuthComponent, {
      width: '880px',
      id: 'modal-ar-login'
    });
    dialogRef.afterClosed().subscribe( async () => {
      this.checkPoolStatus();
    })
  }

  async logout() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      height: '177px',
      data: {title: '', text: this.translate.instant('AR.LOGOUT_CONFIRM'), onlyClose: false }
    });

    dialogRef.afterClosed().subscribe(async result => {   
      if(result){
        const r = await this.amplifyService.logout('ar');
        this.amplifyService.removeTokenAR(this.COOKIE_TOKEN_AR);
        this.router.navigate(['/']);
        this.checkPoolStatus();    
      }
      else{
        dialogRef.close();
      }
    });
  }  

  resevedArea(){
    if(!window.location.href.includes('reserved-area'))
      this.router.navigate(['/', 'reserved-area']);
    else window.location.reload();
  }
}
