@if (loading) {
    <div class="page-loading"></div>
}
@else if (article) {
    <div class="page sim-article {{baseRoute}}-article">
        <div class="cover-image">
            @if (baseRoute === 'anteo') {
                <img [src]="'/cdn2/site/anteo/sim_anteo_cover.png'">
            }
            @else if (baseRoute === 'in-formation-paths') {
                <img [src]="article.cover">
            }
        </div>
        <div class="cover-colored-filter"></div>
        
        <div class="article-and-related">
            <div class="title-box"></div>

            <div class="article-content">
                <div class="top-infos">
                    <div class="category"><h2 [innerHTML]="article.category"></h2></div>

                    <div class="pub-date">
                        @if (baseRoute === 'anteo') {
                            <p>{{article.pubDate | date: 'MMMM yyyy'}}</p>
                        }
                        @else if (baseRoute === 'in-formation-paths') {
                            <p>{{article.subtitle}}</p>
                        }
                    </div>
                </div>
                
                <br>
    
                <div class="title"><h1 [innerHTML]="article.title"></h1></div>
                <div class="subtitle authors"><p [innerHTML]="article.author"></p></div>
    
                <div class="body inner-html inner-html--insights-article inner-html--sim-article" data-aos="fade-up" [innerHTML]="article.body"></div>
                
                @if (article.attachment) {
                    <div class="download-btn">
                        <a [href]="article.attachment" target="_blank" download>
                            <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                {{'GENERAL.DOWNLOAD' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                }
            </div>
    
            <div class="related-wrapper">
                <div class="related-list">
                    @if (article.related?.length) {
                        <p class="related-list--title">Related Articles</p>
            
                        @for (related of article.related; track $index) {
                            <div class="related-list--item">
                                <p class="category">{{article.category | uppercase}}</p>
                                <p class="title" [routerLink]="['/sim/it/anteo/article', related.id]">{{related.title}}</p>
                            </div>
                        }
                    }
                </div>
            </div>
        </div>

        <div class="page-content">
            <app-section-contact-us-sim></app-section-contact-us-sim>      
        </div>
    </div>
}
@else if (pageDataErrorCode) {
    <app-page-not-found></app-page-not-found>
}
@else {
    <div class="page-loading"></div>
}
