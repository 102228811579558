import { Component } from '@angular/core';
import { IExpertisePage } from '../../models/expertise';
import { PromCardsListComponent } from "../../components/prom-cards-list/prom-cards-list.component";
import { MediaSectionComponent } from "../../components/media-section/media-section.component";
import { CommonModule } from '@angular/common';
import { RoutesToPagesComponent } from '../../components/routes-to-pages/routes-to-pages.component';
import { SectionContactUsComponent } from "../../components/section-contact-us/section-contact-us.component";
import { SectionNewsHeadlinesComponent } from "../../components/section-news-headlines/section-news-headlines.component";
import { LongArrowDownComponent } from "../../components/long-arrow-down/long-arrow-down.component";
import { SectionReportsListComponent } from "../../components/section-reports-list/section-reports-list.component";
import { PageRoots } from '../../models/pages';
import { BaseSitePageComponent } from '../base-site-page.component';

const ROOT_SLUG: PageRoots = 'expertise';

@Component({
  selector: 'app-expertise-page',
  templateUrl: './expertise-page.component.html',
  styleUrl: './expertise-page.component.scss',
  standalone: true,
  imports: [CommonModule, PromCardsListComponent, MediaSectionComponent, RoutesToPagesComponent, SectionContactUsComponent, SectionNewsHeadlinesComponent, LongArrowDownComponent, SectionReportsListComponent],
})
export class ExpertisePageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  get videoSrc() {
    if (!this.pageData?.parent) return "";
    return `/cdn2/site/intro-videos/${this.pageData.parent}.mp4`;
  };

  override pageData?: IExpertisePage;

  get isAlternateExpertisePage(): boolean {
    return this.pageData?.category?.toLowerCase() === "Research".toLowerCase();
  }

  get dataAosOffset() {
    return -window.innerHeight * 0.6;
  }

}
