import { AfterViewInit, Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { ISitePageReference } from '../../models/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Debounce } from 'lodash-decorators';
import { SCREEN_SIZE_MOBILE_VERTICAL } from '../../models/constants';
import { sleep } from '../../services/utilities.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-routes-to-pages',
  templateUrl: './routes-to-pages.component.html',
  styleUrl: './routes-to-pages.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class RoutesToPagesComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() items?: ISitePageReference[];
  @Input() baseRoute?: string;
  @Input() size: 'full' | 'thirds' = 'full';
  @Input() arrowColor: 'blue' | 'green' | 'yellow'  = 'green';
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';

  @Input() sectionID?: string;

  private longestTitleIndex = -1;
  private longestTitleNumber: number = 0;
  private longestTitle = '';

  loading = false;
  
  activatedRouteParamMapSubscription?: Subscription;
  routerEventsSubscription?: Subscription;
  langChangeSubscription?: Subscription;

  private _calculatedHeight: number = 150;

  set calculatedHeight(value: number) {
    this._calculatedHeight = value;
  }

  get calculatedHeight(): string {
    return this._calculatedHeight + "px";
  };

  get calculatedHeightAsNumber(): number {
    return this._calculatedHeight;
  }

  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    /** if the theme is yellow, force the arrow color to yellow too */
    if (this.theme === 'yellow') {
      this.arrowColor = 'yellow';
    }


    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((_) => {
      this.findTallestCard();
    });

    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.findTallestCard();
      }
    });

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.findTallestCard();
    });
  }

  @Debounce(1)
  private findTallestCard(): string {
    this.loading = true;

    this.longestTitleIndex = -1;
    this.longestTitleNumber = 0;
    this.longestTitle = '';
    this.calculatedHeight = 0;

    this.items?.forEach((item, index) => {
      if (item.slug && typeof item.slug == "string" && item.slug.startsWith('/')) {
        item.slug = item.slug.substring(1);
      }

      if (item.title.length > this.longestTitleNumber) {
        this.longestTitle = item.title;

        this.longestTitleNumber = item.title.length;
        this.longestTitleIndex = index;
      }
    });

    this.loading = false;

    return this.longestTitle;
  }
    
  ngAfterViewInit() {
    // console.log("ngAfterViewInit");
    this.debouncedSetCardsHeight();
  }

  ngOnChanges(event: any) {
    // console.log("ngOnChanges", event);
    if (event.baseRoute != undefined || event.size != undefined) {
      this.debouncedSetCardsHeight();
    }
  }

  @Debounce(10)
  private async debouncedSetCardsHeight() {
    return await this.setCardsHeight();
  }

  @HostListener('window:resize', ['$event'])
  private async setCardsHeight(): Promise<void> {
    do {
      await sleep(1);
    } while (this.loading || this.longestTitleIndex == -1);

    const screenWidth = window.screen.width;
    if ((screenWidth > SCREEN_SIZE_MOBILE_VERTICAL && this.size === 'thirds') || (screenWidth <= SCREEN_SIZE_MOBILE_VERTICAL && this.theme === 'yellow')) {
      this.calculatedHeight = 0;
      return;
    }

    const card = this.sectionID ? document.getElementById(`single-route-card-${this.longestTitleIndex}-${this.sectionID}`) : document.getElementById(`single-route-card-${this.longestTitleIndex}`) as HTMLElement | null;
    if (card) {
      if (screenWidth <= SCREEN_SIZE_MOBILE_VERTICAL) {
        this.calculatedHeight = card.clientHeight - 60;
      } else {
        /* -100 to account for vertical padding */
        this.calculatedHeight = card.clientHeight - (this.theme === 'yellow' ? 80 : 100);
      }
    }
  }
}
