@if (items) {
    <div class="routes-to-pages size-{{size}} theme--{{theme}}">
        @for (item of items; track $index) {
            <a class="unstyled-anchor" [routerLink]="['/' + baseRoute + '/' + item.slug]">
                <div id="single-route-card-{{$index}}{{sectionID ? '-' + sectionID : ''}}" class="single-route default-card-border" [ngStyle]="{height: calculatedHeightAsNumber > 0 ? calculatedHeight : 'auto'}">
                    <div class="content">
                        <div>
                            @if (item.jobArea || item.jobOffice) {
                                <h5 class="category">
                                    @if (item.jobArea) {
                                        <span class="job-position">{{item.jobArea}}</span>
                                    }
                                    @if (item.jobArea && item.jobOffice) {
                                        <span class="desktop-only">|</span>
                                    }
                                    @if (item.jobOffice) {
                                        <span class="office">{{item.jobOffice}}</span>
                                    }
                                </h5>
                            }
    
                            <p class="title">{{item.title}}</p>
                        </div>
    
                        <div class="flex-vertical-centered">
                            <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="">
                        </div>
                    </div>
                </div>
            </a>
        }
    </div>
}
