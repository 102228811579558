import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { IAboutUsPageInsightsArticle } from '../../../models/about-us';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { SectionNewsHeadlinesComponent } from "../../../components/section-news-headlines/section-news-headlines.component";
import { RouterModule } from '@angular/router';
import { ISitePageNews } from '../../../models/pages';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { NewsTypesWithIconEnum } from '../../../models/common';

@Component({
  selector: 'app-insights-article-page',
  templateUrl: './insights-article-page.component.html',
  styleUrl: './insights-article-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, SectionContactUsComponent, SectionNewsHeadlinesComponent, PromAnimatedButtonComponent],
})
export class InsightsArticlePageComponent extends BaseSitePageComponent {

  get categoryCssClass(): string {
    if (!this.pageData) return "";
    return this.pageData.category!.toLowerCase().replaceAll(" ", "-");
  }

  newsList?: ISitePageNews;

  override ngOnInit(): void {
    super.ngOnInit();

    // TODO: non dovrebbe essere necessario, appena tutte le pagine avranno il loadPage collegato
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('slug')) {
        this.loadPage();
      }
    });
  }

  override async loadPage() {
    if (!this.slug) {
      return;
    }

    try {
      this.pageData = await this.api.pages.getInsightsArticle(this.slug);
      this.loadNewsList();
    } catch (ex) {
      console.error(ex);
    }
  }

  private async loadNewsList() {
    if (!this.slug || !this.pageData) {
      return;
    }

    try {
      const res = this.pageData.category == 'case-studies' ? await this.api.pages.latestCaseStudiesInsights() : await this.api.pages.latestInsights();
      const sameNewsIndex = res.items.findIndex(x => x.slug == this.slug);
      if (sameNewsIndex > -1) {
        /** remove the same news from the list, if it exists */
        res.items.splice(sameNewsIndex, 1);
      } else {
        /** if the same news doesn't exist, it removes the last one from the list to avoid more than 4 "insights" */
        res.items.pop();
      }
      this.newsList = res;
    } catch (ex) {
      console.error(ex);
    }
  }

}
