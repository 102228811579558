<div [ngClass]="{'selector-ship': true, 'open': activeTab >= 0, 'closed': activeTab < 0 && !isClosing, 'closing': isClosing}" class="theme--{{theme}}">
    <div class="header">
        <div class="names-wrap flex-row">
            @for (item of selectors; track $index) {
                <div [ngClass]="{'selector names': true, 'active': $index === activeTab, 'not-active': activeTab >= 0 && $index !== activeTab}" (click)="onTabClick($index)">
                    <h4 class="title">
                        {{item.name}}
                        @let selectedOptionsNumber = getSelectedOptionsNumberBySeletorName(item.name);
                        @if (selectedOptionsNumber > 0) {
                            <span class="badge">({{selectedOptionsNumber}})</span>                        
                        }
                    </h4>
    
                    @if ($index === activeTab) {
                        <!-- <mat-icon class="open-state-icon" fontIcon="expand_less"></mat-icon> -->
                        <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__{{theme === 'careers' ? 'yellow' : 'blue'}}.svg" alt="" />
                    }
                    @else {
                        <!-- <mat-icon class="open-state-icon" fontIcon="expand_more"></mat-icon> -->
                        <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__{{theme === 'careers' ? 'yellow' : 'blue'}}.svg" alt="" />
                    }
                </div>
            }
        </div>

        <div  [ngClass]="{'selector search': true, 'active': searchIconTabIndex === activeTab, 'not-active': activeTab >= 0 && searchIconTabIndex !== activeTab}" (click)="onTabClick(searchIconTabIndex)">
            <!-- <p>🔍</p> -->
            <mat-icon class="search-icon" fontIcon="search"></mat-icon>
        </div>
    </div>

    <div [ngClass]="{'body': true, 'active': activeTab >= 0, 'not-active': activeTab < 0}">
        @if (activeTab >= 0 && activeTab !== searchIconTabIndex) {
            @let options = getOptionsBySeletorName();
            @for (item of options; track $index) {
                <div [ngClass]="{'option': true, 'selected': item.selected}" (click)="onItemClick(item)">
                    <p>{{item.name}}</p>
                </div>
            }
            @empty {
                @if (activeTab >= 0) {
                    <div class="no-options">
                        <p>
                            {{'EMPTY_LISTS.NO_' + ((selectors[activeTab].id ?? selectors[activeTab].name) | uppercase) | translate}}
                        </p>
                    </div>
                }
            }
        }
        @else if (activeTab === searchIconTabIndex) {
            <div class="{{theme}}-selector-search-box-input">
                <mat-form-field class="{{theme}}-selector-search-box-input-element on-light-bgc">
                    <input id="search-input" type="text" matInput [formControl]="searchBoxInput" (keyup.enter)="submitSearch()" [tabindex]="0">
                </mat-form-field>
            </div>
        }
    </div>
</div>

<!-- TABLET / MOBILE -->
<div [ngClass]="{'selector-ship-mobile': true, 'open': activeTab >= 0, 'closed': activeTab < 0 && !isClosing, 'closing': isClosing}" class="theme--{{theme}}">
    <div class="header">
        <div class="names-wrap flex-row">
            @for (item of selectors; track $index) {
                <div class="names-item" id="names-item-{{$index}}">
                    <div [ngClass]="{'selector names': true, 'active': $index === activeTab, 'not-active': activeTab >= 0 && $index !== activeTab}" (click)="onTabClick($index)">
                        <h4 class="title">
                            {{item.name}}
                            @let selectedOptionsNumber = getSelectedOptionsNumberBySeletorName(item.name);
                            @if (selectedOptionsNumber > 0) {
                                <span class="badge">({{selectedOptionsNumber}})</span>                        
                            }
                        </h4>
        
                        @if ($index === activeTab) {
                            <img class="open-state-icon simplified-arrow-less" src="/assets/icons/down_simplified_arrow__{{theme === 'careers' ? 'yellow' : 'blue'}}.svg" alt="" />
                        }
                        @else {
                            <img class="open-state-icon simplified-arrow-more" src="/assets/icons/down_simplified_arrow__{{theme === 'careers' ? 'yellow' : 'blue'}}.svg" alt="" />
                        }
                    </div>

                    <div [ngClass]="{'body': true, 'active': $index === activeTab, 'not-active': activeTab >= 0 && $index !== activeTab}">
                        @if (activeTab >= 0 && activeTab !== searchIconTabIndex) {
                            @for (item of item.options; track $index) {
                                <div [ngClass]="{'option': true, 'selected': item.selected}" (click)="onItemClick(item)">
                                    <p>{{item.name}}</p>
                                </div>
                            }
                            @empty {
                                @if (activeTab >= 0) {
                                    <div class="no-options">
                                        <p>
                                            {{'EMPTY_LISTS.NO_' + ((selectors[activeTab].id ?? selectors[activeTab].name) | uppercase) | translate}}
                                        </p>
                                    </div>
                                }
                            }
                        }
                    </div>
                </div>
            }
        </div>

        <div [ngClass]="{'selector search': true, 'active': searchIconTabIndex === activeTab, 'not-active': activeTab >= 0 && searchIconTabIndex !== activeTab}" (click)="onTabClick(searchIconTabIndex)">
            <!-- <p>🔍</p> -->
            <mat-icon class="search-icon" fontIcon="search">search</mat-icon>

            <div class="{{theme}}-selector-search-box-input">
                <mat-form-field class="{{theme}}-selector-search-box-input-element on-light-bgc">
                    <input id="search-input" type="text" matInput [formControl]="searchBoxInput" (keyup.enter)="submitSearch()" [tabindex]="0">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    @if (theme === 'careers') {
                        <img matSuffix class="search-mobile-btn arrow" src="/assets/icons/right_arrow__yellow.svg" (click)="submitSearch()">
                    }
                    @else {
                        <img matSuffix class="search-mobile-btn arrow" src="/assets/icons/right_arrow__blue.svg" (click)="submitSearch()">
                    }
                </mat-form-field>
            </div>

            <div></div>
        </div>
    </div>
</div>
