<div class="page home">
    <div class="page-content">
        <p>
            Esempio <u>Expertise</u>:
            <a [routerLink]="['expertise', 'credit-intelligence-optimization-strategy']">credit-intelligence-optimization-strategy</a>
        </p>

        <p>
            Esempio <u>Solutions</u>:
            <a [routerLink]="['solutions', 'wealth-management']">wealth-management</a>
        </p>

        <p>
            Esempio <u>Customers</u>:
            <a [routerLink]="['customers', 'wealth-managers-financial-advisors']">wealth-managers-financial-advisors</a>
        </p>
    </div>
</div>
