@if (items) {
    <div class="routes-to-pages size-{{size}} theme--{{theme}}">
        @for (item of items; track $index) {
            <div id="single-route-card-{{$index}}" class="single-route default-card-border" [ngStyle]="{height: size === 'thirds' ? calculatedHeight : 'auto'}" [routerLink]="['/' + baseRoute, item.slug]" data-aos="fade-up">
                <div class="content">
                    <div>
                        @if (item.jobArea || item.jobOffice) {
                            <h5 class="category">
                                @if (item.jobArea) {
                                    <span class="job-position">{{item.jobArea}}</span>
                                }
                                @if (item.jobArea && item.jobOffice) {
                                    |
                                }
                                @if (item.jobOffice) {
                                    <span class="office">{{item.jobOffice}}</span>
                                }
                            </h5>
                        }

                        <p class="title">{{item.title}}</p>
                    </div>

                    <div class="flex-vertical-centered">
                        <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="">
                    </div>
                </div>
            </div>
        }
    </div>
}
