@if (data?.length) {
    <div class="mobile-header-menu">
        <button mat-icon-button class="menu-button" [matMenuTriggerFor]="firstLevelMenu" aria-label="Menu button with a vertical three dot icon" (click)="toggleMenu()">
            <!-- <mat-icon class="menu-icon">more_vert</mat-icon> -->
            <app-burger-menu [isActive]="isMenuOpen()"></app-burger-menu>
        </button>
        
        <mat-menu #firstLevelMenu="matMenu" (closed)="toggleMenu()">
            @for (fl of data; track fl.slug) {
                @if (fl.children?.length) {
                    <button mat-menu-item [matMenuTriggerFor]="secondLevelMenu" (mouseenter)="onFirstLevelEnter(fl)">{{ fl.label | uppercase }}</button>
                }
                @else {
                    @if (fl.url) {
                        <a href="{{fl.url}}">
                            <button mat-menu-item>
                                {{ fl.label | uppercase }}
                                <!-- ↗ -->
                                <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                            </button>
                        </a>
                    }
                    @else if (fl.slug) {
                        <button mat-menu-item [routerLink]="['/' + currentLang, fl.slug]">{{ fl.label | uppercase }}</button>
                    }
                }
            }

            @if (mode == 'sim') {
                <div class="btn-container contact-us">
                    <app-prom-animated-button [glowOnClick]="true" size="small" [bgColor]="'dark'" [theme]="'sim-red'" (click)="openContactDialogRequest()">
                        {{'GENERAL.CONTACT_US' | translate | uppercase}}
                    </app-prom-animated-button>
                </div>

                @if (arURL) {
                    <div class="btn-container contact-us">
                        <a [href]="arURL" target="_blank">
                            <app-prom-animated-button size="small" [arrow]="'top-right'" [bgColor]="'dark'" [theme]="'sim-red'">
                                {{'AR.TITLE' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                }
            }
            @else {
                <div class="btn-container contact-us">
                    <app-prom-animated-button [glowOnClick]="true" size="small" (click)="openContactDialogRequest()">
                        {{'GENERAL.CONTACT_US' | translate | uppercase}}
                    </app-prom-animated-button>
                </div>
            }
        </mat-menu>

        <mat-menu #secondLevelMenu="matMenu">
            @for (sl of activeFirstLevel?.children; track sl.slug) {
                @if (sl.children?.length) {
                    <button mat-menu-item [matMenuTriggerFor]="thirdLevelMenu" (mouseenter)="onSecondLevelEnter(sl)"><span [innerHTML]="sl.label"></span></button>
                }
                @else {
                    @if (sl.url) {
                        <a href="{{sl.url}}">
                            <button mat-menu-item>
                                <span [innerHTML]="sl.label"></span>
                                <!-- ↗ -->
                                <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                            </button>
                        </a>
                    }
                    @else if (sl.slug) {
                        <button mat-menu-item [routerLink]="['/' + currentLang, firstLevelSlug, sl.slug]"><span [innerHTML]="sl.label"></span></button>
                    }
                }
            }
        </mat-menu>

        <mat-menu #thirdLevelMenu="matMenu">
            @for (item of activeSecondLevel?.children; track item.slug) {
                @if (item.url) {
                    <a href="{{item.url}}">
                        <button mat-menu-item>
                            {{ item.label }}
                            <!-- ↗ -->
                            <img class="external-link default" src="/assets/icons/external_link__white.svg" alt="">
                        </button>
                    </a>
                }
                @else if (item.slug) {
                    <button mat-menu-item [routerLink]="['/' + currentLang, firstLevelSlug, item.slug]">{{ item.label }}</button>
                }
            }
        </mat-menu>
    </div>
}
