import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { adjustBackPosition } from '../../services/utilities.service';
import { CookieService } from '../../services/cookie.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-dialog-contact',
  templateUrl: './dialog-contact.component.html',
  styleUrl: './dialog-contact.component.scss',
  standalone: true,
  imports: [CommonModule, MatDialogModule, TranslateModule, FormsModule, ReactiveFormsModule, MatInputModule, MatSelectModule, MatCheckboxModule, PromAnimatedButtonComponent],
})
export class DialogContactComponent implements OnInit {

  data?: {
    privacyPolicyLink: string,
    title?: string,
    useWhiteTheme?: boolean,
    isCareersForm?: boolean,
    canOnlyClose?: boolean,
    list?: { id: string, title: string, minValueLen: number, isRequired?: boolean, dropdown?: {id: string, title: string}[] }[]
  };
  
  get canOnlyClose(): boolean {
    return this.data?.canOnlyClose ?? false;
  }

  contactForm?: FormGroup;
  contactFormFields: {id: string, label?: string, col: number, dropdown?: {id: string, title: string}[] }[] = [];

  constructor(
    public readonly dialogRef: MatDialogRef<DialogContactComponent>,
    private readonly cookieService: CookieService,
    private readonly GA: GoogleAnalyticsService,
  ) {
    this.data = inject(MAT_DIALOG_DATA);
  }

  ngOnInit(): void {
    adjustBackPosition();
    // disableScroll();

    this.setFormData();

    // this.dialogRef.updateSize('700px', undefined);
  }

  setFieldValue(fieldID: string, value: string): void {
    this.contactForm?.get(fieldID)?.setValue(value);
    if (fieldID === 'positionCategory' && value !== 'other') {
      this.contactForm?.get('positionCategory_other')?.removeValidators([Validators.required]);
      this.contactForm?.get('positionCategory_other')?.updateValueAndValidity();
    }
  }

  isFieldRequired(fieldID: string): boolean {
    return this.contactForm?.get(fieldID)?.hasValidator(Validators.required) ?? false;
  }

  isFieldInvalid(fieldID: string): boolean {
    return (
      (/* this.contactForm?.get(fieldID)?.dirty || this.contactForm?.get(fieldID)?.touched || */ this.submitPressed) && this.contactForm?.get(fieldID)?.errors && this.contactForm?.get(fieldID)?.hasError('required')
    ) || this.contactForm?.get(fieldID)?.hasError('email') || false;
  }

  getColumnOrientation(index: number): 'left-side' | 'right-side' {
    const previousFields = this.contactFormFields.slice(0, index);
    if (index > 0 && previousFields.filter(field => field.col === 12).length % 2 !== 0) {
      return index % 2 === 0 ? 'right-side' : 'left-side';
    }

    return index % 2 !== 0 ? 'right-side' : 'left-side';
  }

  submitPressed: boolean = false;
  onYesClick(): void {
    this.submitPressed = true;
    if (this.contactForm?.valid) {
      // this.enableScroll();

      if (this.cookieService.canTrackWithGA()) {
        const qs = new URLSearchParams(window.location.search);
        // const url = window.location.href.split('?')[0];
        const queryParams = {
          campaign: qs.get('campaign') ?? undefined,
          leadSource: qs.get('trafficSrc') ?? undefined,
          // url: url,
        };
        // this.GA.trackEvent('Contact', 'Submit', url, queryParams);
        this.GA.trackEventSelectItem(queryParams);
      }

      this.dialogRef.close(this.contactForm.value);
    }
  }

  onNoClick(): void {
    // this.enableScroll();
    this.dialogRef.close();
  }


  private setFormData() {
    if (!this.data?.list) {
      this.contactFormFields = [{id: 'firstName', col: 6}, {id: 'lastName', col: 6}, {id: 'company', col: 6}, {id: 'country', col: 6}, {id: 'email', col: 12}, {id: 'interest', col: 12}];

      this.contactForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        company: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        interest: new FormControl('', [Validators.required]),
        message: new FormControl(''),
        privacyPolicyCheck: new FormControl(false, [Validators.requiredTrue]),
      });

      if (this.data?.isCareersForm) {
        this.contactFormFields.splice(this.contactFormFields.findIndex(field => field.id === 'company'), 1);
        this.contactFormFields.splice(this.contactFormFields.findIndex(field => field.id === 'country'), 1);

        this.contactForm.removeControl('company');
        this.contactForm.removeControl('country');
      }
    } else {
      this.contactForm = new FormGroup({
        privacyPolicyCheck: new FormControl(false, [Validators.requiredTrue]),
      });
      this.contactFormFields = [];

      this.data.list.forEach((field) => {
        this.contactFormFields.push({
          id: field.id,
          label: field.title,
          col: ["email", "e-mail"].includes(field.id) ? 12 : 6,

          dropdown: (field.dropdown && field.dropdown.length > 0) ? field.dropdown : undefined,
        });

        const validators = [];
        if (field.isRequired) {
          validators.push(Validators.required);
        }
        if (field.minValueLen) {
          validators.push(Validators.minLength(field.minValueLen));
        }
        this.contactForm?.addControl(field.id, new FormControl('', validators.length > 0 ? validators : undefined));
      });
    }
  }
}
