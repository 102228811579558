import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export type alphabetLetter = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z';
export const alphabetLetters: alphabetLetter[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

@Component({
  selector: 'app-alphabet-selector',
  templateUrl: './alphabet-selector.component.html',
  styleUrl: './alphabet-selector.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class AlphabetSelectorComponent {
  
  @Input() enabledLetters: alphabetLetter[] = [];
  @Output() letterSelectedEvent = new EventEmitter<alphabetLetter | undefined>();

  readonly alphabetLetters = alphabetLetters;

  selectedLetter?: alphabetLetter = undefined;


  selectLetter(letter: alphabetLetter) {
    if (!this.enabledLetters.includes(letter)) return;

    if (this.selectedLetter == letter) this.selectedLetter = undefined;
    else this.selectedLetter = letter;
    this.letterSelectedEvent.emit(this.selectedLetter);
  }
}
