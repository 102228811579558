@if (data) {
    <div class="dialog-person-bio">
        <div class="person">
            <div class="avatar-and-bio">
                @if (data.bio.avatar) {
                    <div class="avatar-wrap">
                        @if (data.bio.avatar) {
                            <img [src]="data.bio.avatar" />
                        }
                    </div>
                }
    
                <div class="text-column">
                    <p class="name-and-job-position">
                        <span class="name">{{data.bio.name}}</span>
                        @if (data.bio.position) {
                            <span class="job-position">, {{data.bio.position}}</span>
                        }
                    </p>
                    @if (data.bio.body?.startsWith('<p>')) {
                        <div [ngClass]="{'bio': true, 'no-avatar': !data.bio.avatar}" [innerHTML]="data.bio.body"></div>   
                    }
                    @else {
                        <div [ngClass]="{'bio': true, 'no-avatar': !data.bio.avatar}"><p [innerHTML]="data.bio.body"></p></div>
                    }
                </div>

                <div class="close">
                    <mat-icon class="interactive" (click)="dialogRef.close()">close</mat-icon>
                </div>
            </div>
        </div>
    </div>
}
