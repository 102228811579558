import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";

@Component({
  selector: 'app-dialog-contact',
  templateUrl: './dialog-contact.component.html',
  styleUrl: './dialog-contact.component.scss',
  standalone: true,
  imports: [CommonModule, MatDialogModule, TranslateModule, FormsModule, ReactiveFormsModule, MatInputModule, MatCheckboxModule, PromAnimatedButtonComponent],
})
export class DialogContactComponent implements OnInit {

  data?: any;
  
  get canOnlyClose(): boolean {
    return this.data?.canOnlyClose ?? false;
  }

  contactForm?: FormGroup;
  contactFormFields: {id: string, label?: string, col: number}[] = [];

  constructor(
    public readonly dialogRef: MatDialogRef<DialogContactComponent>
  ) {
    this.data = inject(MAT_DIALOG_DATA);
  }

  ngOnInit(): void {
    if (!this.data.list) {
      this.contactFormFields = [{id: 'firstName', col: 6}, {id: 'lastName', col: 6}, {id: 'company', col: 6}, {id: 'country', col: 6}, {id: 'email', col: 12}, {id: 'interest', col: 12}];

      this.contactForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        company: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        interest: new FormControl('', [Validators.required]),
        message: new FormControl(''),
        privacyPolicyCheck: new FormControl(false, [Validators.requiredTrue]),
      });
    } else {
      this.contactForm = new FormGroup({
        privacyPolicyCheck: new FormControl(false, [Validators.requiredTrue]),
      });
      this.contactFormFields = [];

      this.data.list.forEach((field: {id: string, title: string, minValueLen: number}) => {
        if (!["positionCategory_other"].includes(field.id)) {
          this.contactFormFields.push({
            id: field.id,
            label: field.title,
            col: ["email", "e-mail"].includes(field.id) ? 12 : 6,
          });
  
          this.contactForm?.addControl(field.id, new FormControl('', field.minValueLen ? [Validators.required, Validators.minLength(field.minValueLen)] : undefined));
        }
      });
    }

    // this.dialogRef.updateSize('700px', undefined);
  }

  isFieldRequired(fieldID: string): boolean {
    return this.contactForm?.get(fieldID)?.hasValidator(Validators.required) ?? false;
  }

  isFieldInvalid(fieldID: string): boolean {
    return (
      (/* this.contactForm?.get(fieldID)?.dirty || this.contactForm?.get(fieldID)?.touched || */ this.submitPressed) && this.contactForm?.get(fieldID)?.errors && this.contactForm?.get(fieldID)?.hasError('required')
    ) || this.contactForm?.get(fieldID)?.hasError('email') || false;
  }

  submitPressed: boolean = false;
  onYesClick(): void {
    this.submitPressed = true;
    if (this.contactForm?.valid) {
      this.dialogRef.close(this.contactForm.value);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
