<div class="long-arrow-down animation-up-and-down">
    @switch (theme) {
        @case ('fluo-yellow') {
            <img src="/assets/icons/long_arrow_down__yellow.svg" alt="">
        }
        @default {
            <img src="/assets/icons/long_arrow_down.svg" alt="">
        }
    }
</div>
