import { Component, Input } from '@angular/core';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ContactModalComponent } from './contact-modal.component';
import { ApiService } from '../../services/api.service';
import { HeaderDataService } from '../../services/header-data.service';

@Component({
  selector: 'app-section-contact-us',
  templateUrl: './section-contact-us.component.html',
  styleUrl: './section-contact-us.component.scss',
  standalone: true,
  imports: [CommonModule, PromAnimatedButtonComponent, TranslateModule],
})
export class SectionContactUsComponent extends ContactModalComponent {
  @Input() text?: string = `Need some help?<br>Get in touch with our team!`;
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';
  @Input() email?: string;
  link: string = `/contact-us/form`;

  constructor(
    protected override readonly dialog: MatDialog,
    protected override readonly translate: TranslateService,
    protected override readonly api: ApiService,
    protected override readonly headerDataService: HeaderDataService,
  ) {
    super(dialog, translate, api, headerDataService);
  }

  protected override async openContactDialog() {
    super.openContactDialog(this.theme, this.email);
  }
}
