@if (!loading && pageData) {
    <div class="page about-us--social-responsibility">
        <!-- <div class="intro-background"></div> -->
        <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div>
    
        <div class="page-content">
            <section class="intro">
                <div class="section-content" data-aos="fade-up">
                    <div class="category"><h2 [innerHTML]="pageData.title"></h2></div>
                    <div class="title"><h1>We are expertise providers</h1></div>
                    <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
                </div>

                <app-long-arrow-down></app-long-arrow-down>
            </section>

            @if (pageData.mediaSection) {
                <section class="media">
                    <app-media-section [pageRoot]="pageRoot" [mediaSection]="pageData.mediaSection" data-aos="fade-up"></app-media-section>
                </section>
            }

            @if (pageData.peopleSection) {
                <section class="prometeia-association">
                    <div class="section-content">
                        <div class="row">
                            <div class="col">
                                <h3>{{pageData.peopleSection.title}}</h3>
                                @if (pageData.peopleSection.body.startsWith('<p>')) {
                                    <div class="body" data-aos="fade-up" [innerHTML]="pageData.peopleSection.body"></div>
                                }
                                @else {
                                    <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.peopleSection.body"></p></div>
                                }
                            </div>
                            <div class="col flex-vertical-centered">
                                <div class="image-wrap">
                                    <img src="{{pageData.peopleSection.imageUrl}}" alt="prometeia_association" data-aos="fade-up">
                                </div>
                            </div>
                        </div>
                        
                        @if (pageData.peopleSection.items.length) {
                            <div class="accordion-container" data-aos="fade-up">
                                <app-people-accordion [list]="pageData.peopleSection.items" [bgColor]="'deep-blue'"></app-people-accordion>
                            </div>
                        }
                    </div>
                </section>
            }

            @if (pageData.recognitions) {
                <section class="recognitions">
                    <app-media-list-section [pageRoot]="pageRoot" [mediaSection]="pageData.recognitions" [currentLang]="currentLang"></app-media-list-section>
                </section>
            }

            @if (pageData.ourTeam) {
                <section class="our-team">
                    <div class="section-content flex-row">
                        <h3>{{pageData.ourTeam.title}}</h3>
        
                        <div class="button-container flex-vertical-centered">
                            <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" [routerLink]="[pageData.ourTeam.link.route]">
                                {{pageData.ourTeam.link.label | uppercase}}
                            </app-prom-animated-button>
                        </div>
                    </div>
                </section>
            }

            @if (pageData.offices) {
                <section class="offices">
                    <div class="section-content">
                        <h3>Locations & Contacts</h3>
                        
                        @if (pageData.offices.items.length) {
                            <div class="offices-list" data-aos="fade-up">
                                @for (item of pageData.offices.items; track $index) {
                                    <div class="office">
                                        <app-image-card [card]="item" [borderColor]="'electric-blue'"></app-image-card>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </section>
            }

            <app-section-contact-us [text]="'Any question?<br>Get in touch with our team!'"></app-section-contact-us>
        </div>
    </div>
}
