import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { scrollIdIntoView } from '../../services/utilities.service';
import { SelectorShipService } from '../../services/selector-ship.service';
import { SCREEN_SIZE_MOBILE_VERTICAL } from '../../models/constants';

export interface IShipSelectorOption {
  name: string;
  selected?: boolean;
}

export interface IShipSelector {
  id?: string;
  name: string;
  options: IShipSelectorOption[];
}

export interface IShipSelectorSelection {
  name: string;
  selected: string[];
}

@Component({
  selector: 'app-selector-ship',
  templateUrl: './selector-ship.component.html',
  styleUrl: './selector-ship.component.scss',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatInputModule, TranslateModule],
})
export class SelectorShipComponent implements OnInit {
  @Input() selectors: IShipSelector[] = [];
  @Input() theme: 'default' | 'careers' = 'default';

  @Output() selectionChangedEvent = new EventEmitter<IShipSelectorSelection[]>();
  @Output() stringSearchEvent = new EventEmitter<string>();
  @Output() clearSearchEvent = new EventEmitter<void>();

  activeTab: number = -1;
  readonly searchIconTabIndex: number = 999;

  isClosing: boolean = false;

  get cacheID(): string {
    return this.theme == "default" ? "insights" : this.theme;
  }

  constructor (
    private selectorShipService: SelectorShipService,
  ) {}

  ngOnInit(): void {
    this.preSelectFromCache();
  }

  private preSelectFromCache() {
    const cachedSelectors = this.selectorShipService.loadCache(this.cacheID);
    if (!cachedSelectors) return;

    this.selectors.forEach(selector => {
      const cachedSelector = cachedSelectors.find(cs => cs.name == selector.name);

      // console.log(cachedSelector, selector);

      selector.options.forEach(option => {
        const cachedOption = cachedSelector?.options.find(co => co.name == option.name);
        
        // if (cachedOption?.selected) {
        //   console.log(cachedOption, option);
        // }

        option.selected = cachedOption?.selected;
      });
    });

    // console.log(cachedSelectors, this.selectors);
    this.selectionChangedEvent.emit(this.selectors.map((selector: IShipSelector) => ({name: selector.name, selected: selector.options.filter((option) => option.selected).map((option) => option.name)})));
  }

  onTabClick(index: number) {
    if (this.activeTab === index) {
      this.activeTab = -1;
      this.isClosing = true;
      setTimeout(() => {
        this.isClosing = false;
      }, 300);
      return;
    }
    this.activeTab = index;

    if (index === this.searchIconTabIndex) {
      // this.searchBoxInput.setValue('');
    }

    if (window.innerWidth < SCREEN_SIZE_MOBILE_VERTICAL) {
      setTimeout(() => {
        scrollIdIntoView(`names-item-${index}`, -100, { behavior: 'smooth' });
      }, 10);
    }
  }

  onItemClick(item: IShipSelectorOption) {
    item.selected = !item.selected;
    // console.log(this.selectors);

    this.selectorShipService.updateCache(this.cacheID, this.selectors);

    this.selectionChangedEvent.emit(this.selectors.map((selector: IShipSelector) => ({name: selector.name, selected: selector.options.filter((option) => option.selected).map((option) => option.name)})));
  }

  getSelectedOptionsNumberBySeletorName(selectorName: string): number {
    return this.selectors?.find((selector) => selector.name === selectorName)?.options.filter((option) => option.selected).length ?? 0;
  }

  getOptionsBySeletorName(): IShipSelectorOption[] {
    return this.selectors?.[this.activeTab]?.options ?? [];
  }


  /** SEARCH */

  searchBoxInput = new FormControl('', [Validators.required, Validators.minLength(3)]);
  lastSearch: string = '';

  submitSearch() {
    if (!this.searchBoxInput.valid || !this.searchBoxInput.value) {
      if (this.lastSearch?.length > 0 && (this.searchBoxInput.value == '' || this.searchBoxInput.value == null || this.searchBoxInput.value == undefined)) {
        this.clearSearchEvent.emit();
      } else {
        console.error('Invalid search input');
      }
      return;
    }

    this.onTabClick(this.searchIconTabIndex);
    this.stringSearchEvent.emit(this.searchBoxInput.value);
    this.lastSearch = this.searchBoxInput.value;
  }
}
