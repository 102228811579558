@if (card) {
    <div id="single-prom-card-{{cardIndex}}"
        class="p-card default-card-border size-{{size}} theme--{{theme}}"
        [ngClass]="{
            'forced-card-height': forcedCardHeight !== 'auto',
            'with-arrow': card.download || size === 'full',
            'with-external-link': card.link && card.link.url
        }"
        [ngStyle]="{'height': forcedCardHeight + 'px'}"
        data-aos="fade-up"
        (click)="goToCardURL()"
    >
        <div class="p-card--header">
            <h4 class="p-card--title text--{{titleColor}}">{{card.title}}</h4>
        </div>
        
        <div class="p-card--content">
            @if (card.body.startsWith('<p>')) {
                <div class="inner-html--p-card--body p-card--body" [innerHTML]="card.body"></div>
            }
            @else {
                <div class="inner-html--p-card--body p-card--body"><p [innerHTML]="card.body"></p></div>
            }
        </div>

        @if (card.download || size === 'full') {
            <div class="p-card--footer">
                @if (card.download) {
                    <div class="download-icon download-icon-{{arrowColor}}">
                        <div class="arrow-wrap">
                            <a [href]="card.download.url" target="_blank" [download]="card.download.label">
                                <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="download_arrow">
                            </a>
                        </div>
                    </div>
                }

                @if (card.link && card.link.url) {
                    <div class="external-link-icon external-link-icon-{{arrowColor}}">
                        <div class="arrow-wrap">
                            <a [href]="card.link.url" target="_blank">
                                <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="external_link_arrow">
                            </a>
                        </div>
                    </div>
                }
                @else if (card.link && card.link.route) {
                    <div class="internal-route-icon internal-route-icon-{{arrowColor}}">
                        <div class="arrow-wrap">
                            <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="internal_route_arrow">
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}
