import { Component, Input } from '@angular/core';
import { PromBordedButtonComponent } from "../prom-borded-button/prom-borded-button.component";
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-section-contact-us',
  templateUrl: './section-contact-us.component.html',
  styleUrl: './section-contact-us.component.scss',
  standalone: true,
  imports: [CommonModule, PromBordedButtonComponent, PromAnimatedButtonComponent, TranslateModule],
})
export class SectionContactUsComponent {
  @Input() text?: string = `Need some help?<br>Get in touch with our team!`;
  @Input() theme: 'default' | 'yellow' = 'default';
  link: string = `/contact-us/form`;
}
