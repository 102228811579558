import { Component, Input, AfterViewInit, HostListener } from '@angular/core';
import { ISitePageFlippingCard } from '../../models/common';
import { CommonModule } from '@angular/common';
import { SCREEN_SIZE_MOBILE_VERTICAL } from '../../models/constants';
import { sleep } from '../../services/utilities.service';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrl: './image-card.component.scss',
  standalone: true,
  imports: [CommonModule],
})
export class ImageCardComponent implements AfterViewInit {
  @Input() card?: ISitePageFlippingCard;
  @Input() borderColor: 'electric-blue' | 'red' = 'red';
  @Input() imageSize: 'default' | 'small' = 'default';
  @Input() cardHeight: 'default' | 'tall' = 'default';
  @Input() useMarginInBody: boolean = false;

  calculatedHeight: number = 0;

  ngAfterViewInit() {
    if (this.cardHeight !== 'tall') return;

    this.setCardsHeight();
  }

  @HostListener('window:resize', ['$event'])
  private async setCardsHeight() {
    await sleep(1);
    
    const screenWidth = window.screen.width;
    if (screenWidth <= SCREEN_SIZE_MOBILE_VERTICAL) {
      this.calculatedHeight = 0;
      return;
    }

    const cards = Array.from(document.getElementsByClassName(`image-card`));
    const tallestCard = cards.sort((a, b) => b.clientHeight - a.clientHeight)[0];
    if (cards && tallestCard?.clientHeight) {
      // console.log('tallestCardHeight', tallestCard);
      this.calculatedHeight = tallestCard.clientHeight;
    }
  }
}
