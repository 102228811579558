import pkg from '../../package.json';

export const environment = {
    production: true,
    appVersion: pkg.version + '-s',
    api: {
        baseEndpoint: '/api/web',
        appEndpoint: 'https://wsapp.prometeia.com/corporate',
    },
    aws: {
        ar: {
            CONFIG_COGNITO_ALUMNI: require('../aws-config/prod/aws-export-alumni.json'),
            CONFIG_COGNITO_AR : require('../aws-config/prod/aws-export-AR.json'),
        }
    }
};
