import { Component, Input } from '@angular/core';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogContactComponent } from '../dialog-contact/dialog-contact.component';
import { DialogNoticeComponent } from '../dialog-notice/dialog-notice.component';

@Component({
  selector: 'app-section-contact-us',
  templateUrl: './section-contact-us.component.html',
  styleUrl: './section-contact-us.component.scss',
  standalone: true,
  imports: [CommonModule, PromAnimatedButtonComponent, TranslateModule],
})
export class SectionContactUsComponent {
  @Input() text?: string = `Need some help?<br>Get in touch with our team!`;
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';
  link: string = `/contact-us/form`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  protected async openContactDialog() {
    const dialogRef = this.dialog.open(DialogContactComponent, {
      id: 'modal-dialog-contact',

      minWidth: '750px',
      // maxWidth: '60%',
      
      height: 'auto',
      minHeight: '80%',
      maxHeight: '100%',
      
      data: {privacyPolicyLink: `/${this.translate.currentLang}/article/privacy-policy`, useWhiteTheme: this.theme === 'red'},
    });
    dialogRef.afterClosed().subscribe( async (res) => {
      console.log(res);

      if (res) {
        this.dialog.open(DialogNoticeComponent, {
          id: 'modal-dialog-notice',
          
          data: {
            category: this.translate.instant('FORMS.THANKS_FOR_CONTACT'),
            title: this.translate.instant('FORMS.THANKS_NOTICE'),
          },
        });
      }
    });
  }
}
