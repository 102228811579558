@if (mediaSection) {
    <div class="media-section in-{{pageRoot}}">
        <div class="items row">
            @for (item of mediaSection.items; track $index) {
                <div [ngClass]="{'col-12': item.size === 'full', 'col-6': item.size === 'half', 'col-4': item.size === 'third', 'theme--deep-blue': ['text'].includes(item.type) && ($index + 1 < mediaSection.items.length) && ['text'].includes(mediaSection.items[$index + 1].type) }">
                    @switch (item.type) {
                        @case ('text') {
                            <div id="media-section-item-{{$index}}" class="media-text" data-aos="fade-up">
                                <h3 [innerHTML]="item.title"></h3>
        
                                @if (item.download) {
                                    <div class="flex-vertical-centered">
                                        <a class="prom-borded-button-wrapper" [href]="item.download.url" target="_blank" download style="font-size: 1em;">
                                            <app-prom-animated-button [arrow]="'down'">
                                                {{item.download.label | uppercase}}
                                            </app-prom-animated-button>
                                        </a>
                                    </div>
                                }

                                @if (item.link?.route) {
                                    <div class="flex-vertical-centered">
                                        <app-prom-animated-button class="prom-borded-button-wrapper" [arrow]="'right'" [routerLink]="['/' + item.link?.route]">
                                            {{item.link!.label| uppercase}}
                                        </app-prom-animated-button>
                                    </div>
                                }
                            </div>
                        }
                        @case ('image') {
                            <div id="media-section-item-{{$index}}" class="media-image">
                                <img src="{{item.url}}" alt="media_image">
                            </div>
                        }
                        @case ('video') {
                            <app-video-embedder [source]="item" [isOpenEvt]="openVideoSignal"></app-video-embedder>
                            <div id="media-section-item-{{$index}}" [ngClass]="{'media-video': true, 'with-cover-image': item.cover}" (click)="openVideo(item)">
                                @if (item.cover) {
                                    <div class="media-colored-filter"></div>
                                    <div class="play-btn-wrapper">
                                        <img class="play-btn" [src]="playSymbol" alt="play_button">
                                    </div>
                                }

                                @if (item.cover) {
                                    <div class="cover-wrap">
                                        <div class="media-cover-image" style="background: url('{{item.cover}}'); background-size: cover; background-position: center;"></div>
                                    </div>
                                }
                                @else if (item.url && item.url.includes('.com')) {
                                    <iframe width="{{videoWidth($index)}}" height="{{videoHeight($index)}}" [src]="item.url | safe: 'resourceUrl'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                }
                                @else if (item.url?.endsWith('.mp4')) {
                                    <video width="{{videoWidth($index)}}" height="{{videoHeight($index)}}" controls [src]="item.url"></video>
                                }
                            </div>
                        }
                        @case ('text+image') {
                            <div id="media-section-item-{{$index}}" class="media-text-image" data-aos="fade-up">
                                <h3>{{item.title}}</h3>
        
                                <div class="body" data-aos="fade-up"><p [innerHTML]="item.body"></p></div>

                                <div class="image-wrap">
                                    <img src="{{item.url}}" alt="media_image">
                                </div>
                            </div>
                        }
                        @case ('text+link') {
                            <div id="media-section-item-{{$index}}" class="media-text-link" data-aos="fade-up">
                                <h3>{{item.title}}</h3>
        
                                <div class="body" data-aos="fade-up"><p [innerHTML]="item.body"></p></div>

                                @if (item.link?.url) {
                                    <div class="flex-vertical-centered">
                                        <app-prom-animated-button class="prom-borded-button-wrapper" [arrow]="'top-right'" [theme]="getButtonTheme(item)">
                                            {{item.link!.label | uppercase}}
                                        </app-prom-animated-button>
                                    </div>
                                }
                                @else if (item.link?.route) {
                                    <div class="flex-vertical-centered">
                                        <app-prom-animated-button class="prom-borded-button-wrapper" [arrow]="'right'" [theme]="getButtonTheme(item)" [routerLink]="item.link!.route">
                                            {{item.link!.label | uppercase}}
                                        </app-prom-animated-button>
                                    </div>
                                }
                            </div>
                        }
                        @default { }
                    }
                </div>
            }
        </div>
    </div>
}


<!-- 
<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/7XXu_-eoxHo?si=BN332jXxJp2N3mo6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
 -->