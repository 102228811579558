import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../services/cookie.service';
import { scrollToTop } from '../../services/utilities.service';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CookieDetailComponent } from "./cookie-detail/cookie-detail.component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

export interface ICookiesListItem {
  /** stored cookie name */
  id: string,
  company: string,
  domain: string,
  accepted: boolean,
  description: string,
  links: { label: string, url: string }[],
}

@Component({
  selector: 'app-privacy-and-cookies',
  templateUrl: './privacy-and-cookies.component.html',
  styleUrls: ['./privacy-and-cookies.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatIconModule, MatSlideToggleModule, FormsModule, CookieDetailComponent],
})
export class PrivacyAndCookiesComponent implements OnInit {

  logo_scuro: string = "/assets/images/logo.png";

  isBannerVisible: boolean = true;
  isDetailVisible: boolean = false;

  get isCookiesPage(): boolean {
    const [test, locale, root, page] = this.router.url.split("/");
    if (!test.length && root == "article" && ["cookies", "cookie"].includes(page)) {
      return true;
    }
    return false;
  }

  bannerOpenStateClass: string = 'closed';

  cookieDetailOpenState: boolean[] = [false, false, false];
  /**
   * each item can be ->
   *  0: not setted |
   *  1: accepted |
   * -1: NOT accepted
   */
  // cookiePreference: number[] = [1, 0, 0];

  /**
   * each item can be ->
   *  true: accepted |
   * false: NOT accepted
   */
  cookiePreferenceV2: boolean[] = [true, false, false];

  get functionalCookies(): ICookiesListItem[] {
    return [
      {
        id: "COOKIE_MAIN",
        company: "Prometeia",
        domain: "prometeia.it, prometeia.com",
        accepted: true,
        description: this.translate.instant('COOKIES.PROMETEIA_DESCRIPTION'),
        links: [ { label: "Cookie Policy", url: `/${this.translate.currentLang}/article/cookies` } ]
      }
    ]
  };

  performanceCookies: ICookiesListItem[] = [
    {
      id: "COOKIE_AN_ST__GOOGLE",
      company: "Google Inc.",
      domain: "ajax.googleapis.com, google.com, www.google-analytics.com, www.google.com, www.google.ie, www.googletagmanager.com, www.gstatic.com",
      accepted: false,
      description: "Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.",
      links: [
        {url: "https://policies.google.com/terms", label: "Terms and Conditions"},
        {url: "https://policies.google.com/privacy", label: "Privacy Policy"},
      ]
    },
    {
      id: "COOKIE_AN_ST__TABLEAU",
      company: "Tableau Software",
      domain: "public.tableau.com, tableau.com",
      accepted: false,
      description: "Tableau Software provides easy-to-use software applications for fast analytics and visualization.",
      links: [
        {url: "https://www.tableau.com/privacy", label: "Privacy Policy"},
      ]
    },
    // {
    //   id: "COOKIE_AN_ST__JQ",
    //   company: "The jQuery Foundation",
    //   domain: "code.jquery.com",
    //   accepted: false,
    //   description: "The jQuery Foundation is a non-profit trade association dedicated to supporting development of the jQuery Core, jQuery UI, and jQuery Mobile projects; providing jQuery documentation and support; and fostering the jQuery community.",
    //   links: [
    //     {url: "https://www.linuxfoundation.org/cookies", label: "Cookie Policy"},
    //   ]
    // },
    {
      id: "COOKIE_AN_ST__HUBSPOT",
      company: "HubSpot",
      domain: "hubspot.com, app.hubspot.com",
      accepted: false,
      description: "HubSpot is an American developer and marketer of software products for inbound marketing, sales, and customer service.",
      links: [
        {url: "https://www.hubspot.com/privacy", label: "Privacy Policy"},
      ]
    }
  ]

  advertisingCookies: ICookiesListItem[] = [
    {
      id: "COOKIE_MRKT__G_ADS",
      company: "Google Ads",
      domain: "doubleclick.net, googleads.g.doubleclick.net, static.doubleclick.net, stats.g.doubleclick.net",
      accepted: false,
      description: "Google Ads is an online advertising platform developed by Google, where advertisers pay to display brief advertisements, service offerings, product listings, video content and generate mobile application installs within the Google ad network to web users.",
      links: [
        {url: "https://policies.google.com/terms", label: "Terms and Conditions"},
        {url: "https://policies.google.com/privacy", label: "Privacy Policy"},
      ]
    },
    {
      id: "COOKIE_MRKT__G_YT",
      company: "Google Inc.",
      domain: "s.ytimg.com, www.youtube.com, youtube.com",
      accepted: false,
      description: "Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.",
      links: [
        {url: "https://policies.google.com/terms", label: "Terms and Conditions"},
        {url: "https://policies.google.com/privacy", label: "Privacy Policy"},
      ]
    }
  ]

  constructor(
    public readonly translate: TranslateService,
    public readonly cookieService: CookieService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    const cookieSettingsLoaded = this.cookieService.isCookiesStored();
    // console.log("cookieSettingsLoaded", cookieSettingsLoaded);
    this.isBannerVisible = !cookieSettingsLoaded;
    this.isDetailVisible = false;
    
    setTimeout(() => {
      this.bannerOpenStateClass = 'open';
    }, 10);
  }

  accept(necessaryOnly: boolean = false) {
    this.isBannerVisible = false;
    if (necessaryOnly) {
      this.cookieService.setMainCookie();
    } else {
      this.setCookies({all: true});
    }
  }

  preferences() {
    scrollToTop();

    this.bannerOpenStateClass = 'closed';
    setTimeout(() => {
      this.isDetailVisible = true;
    }, 1);
  }

  send() {
    this.isBannerVisible = false;
    this.closeDetail();
    this.cookieService.removeCookiePreferences();
    this.performanceCookies.forEach(c => this.cookieService.removeCookieByID(c.id));
    this.advertisingCookies.forEach(c => this.cookieService.removeCookieByID(c.id));
    this.setCookies();
  }

  private setCookies(options?: {all: boolean}) {
    this.cookieService.setMainCookie();
    // console.log("this.cookiePreference 1", this.cookiePreference);
    if (/* !this.allCookiesAreSetted() */ !!options?.all) {
      // this.cookiePreference = [1, 1, 1];
      this.cookiePreferenceV2 = [true, true, true];
    }
    // console.log("this.cookiePreference 2", this.cookiePreference);
    this.cookieService.setCookiePreferencesV2(this.cookiePreferenceV2);

    this.performanceCookies.forEach(c => {
      if (c.accepted || this.cookieService.isAnalyticsEnabled()) {
        this.cookieService.setCookieByID(c.id);
      }
    });
    this.advertisingCookies.forEach(c => {
      if (c.accepted || this.cookieService.isMarketingEnabled()) {
        this.cookieService.setCookieByID(c.id);
      }
    });

    setTimeout(() => {
      window.location.reload();
    }, 10);
  }


  toggleCookieDetailOpenState(index: number): void {
    this.cookieDetailOpenState[index] = !this.cookieDetailOpenState[index];
    // this.bannerOpenStateClass = 'close';
  }

  isCookieDetailOpen(index: number): boolean {
    return this.cookieDetailOpenState[index];
  }

  private setCookiePreference(index: number, value: boolean): void {
    switch (index) {
      case 1:
        this.performanceCookies.forEach(c => c.accepted = value);
        break;
      case 2:
        this.advertisingCookies.forEach(c => c.accepted = value);
        break;
    }
  }

  public updateSubCookiePreference(index: number) {
    this.setCookiePreference(index, this.cookiePreferenceV2[index]);
  }

  public updateParentCookiePreference(index: number, value: boolean) {
    switch (index) {
      case 1:
        this.cookiePreferenceV2[index] = this.performanceCookies.every(c => !!c.accepted);
        break;
      case 2:
        this.cookiePreferenceV2[index] = this.advertisingCookies.every(c => !!c.accepted);
        break;
    }
  }

  isCookieAccepted(index: number): boolean {
    return this.cookiePreferenceV2[index] ?? false;
  }

  // allCookiesAreSetted(): boolean {
  //   let res = 1;

  //   this.cookiePreference.forEach(item => {
  //     res = res * item;
  //   });

  //   if (res == 0) return false;
  //   return true;
  // }

  // allCookiesAreSetted(): boolean {
  //   return this.cookiePreferenceV2.every(item => !!item);
  // }


  closeDetail() {
    this.bannerOpenStateClass = 'open';
    this.isDetailVisible = false;
    this.cookieService.forceReopenCookieModal = false;
  }

}
