@if (papers?.length) {
    <div class="papers">
        <hr>
        @for (paper of papers; let paperIndex = $index; track paperIndex;) {
            @if (paperIndex < lastIndexToView) {
                <div class="paper">
                    <p class="paper--authors font__inter_regular">{{'AUTHORS' | translate}}: {{paper.authors}}</p>
                    @if (paper.url) {
                        <a class="paper--title link font__inter_bold" [href]="paper.url" target="_blank">
                            {{paper.title}}
                        </a>
                    }
                    @else {
                        <p class="paper--title font__inter_bold">{{paper.title}}</p>
                    }
                    <div class="flex-row">
                        <p class="paper--date font__inter_bold">{{paper.pubDate}}</p>
                        <p class="paper--subtitle">{{paper.subtitle}}</p>
                    </div>
                </div>
                <hr>
            }
        }
        @if (canLoadMore) {
            <div class="load-more">
                <app-prom-animated-button [theme]="'deep-blue'" (click)="onLoadMoreClick()">
                    {{'GENERAL.LOAD_MORE' | translate | uppercase}}
                </app-prom-animated-button>
            </div>
        }
    </div>
}
