{
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_AniD4miv5",
    "aws_user_pools_web_client_id": "3bt4t65nmseke0tm2f6pma9419",
    "oauth": {
        "domain": "prometeia-ar.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.prometeia.it/authenticated",
        "redirectSignOut": "https://www.prometeia.it/disconnected",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}