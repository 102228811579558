import { Injectable } from '@angular/core';
import { AxiosInstance } from 'axios';
import { HttpClient } from '@angular/common/http';
import { RestClientService } from './rest-client.service';
import { ISitePage, ISitePageNews } from '../models/pages';
import { IHeaderRow } from '../models/header';
import { environment } from '../../environments/environment';
import { IJobFilters, IJobOffer } from '../models/careers';
import { IAboutUsPageInsightsArticle } from '../models/about-us';
import { TranslateService } from '@ngx-translate/core';
import { IAnteoArticle, IAnteoIssueNumber, ISimAnteoArchive } from '../models/sim';

export interface ILoginRequest { username: string, password: string }

export interface ILoginResponse {
  access_token: string;
  refresh_token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private restClient?: AxiosInstance;
  private appRestClient?: AxiosInstance;

  private initTime?: Date;

  get isRestClientReady(): boolean {
    return this.restClient != undefined;
  }

  get isAppRestClientReady(): boolean {
    return this.appRestClient != undefined;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly restClientService: RestClientService,
    private readonly translate: TranslateService,
  ) {
    this.init();

    this.translate.onLangChange.subscribe(() => {
      this.reInit();
    });
  }

  public async init() {
    await this.restClientService.defaultInit();
    this.restClient = this.restClientService.restClient;
    // if (this.isRestClientReady) console.log("Rest client initialized for API service");
    this.initTime = new Date();


    await this.restClientService.init({ baseUrl: environment.api.appEndpoint });
    this.appRestClient = this.restClientService.restClient;
    // if (this.isAppRestClientReady) console.log("Rest client initialized for app API service");
  }

  public async reInit() {
    const now = new Date();
    if (!this.initTime || now.getTime() - this.initTime.getTime() < 100) return;

    this.restClient = undefined;
    await this.restClientService.init({ baseUrl: environment.api.baseEndpoint });
    this.restClient = this.restClientService.restClient;
    // if (this.isRestClientReady) console.log("Rest client re-initialized for API service");
  }

  public header = {
    get: () => this.restClient?.get<IHeaderRow[]>('/menu') as unknown as Promise<IHeaderRow[]>,
  }

  public pages = {
    home: () => this.restClient?.get<ISitePage>('/v2/page/home') as unknown as Promise<ISitePage>,

    get: (body: {root: string, slug: string}) => this.restClient?.get<ISitePage>(`/v2/page/${body.root}/${body.slug}`) as any,
    getBySlug: (slug: string) => this.restClient?.get<ISitePage>(`/v2/page/${slug}`) as any,
    jobOffers: () => this.restClient?.get<any>(`/page/job-offers`) as unknown as Promise<{ filters?: { id: string, items: {label: string}[] }[] }>,

    insightsTopics: () => this.restClient?.get<string[]>(`/news/topics`) as unknown as Promise<string[]>,

    insights: (options: {skip?: number, limit?: number, q?: string, topics?: string, categories?: string, types?: string}) => this.restClient?.get<ISitePageNews>(`/news`, { params: options }) as unknown as Promise<ISitePageNews>,
    latestInsights: () => this.restClient?.get<ISitePageNews>(`/news`, { params: { skip: 0, limit: 4+1, categories: 'ideas;news;awards;events' } }) as unknown as Promise<ISitePageNews>,
    latestCaseStudiesInsights: () => this.restClient?.get<ISitePageNews>(`/news`, { params: { skip: 0, limit: 4+1, categories: 'case-studies' } }) as unknown as Promise<ISitePageNews>,
    getInsightsArticle: (slug: string) => this.restClient?.get<IAboutUsPageInsightsArticle>(`/news/article/${slug}`) as unknown as Promise<IAboutUsPageInsightsArticle>,
  }

  public simPages = {
    /**
      Home -> baseRoute "sim", slug "sim-home"

      Consulenza/Consultancy subpages -> baseRoute "sim-page", slug VARIABLE
      
      InFormation Paths article -> baseRoute "sim-event", slug VARIABLE
    */
    get: (body: {baseRoute: string, slug: string}) => this.restClient?.get<ISitePage>(`/v2/page-by-route/${body.baseRoute}/${body.slug}`) as any,

    /** InFormation Paths archive -> baseRoute "sim-event" */
    list: (body: {baseRoute: string}) => this.restClient?.get<any>(`/v2/page-by-route/${body.baseRoute}`) as unknown as Promise<any>,


    // Anteo baseRoute: "sim-anteo"

    /** Anteo archive */
    // TODO: use old API (appEndpoint /anteo/archive for issue 112 and newer ones + https://www.prometeia.3logic.it/api/web/pages?_category=anteo for older ones)
    anteoArchive: () => this.appRestClient?.get<ISimAnteoArchive>(`/anteo/archive`).then(res => ({items: res})) as unknown as Promise<ISimAnteoArchive>,

    /** Anteo issue */
    anteoIssue: (issueNumber: string) => this.appRestClient?.get<IAnteoIssueNumber>(`/anteo/${issueNumber}`) as unknown as Promise<IAnteoIssueNumber>,

    /** Anteo article */
    anteoArticle: (slug: string) => this.appRestClient?.get<IAnteoArticle>(`/anteo/article/${slug}`) as unknown as Promise<IAnteoArticle>,
  }

  public careers = {
    list: (filters?: {q?: string}) => this.appRestClient?.get<{filters: IJobFilters, offers: IJobOffer[]}>(`/career/job-offers`, { params: filters }) as unknown as Promise<{filters: IJobFilters, offers: IJobOffer[]}>,
    get: (jobID: number) => this.appRestClient?.get<IJobOffer[]>(`/career/job-offers/${jobID}`) as unknown as Promise<IJobOffer[]>,
  }

  public searchResults = {
    allSite: (query: string) => this.restClient?.get<any[]>(`/search`, { params: { q: query } }) as unknown as Promise<any[]>,

    trendingTopics: (query: string, from: number = 0, limit: number = 30) => this.appRestClient?.get<any[]>(`/news/trending-topics`, { params: { q: query, from: from, limit: limit } }) as unknown as Promise<any[]>,

    insights: (query: string, from: number = 0, limit: number = 30) => this.appRestClient?.get<any[]>(`/news/search`, { params: { q: query, from: from, limit: limit } }) as unknown as Promise<any[]>,
  }

  public newsletters = {
    get: (id: string) => this.restClient?.get<any>(`/page/newsletter-form-fields__${id}`) as unknown as Promise<any>,
    post: (slug: string, body: string) => this.restClient?.post(`/newsletters/subscribe/${slug}`, body),
  }
}
