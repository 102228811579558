<section class="section-contact-us theme--{{theme}}">
    <div class="content" data-aos="fade-up">
        <h3 [innerHTML]="text"></h3>
        
        <div class="flex-vertical-centered">
            <!-- <a class="borded-button" [href]="link" target="_blank">
                TALK TO US <span class="right-arrow-bg"><img src="/assets/icons/right_arrow__white.svg"/></span>
            </a> -->
            <!-- <app-prom-borded-button class="prom-borded-button-wrapper" [label]="'TALK TO US'" [url]="link" [arrow]="'right'"></app-prom-borded-button> -->
            <a class="prom-borded-button-wrapper" [href]="link" target="_blank" style="font-size: 1em;">
                <app-prom-animated-button [arrow]="'right'" [theme]="'deep-blue'">
                    {{'GENERAL.TALK_TO_US' | translate | uppercase}}
                </app-prom-animated-button>
            </a>
        </div>
    </div>
</section>
