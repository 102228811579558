import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { ISimAnteoArchive, ISimEventArchive } from '../../../models/sim';
import { CommonModule } from '@angular/common';
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { SectionContactUsSimComponent } from "../../../components/section-contact-us-sim/section-contact-us-sim.component";
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DotsLoaderComponent } from "../../../components/dots-loader/dots-loader.component";

const INITIAL_ANTEO_LIMIT: number = 10;
const ANTEO_LIMIT_INCREMENT: number = 10;

const INITIAL_EVENT_LIMIT: number = 5;
const EVENT_LIMIT_INCREMENT: number = 5;

const USE_ANTEO_LATEST_TEMPLATE: boolean = false;

@Component({
  selector: 'app-sim-archive',
  templateUrl: './sim-archive.component.html',
  styleUrl: './sim-archive.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule, PromAnimatedButtonComponent, SectionContactUsSimComponent, DotsLoaderComponent],
})
export class SimArchiveComponent extends BaseSitePageComponent {
  get baseRoute(): string {
    switch (this.slug) {
      case 'anteo':               return 'sim-anteo';
      case 'in-formation-paths':  return 'sim-event';
      default:                    return '';
    }
  }

  get isAnteoLatestPage() {
    return window.location.href.endsWith('anteo/latest');
  }

  get isAnteoLatestTemplate() {
    return USE_ANTEO_LATEST_TEMPLATE && this.isAnteoLatestPage;
  }

  limit: number = 0;

  override pageData?: ISimAnteoArchive | ISimEventArchive;

  override async loadPage() {
    console.log(this.slug, "->", this.baseRoute);

    this.limit = this.isAnteoLatestTemplate ? 1 : (this.slug === 'anteo' ? INITIAL_ANTEO_LIMIT : INITIAL_EVENT_LIMIT);

    try {
      switch (this.slug) {
        case 'anteo':
          this.pageData = await this.api.simPages.anteoArchive();
          if (this.isAnteoLatestPage) {
            this.toggleItem(this.pageData.items[0], 0, true);
          }
          break;
        case 'in-formation-paths':
          this.pageData = await this.api.simPages.list({ baseRoute: 'sim-event' });
          break;
        default:
          this.pageData = undefined;
      }
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    }
  }

  onLoadMoreClick() {
    this.limit += (this.slug === 'anteo' ? ANTEO_LIMIT_INCREMENT : EVENT_LIMIT_INCREMENT);
  }

  toggleItem(item: any, index: number, isInit?: boolean) {
    if (this.isAnteoLatestTemplate && !isInit) return;

    item.isOpen = !item.isOpen;

    if (item.isOpen && this.baseRoute === 'sim-anteo') {
      if (!item.issueContent) {
        this.loadAnteoIssue(item, (item as {issueNumber: string}).issueNumber);
      }
    }
  }

  loadingAnteoIssue: boolean = false;
  private async loadAnteoIssue(item: any, issueNumber: string) {
    this.loadingAnteoIssue = true;
    try {
      item.issueContent = await this.api.simPages.anteoIssue(issueNumber);
    } catch (ex: any) {
      console.error(ex);
    } finally {
      this.loadingAnteoIssue = false;
    }
  }
}
