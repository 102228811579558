import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { IHeaderRow } from '../../models/header';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LocaleEnum } from '../../models/common';

@Component({
  selector: 'app-mobile-header-menu',
  templateUrl: './mobile-header-menu.component.html',
  styleUrl: './mobile-header-menu.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule, MatMenuModule],
})
export class MobileHeaderMenuComponent {
  @Input() data?: IHeaderRow[] = [];
  @Input() currentLang: LocaleEnum = LocaleEnum.IT;

  activeFirstLevel?: IHeaderRow;
  activeSecondLevel?: IHeaderRow;
  firstLevelSlug?: string;

  onFirstLevelEnter(row: IHeaderRow) {
    // console.log('onFirstLevelEnter', row);

    this.firstLevelSlug = row.slug;

    if ((row.children?.length ?? 0) > 1) {
      this.activeFirstLevel = row;
    } else {
      /** skips the first level if it has only one child */
      this.activeFirstLevel = row.children?.[0];
    }
  }

  onSecondLevelEnter(row: IHeaderRow) {
    this.activeSecondLevel = row;
  }
}
