@if (pageData) {
    <div class="page insights-article {{categoryCssClass}}">
        <div class="cover-colored-filter"></div>
        
        <div class="article-and-related">
            <div class="title-box"></div>
            <div class="article-content">
                <div class="top-infos">
                    <div class="category flex-row">
                        <h2 class="area" [innerHTML]="pageData.area"></h2>
                        <h2 class="category-separator">|</h2>
                        <h2 class="office" [innerHTML]="pageData.subtitle"></h2>
                    </div>
                </div>
                
                <br>
    
                <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                <br>
    
                <div class="body inner-html inner-html--insights-article inner-html--open-positions-article" data-aos="fade-up" [innerHTML]="pageData.body"></div>

                <div class="application-btn">
                    <a [href]="pageData.permalink" target="_blank">
                        <app-prom-animated-button class="button" [theme]="'fluo-yellow'" [bgColor]="'light'" [arrow]="'top-right'">
                            {{'CAREERS.OPEN_POSITIONS.APPLY' | translate | uppercase}}
                        </app-prom-animated-button>
                    </a>
                </div>
            </div>
    
            <div class="related-wrapper">
                <div class="related-list">
                    @if (relatedOpenPositions?.items?.length) {
                        <p class="related-list--title">Related offers</p>
                        <h5 class="related-list--area">{{pageData.area}}</h5>
            
                        @for (related of relatedOpenPositions!.items; track $index) {
                            <div class="related-list--item">
                                @if (related.category) {
                                    <p class="category">{{related.category | uppercase}}</p>
                                }
                                <p class="title" [routerLink]="['/about-us/insights/article', related.slug]">{{related.title}}</p>
                            </div>
                        }
                    }
                </div>
            </div>
        </div>

        <div class="page-content">
            <app-section-contact-us [theme]="'yellow'" [text]="'Any questions?<br>Get in touch with our team!'"></app-section-contact-us>      
            
            <app-media-section [mediaSection]="discoverOurTribe" [pageRoot]="'careers'"></app-media-section>
        </div>
    </div>
}
@else {
    <div class="page-loading"></div>
}
