import { Component, Input, signal, WritableSignal } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { ICarouselItem } from '../../models/careers';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { VideoEmbedderComponent } from "../video-embedder/video-embedder.component";
import { MOBILE_BREAKPOINT } from '../../models/constants';
import { getYoutubeID } from '../../services/utilities.service';
import { IMediaSectionItem } from '../../models/common';

const defaultOptions: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: true,
  navSpeed: 700,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    }
  },
  autoplay: true,
  // autoplayHoverPause: true,
  autoplaySpeed: 1500,
  autoplayTimeout: 10000,
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  standalone: true,
  imports: [CommonModule, CarouselModule, PromAnimatedButtonComponent, VideoEmbedderComponent],
})
export class CarouselComponent {
  
  @Input() slides: ICarouselItem[] = [];

  customOptions: OwlOptions = defaultOptions;

  getSlideID(slide: ICarouselItem) {
    return (slide.label + ' ' + slide.details).replace(/\s/g, '-');
  }


  slideVideoItem?: IMediaSectionItem;
  openVideoSignal: WritableSignal<boolean> = signal(false);
  openVideo(slide: ICarouselItem) {
    if (!slide.link.url) {
      return;
    }
    this.slideVideoItem = this.slideToMediaItem(slide);
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      window.open("https://youtu.be/" + getYoutubeID(slide.link.url), '_blank');
      return;
    }
    this.openVideoSignal.set(true);
  }


  private slideToMediaItem(slide: ICarouselItem): IMediaSectionItem {
    return {
      type: 'video',
      title: '',
      body: '',
      url: slide.link.url,
    }
  }
}
