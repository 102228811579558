import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PromAnimatedButtonComponent } from '../prom-animated-button/prom-animated-button.component';
import { CommonModule } from '@angular/common';
import { INITIAL_PAPERS_LIMIT, PAPERS_LIMIT_INCREMENT } from '../../models/constants';

@Component({
  selector: 'app-papers-list',
  templateUrl: './papers-list.component.html',
  styleUrl: './papers-list.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, PromAnimatedButtonComponent],
})
export class PapersListComponent {

  @Input() papers?: {
    title: string,
    authors: string,
    pubDate: string,
    subtitle: string,
    url?: string,
  }[];

  lastIndexToView = INITIAL_PAPERS_LIMIT;

  get canLoadMore() {
    return (this.papers ?? []).length > this.lastIndexToView;
  }

  onLoadMoreClick() {
    this.lastIndexToView += PAPERS_LIMIT_INCREMENT;
  }
}
