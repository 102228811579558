import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { PageRoots } from '../../../models/pages';
import { IAboutUsPageOurTeam } from '../../../models/about-us';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IPeopleGroupBios, IPersonBio } from '../../../models/common';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPersonBioComponent } from '../../../components/dialog-person-bio/dialog-person-bio.component';
import { VIDEO_SOURCES } from '../../../models/constants';
import { PromAnimatedButtonComponent } from '../../../components/prom-animated-button/prom-animated-button.component';
import { alphabetLetter, AlphabetSelectorComponent } from '../../../components/alphabet-selector/alphabet-selector.component';
import { sleep } from '../../../services/utilities.service';

const ROOT_SLUG: PageRoots = 'about-us';

@Component({
  selector: 'app-our-team-page',
  templateUrl: './our-team-page.component.html',
  styleUrl: './our-team-page.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, PromAnimatedButtonComponent, AlphabetSelectorComponent],
})
export class OurTeamPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: PageRoots = ROOT_SLUG;
  readonly videoSrc = VIDEO_SOURCES.OUR_TEAM;

  selectedLetter?: alphabetLetter = undefined;

  override get slug() {
    return "our-team";
  }

  override pageData?: IAboutUsPageOurTeam;

  get notCollapsedPeopleSection() {
    return this.pageData?.peopleSection?.items.filter(p => !p.collapsed);
  }

  get collapsedPeopleSection() {
    if (!this.selectedLetter) return [];

    return this.phoneBook[this.selectedLetter] ?? [];
  }

  get enabledLetters(): alphabetLetter[] {
    return Object.keys(this.phoneBook) as alphabetLetter[];
  }

  constructor(
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly api: ApiService,
    protected override readonly translate: TranslateService,
    private readonly dialog: MatDialog,
  ) {
    super(activatedRoute, api, translate);
  }

  override ngOnInit(): void {
    super.staticSlugInit();
  }

  override async loadPage() {
    if (!this.slug) return;

    this.loading = true;
    try {
      this.pageData = await this.api.pages.getBySlug(this.slug);
      this.loading = false;

      this.formatPage();
      this.setPhoneBook();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    } finally {
      this.loading = false;
    }
  }

  onPersonClick(person: IPersonBio) {
    // console.log('onPersonClick', person);

    const dialogRef = this.dialog.open(DialogPersonBioComponent, {
      id: 'modal-dialog-person',

      minWidth: 'min(750px, 90vw)',
      // maxWidth: '60%',
      
      // height: 'auto',
      // minHeight: '80%',
      // maxHeight: '100%',
      
      data: {bio: person},
    });
    // dialogRef.afterClosed().subscribe( async (res) => {});
  }

  phoneBookStatus: "closed" | "opening" | "open" | "data-updating" | "closing" = "closed";
  previousLetter?: alphabetLetter;
  async onLetterSelected(letter?: alphabetLetter) {
    // console.log('onLetterSelected', letter, this.previousLetter, this.previousLetter === letter);
    const isFirst: boolean = !this.selectedLetter && letter !== undefined;
    if (isFirst) {
      this.phoneBookStatus = "opening";
    } else if (this.previousLetter === letter || letter == undefined) {
      this.phoneBookStatus = "closing";
    } else {
      this.phoneBookStatus = "data-updating";
    }
    
    if (isFirst) {
      this.selectedLetter = letter;
      await sleep(500);
      if (this.phoneBookStatus == "closing") this.phoneBookStatus = "closed";
      else this.phoneBookStatus = "open";
    } else {
      await sleep(500);
      this.selectedLetter = letter;
      if (this.phoneBookStatus == "closing") this.phoneBookStatus = "closed";
      else this.phoneBookStatus = "open";
    }

    this.previousLetter = letter;
  }

  phoneBook: any;
  private setPhoneBook() {
    if (!this.pageData) return;

    this.phoneBook = {};

    const ogList: IPeopleGroupBios[] = this.pageData?.peopleSection?.items.filter(p => p.collapsed) ?? [];
    let sortedList: IPersonBio[] = [];
    ogList.forEach(ogItem => {
      ogItem.items.forEach(p => p.position = ogItem.title);
      sortedList.push(...ogItem.items);
    });
    sortedList = sortedList.sort((a, b) => a.lastName && b.lastName ? a.lastName.localeCompare(b.lastName) : a.name.localeCompare(b.name));

    sortedList.forEach(p => {
      let letter = (p.lastName && p.lastName[0] ? p.lastName[0] : p.name[0] as any).toUpperCase();
      if (["Ä", "Á", "À", "Â"].includes(letter)) letter = "A";
      else if (["Ë", "É", "È", "Ê"].includes(letter)) letter = "E";
      else if (["Ï", "Í", "Ì", "Î"].includes(letter)) letter = "I";
      else if (["Ö", "Ó", "Ò", "Ô"].includes(letter)) letter = "O";
      else if (["Ü", "Ú", "Ù", "Û"].includes(letter)) letter = "U";
      
      // console.log(letter, p.lastName);

      if (!this.phoneBook[letter]) this.phoneBook[letter] = [];
      this.phoneBook[letter].push(p);
    });

    // console.log(this.phoneBook);
  }
}
