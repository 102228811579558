@if (news?.length) {
    <section class="news-headlines bgc--{{bgColor}}">
        <div class="news-cards">
            @for (newsItem of news; track $index) {
                <div class="news-card news-category--{{newsItem.category | kebabCase}}" [routerLink]="[articleRoot, newsItem.slug]" data-aos="fade-up">
                    @if (newsItem.heroImage) {
                        <div class="news-bg">
                            <img src="{{newsItem.heroImage}}" alt="{{newsItem.title}}">
                        </div>
                    }
                    @else if (newsItem.category === 'events') {
                        <div class="news-bg">
                            <img src="/assets/images/news-events-bg.png" alt="{{newsItem.title}}">
                        </div>
                    }
                    <div [ngClass]="{'news-colored-filter': true, 'no-image': !newsItem.heroImage}"></div>
                    <div class="news-border"></div>

                    <div>
                        <h5>{{newsItem.category | uppercase}}</h5>
                        <h4>{{newsItem.title}}</h4>
                        <h5>{{newsItem.eventDate | date: ('DATE_FORMAT_W_SPACE' | translate)}}</h5>
                    </div>

                    <div class="footer">
                        @if (newsItem.types?.length) {
                            <div class="news-icons flex-row">
                                @for (icon of newsItem.types; track $index) {
                                    @if (supportedIcons.includes(icon)) {
                                        <img class="icon icon-white" src="/assets/icons/insights/Type_{{icon | uppercase}}_NEG.png" alt="{{icon}}-icon">
                                        <img class="icon icon-blue" src="/assets/icons/insights/Type_{{icon | uppercase}}_POS.png" alt="{{icon}}-icon">
                                    }
                                }
                            </div>
                        }
    
                        <div class="go-to-news">
                            <img class="arrow arrow-blue" src="/assets/icons/right_arrow__blue.svg" alt="">
                            <img class="arrow arrow-green" src="/assets/icons/right_arrow__green.svg" alt="">
                            <img class="arrow arrow-white" src="/assets/icons/right_arrow__white_large.svg" alt="">
                        </div>
                    </div>
                </div>
            }
        </div>

        @if (showSeeMoreButton) {
            <div class="btn-container">
                <app-prom-animated-button [arrow]="'right'" [theme]="bgColor === 'white' ? 'deep-blue' : 'fluo-green'" [bgColor]="bgColor === 'white' ? 'light' : 'dark'" [routerLink]="['/' + currentLang, 'about-us', 'insights']">
                    {{'GENERAL.SEE_MORE' | translate | uppercase}}
                </app-prom-animated-button>
            </div>
        }
    </section>
}
