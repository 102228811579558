@if (!loading && pageData) {
    <div class="page about-us--social-responsibility">
        <!-- <div class="intro-background"></div> -->
        <!-- <video autoplay muted [muted]="true" loop webkit-playsinline playsinline class="intro-video-background">
            <source [src]="videoSrc" type="video/mp4">
        </video>
        <div class="intro-colored-filter"></div> -->
    
        <div class="page-content">
            <!-- <section class="intro">
                <div class="section-content">
                    <div class="category"><h2>Our Team</h2></div>
                    <div class="title"><h1>Lorem ipsum dolor sit amet, consectetuer</h1></div>
                </div>

                <app-long-arrow-down></app-long-arrow-down>
            </section> -->

            @if (pageData.peopleSection) {
                @for (peopleGroup of notCollapsedPeopleSection; track $index) {
                    <section class="people-group">
                        <div class="section-content">
                            <h3>{{peopleGroup.title}}</h3>

                            <div [ngClass]="{'people-list': true, 'collapsed': peopleGroup.collapsed, 'expanded': !peopleGroup.collapsed}">
                                @for (person of peopleGroup.items; track $index) {
                                    @if (peopleGroup.collapsed) {
                                        <div class="person interactive" (click)="onPersonClick(person)">
                                            <div class="avatar-wrap">
                                                <img [src]="person.avatar" />
                                            </div>
                                            <p class="name-and-job-position">
                                                <span class="name">{{person.name}}</span>
                                                @if (person.position) {
                                                    <span class="job-position">, {{person.position}}</span>
                                                }
                                            </p>
                                        </div>
                                    }
                                    @else {
                                        <div class="person">
                                            <div class="avatar-and-bio">
                                                @if (person.avatar) {
                                                    <div class="avatar-wrap">
                                                        @if (person.avatar) {
                                                            <img [src]="person.avatar" />
                                                        }
                                                    </div>
                                                }
                
                                                <div>
                                                    <p class="name-and-job-position">
                                                        <span class="name">{{person.name}}</span>
                                                        @if (person.position) {
                                                            <span class="job-position">, {{person.position}}</span>
                                                        }
                                                    </p>
                                                    @if (person.body?.trim()?.startsWith('<p>')) {
                                                        <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}" [innerHTML]="person.body"></div>   
                                                    }
                                                    @else {
                                                        <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}"><p [innerHTML]="person.body"></p></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    </section>
                }

                <section class="people-group">
                    <div class="section-content">
                        @if (pageData.partnerships) {
                            <h3>{{pageData.partnerships.title}}</h3>
                            @if (pageData.partnerships.body.trim().startsWith('<p>')) {
                                <div class="body" data-aos="fade-up" [innerHTML]="pageData.partnerships.body"></div>
                            }
                            @else {
                                <div class="body" data-aos="fade-up"><p [innerHTML]="pageData.partnerships.body"></p></div>
                            }
                        }

                        <div [ngClass]="{'alphabet-selector': true, 'open': collapsedPeopleSection.length > 0}">
                            <app-alphabet-selector [enabledLetters]="enabledLetters" (letterSelectedEvent)="onLetterSelected($event)"></app-alphabet-selector>
                        </div>

                        <!-- <p>{{collapsedPeopleSection.length}} people, {{phoneBookStatus}}, previous letter: {{previousLetter ?? '-'}}</p> -->
                        <div class="people-list expanded phonebook-container {{phoneBookStatus}}">
                            <div class="phonebook {{phoneBookStatus}}">
                                @for (person of collapsedPeopleSection; track $index) {
                                    <div class="person">
                                        <div class="avatar-and-bio">
                                            @if (person.avatar) {
                                                <div class="avatar-wrap">
                                                    @if (person.avatar) {
                                                        <img [src]="person.avatar" />
                                                    }
                                                </div>
                                            }
            
                                            <div>
                                                <p class="name-and-job-position">
                                                    <span class="name">{{person.name}}</span>
                                                    @if (person.position) {
                                                        <br>
                                                        <span class="job-position"><i>{{person.position}}</i></span>
                                                    }
                                                </p>
                                                @if (person.body?.trim()?.startsWith('<p>')) {
                                                    <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}" [innerHTML]="person.body"></div>   
                                                }
                                                @else {
                                                    <div [ngClass]="{'bio': true, 'no-avatar': !person.avatar}"><p [innerHTML]="person.body"></p></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }

            @if (pageData.ourWorld) {
                <div class="our-world-block">
                    <div class="our-world-block--background"></div>
                    <div class="our-world-block--colored-filter"></div>

                    <section class="our-world">
                        <div class="section-content">
                            <h3>{{pageData.ourWorld.title}}</h3>
            
                            <div class="button-container">
                                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" [routerLink]="[pageData.ourWorld.link.route]">
                                    {{pageData.ourWorld.link.label | uppercase}}
                                </app-prom-animated-button>
                            </div>
                        </div>
                    </section>
                </div>
            }
        </div>
    </div>
}
