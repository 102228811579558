{
  "Table1": [
    {
      "permalink": "https://www.prometeia.it/news/forrester-wave-digital-wealth-management-platforms-q1-2020",
      "slug": "forrester-wave-digital-wealth-management-platforms-q1-2020"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-certificazione-leed-gold-headquarter",
      "slug": "la-sede-centrale-di-prometeia-ottiene-la-certificazione-leed-gold"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-category-leader-credit-risk-data-integrity-control-solutions",
      "slug": "prometeia-a-category-leader-for-credit-risk-and-data-integrity-control-solutions"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-aderisce-10-principi-nazioni-unite",
      "slug": "prometeia-aderisce-ai-dieci-principi-del-global-compact-delle-nazioni-unite"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-al-fuorisalone",
      "slug": "prometeia-al-fuorisalone-27-mila-grazie"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-apre-branch-zurigo-consolida-presenza-mercato-svizzero",
      "slug": "prometeia-apre-branch-a-zurigo-e-consolida-la-presenza-nel-mercato-svizzero"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-best-economic-forecaster-2020-economia-italiana-secondo-focuseconomics",
      "slug": "prometeia-best-economic-forecaster-2020-per-leconomia-italiana-secondo-focuseconomics"
    },
    {
      "permalink": "https://www.prometeia.it/media/comunicati-stampa/primi-nel-tech-quadrant",
      "slug": "prometeia-category-leader-nel-quadrante-chartis-dellanalisi-dei-rischi-di-credito"
    },
    {
      "permalink": "https://www.prometeia.it/flex/cm/pages/ServeBLOB.php/L/IT/IDPagina/846",
      "slug": "prometeia-e-prima-al-mondo-per-le-soluzioni-liquidity-risk-e-alm-nella-classifica-risktech100-di-chartis"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-launches-ilias-new-interactive-answer-to-complex-liquidity-risk-challenges",
      "slug": "prometeia-launches-ilias-the-new-interactive-answer-to-ever-more-complex-liquidity-risk-challenges"
    },
    {
      "permalink": "https://www.prometeia.it/news/chartis-IFRS9-fintech-quadrant-category-leader",
      "slug": "prometeia-leader-nel-quadrante-chartis-per-le-soluzioni-ifrs-9"
    },
    {
      "permalink": "https://www.prometeia.it/news/Prometeia-2018-Risk-Tech-100-award",
      "slug": "prometeia-still-a-leader-in-risktech100-customer-satisfaction-rankings"
    },
    {
      "permalink": "https://www.prometeia.it/media/news/Prometeia-2016-Risk-technology-Rankings",
      "slug": "prometeia-vince-nella-categoria-liquidity-risk-management-del-risk-technology-rankings-2016"
    },
    {
      "permalink": "https://www.prometeia.it/XCelent-piattaforma-PFTPro",
      "slug": "prometeia-vincitrice-dei-premi-xcelent-per-advanced-technology-e-customer-base"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-wins-balance-sheet-risk-management-category-award-in-the-2021-risktech100",
      "slug": "prometeia-wins-balance-sheet-risk-management-category-award-in-the-2021-risktech100"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-wins-risk-technology-award-best-credit-stress-testing-product",
      "slug": "prometeia-wins-risk-technology-award-for-best-credit-stresstesting-product-of-the-year"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-wins-risk-technology-award-best-enterprise-wide-stress-testing-product",
      "slug": "prometeia-wins-risk-technology-award-for-best-enterprisewide-stresstesting-product-of-the-year"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia-wins-risktech100-2019-award-for-capital-optimization",
      "slug": "prometeia-wins-the-risktech100-2019-award-for-capital-optimization"
    },
    {
      "permalink": "https://www.prometeia.it/news/prometeia.wins-xcelent-award-balance-sheet-management-alm-solution",
      "slug": "prometeia-wins-xcelent-award-for-its-balance-sheet-management-alm-solution"
    },
    {
      "permalink": "https://www.prometeia.it/chi-siamo/prometeia-40/prometeia-40-presidente-bce-mario-draghi",
      "slug": "prometeia40-con-il-presidente-bce-mario-draghi"
    },
    {
      "permalink": "https://www.prometeia.it/news/forrester-wave-digital-wealth-management-platforms-q1-2020",
      "slug": "the-forrester-wave-digital-wealth-management-platforms-q1-2020"
    },
    {
      "permalink": "https://www.prometeia.it/news/new-frontier-integrated-balance-sheet-management-in-turkey",
      "slug": "the-new-frontier-of-integrated-balance-sheet-management"
    },
    {
      "permalink": "https://www.prometeia.it/news/wealthtech-100-prometeia-ancora-una-volta-tra-principali-tech-pioneer-globali",
      "slug": "wealthtech-100-prometeia-ancora-una-volta-tra-i-principali-tech-pioneer-a-livello-globale"
    },
    {
      "permalink": "https://www.prometeia.it/news/financial-modelling-insights-the-inflation-index",
      "slug": "financial-modelling-insights-the-inflation-index"
    },
    {
      "permalink": "https://www.prometeia.it/news/banks-sailing-in-uncertain-waters",
      "slug": "banks-sailing-in-uncertain-waters"
    },
    {
      "permalink": "https://www.prometeia.it/news/risk-sharing-and-risk-reduction-in-the-euro-area-banking-sector",
      "slug": "risk-sharing-and-risk-reduction-in-the-euro-area-banking-sector"
    },
    {
      "permalink": "https://www.prometeia.it/en/media/neural-networks-motor-insurance-paper",
      "slug": "neural-networks-motor-insurance-paper"
    },
    {
      "permalink": "https://www.prometeia.it/news/regolamentazione-e-apertura-volontaria-breve-guida-open-banking-in-Italia-e-estero",
      "slug": "regolamentazione-e-apertura-volontaria-breve-guida-open-banking-in-Italia-e-estero"
    },
    {
      "permalink": "https://www.prometeia.it/news/fair-value-of-defaultable-bonds-and-bank-loans-a-practitioners-guide",
      "slug": "fair-value-of-defaultable-bonds-and-bank-loans-a-practitioners-guide"
    },
    {
      "permalink": "https://www.prometeia.it/news/towards-cfo-cro-collaboration-model-real-challenges-for-banks",
      "slug": "towards-cfo-cro-collaboration-model-real-challenges-for-banks"
    },
    {
      "permalink": "https://www.prometeia.it/news/basel-3-or-4-where-do-we-stand",
      "slug": "basel-3-or-4-where-do-we-stand"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/psd2-portabilita-informazioni-conti",
      "slug": "psd2-portabilita-informazioni-conti"
    },
    {
      "permalink": "https://www.prometeia.it/news/ECB-liquidity-stress-test-2019-white-paper",
      "slug": "ECB-liquidity-stress-test-2019-white-paper"
    },
    {
      "permalink": "https://www.prometeia.it/news/how-will-credit-spread-risk-in-the-banking-book-be-put-into-practice-prometeia-survey",
      "slug": "how-will-credit-spread-risk-in-the-banking-book-be-put-into-practice-prometeia-survey"
    },
    {
      "permalink": "https://www.prometeia.it/news/npl-nuova-asset-class",
      "slug": "npl-nuova-asset-class"
    },
    {
      "permalink": "https://www.prometeia.it/news/why-italian-banks-pay-higher-returns-to-investors",
      "slug": "why-italian-banks-pay-higher-returns-to-investors"
    },
    {
      "permalink": "https://www.prometeia.it/ricerca/position-note/settembre2019",
      "slug": "settembre2019"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/do-low-interest-rates-really-hurt-banks-profitability",
      "slug": "do-low-interest-rates-really-hurt-banks-profitability"
    },
    {
      "permalink": "https://www.prometeia.it/news/how-banks-respond-ECB-validation-reporting-model-risk-management-requirements-findings",
      "slug": "how-banks-respond-ECB-validation-reporting-model-risk-management-requirements-findings"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/basel-4-and-reduction-in-rwa-variability-capital-buffers-and-capital-erosion",
      "slug": "basel-4-and-reduction-in-rwa-variability-capital-buffers-and-capital-erosion"
    },
    {
      "permalink": "https://www.prometeia.it/prometeiamio/int/nostra-visione/coronavirus-peso-economico-territori-colpiti-italia",
      "slug": "coronavirus-peso-economico-territori-colpiti-italia"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/coronavirus-aiuto-intelligenza-artificiale",
      "slug": "coronavirus-aiuto-intelligenza-artificiale"
    },
    {
      "permalink": "https://www.prometeia.it/news/coco-bonds-analisi-buy-side-e-sell-side",
      "slug": "coco-bonds-analisi-buy-side-e-sell-side"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/che-impatto-avra-pandemia-covid-19-su-climate-change",
      "slug": "che-impatto-avra-pandemia-covid-19-su-climate-change"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/peltro-bce-che-cosa-e-come-funziona",
      "slug": "peltro-bce-che-cosa-e-come-funziona"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/banche-sistemi-pagamento-lezioni-dalla-pandemia",
      "slug": "banche-sistemi-pagamento-lezioni-dalla-pandemia"
    },
    {
      "permalink": "https://www.prometeia.it/prometeiamio/int/nostra-visione/covid-impatto-su-export-regioni-italiane",
      "slug": "covid-impatto-su-export-regioni-italiane"
    },
    {
      "permalink": "https://www.prometeia.it/prometeiamio/int/nostra-visione/impatto-covid-e-recessione-su-occupazione-femminile",
      "slug": "impatto-covid-e-recessione-su-occupazione-femminile"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/text-analysis-su-comunicazione-banche-centrali-ai-tempi-coronavirus",
      "slug": "text-analysis-su-comunicazione-banche-centrali-ai-tempi-coronavirus"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/Next-generation-eu-accordo-storico-che-puo-cambiare-volto-dell-europa",
      "slug": "Next-generation-eu-accordo-storico-che-puo-cambiare-volto-dell-europa"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/banche-italiane-6-nuove-tendenze-dettate-da-crisi-covid-19",
      "slug": "banche-italiane-6-nuove-tendenze-dettate-da-crisi-covid-19"
    },
    {
      "permalink": "https://www.prometeia.it/news/ai-risk-management-fattori-abilitanti-use-case-sfide-future",
      "slug": "ai-risk-management-fattori-abilitanti-use-case-sfide-future"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/diverse-performance-banche-europee-attravers-lente-business-model",
      "slug": "diverse-performance-banche-europee-attravers-lente-business-model"
    },
    {
      "permalink": "https://www.prometeia.it/news/climate-change-risk-across-EU-banking-system-prometeia-survey",
      "slug": "climate-change-risk-across-EU-banking-system-prometeia-survey"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/gacs-schema-garanzia-statale-italiano-per-cartolarizzazioni-npl",
      "slug": "gacs-schema-garanzia-statale-italiano-per-cartolarizzazioni-npl"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/central-bank-digital-currencies-what-is-at-stake-fot-the-banking-system",
      "slug": "central-bank-digital-currencies-what-is-at-stake-fot-the-banking-system"
    },
    {
      "permalink": "https://www.prometeia.it/atlante/prometeia-discussion-note-distributive-effects-covid-italy",
      "slug": "prometeia-discussion-note-distributive-effects-covid-italy"
    },
    {
      "permalink": "https://www.prometeia.it/ricerca/position-note/settembre2020",
      "slug": "settembre2020"
    },
    {
      "permalink": "https://www.prometeia.it/ricerca/position-note/luglio2019",
      "slug": "luglio2019"
    },
    {
      "permalink": "https://www.prometeia.it/ricerca/position-note/marzo2019",
      "slug": "marzo2019"
    },
    {
      "permalink": "https://www.prometeia.it/ricerca/position-note/dicembre2018",
      "slug": "dicembre2018"
    },
    {
      "permalink": "https://www.prometeia.it/ricerca/position-note/settembre2018",
      "slug": "settembre2018"
    }
  ]
}