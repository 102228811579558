import { ISitePageLink } from "./common";
import { IHeaderRow } from "./header";
import { ISitePageMediaSection } from "./pages";

export const DEFAULT_VIDEO_WIDTH = 720;
export const DEFAULT_VIDEO_HEIGHT = 685;

export const MOBILE_BREAKPOINT = 1024;

export const SCREEN_SIZE_IPDA_AIR = 1180;       // same as _variables.scss
export const SCREEN_SIZE_MOBILE_VERTICAL = 430; // same as _variables.scss

export const STRING_SEPARATOR = "__";

export const INITIAL_NEWS_LIMIT: number = 12;
export const NEWS_LIMIT_INCREMENT: number = 12;
export const INITIAL_PAPERS_LIMIT: number = 5;
export const PAPERS_LIMIT_INCREMENT: number = 5;

/** /cdn2/site/intro-videos/FILE_NAME.mp4 */
export const VIDEO_SOURCES: {
  HOME: string;
  SIM_HOME: string;
  SIM_PAGE: string;
  OUR_TRIBE: string;
  SOCIAL_RESPONSIBILITY: string;
  OUR_WORLD: string;
  OUR_TEAM: string;
} = {
  HOME: "/cdn2/site/intro-videos/homepage.mp4",

  SIM_HOME: "/cdn2/site/intro-videos/homepage.mp4",
  SIM_PAGE: "/cdn2/site/intro-videos/sim_consulenza.mp4",

  /** careers */
  OUR_TRIBE: "/cdn2/site/intro-videos/our_tribe.mp4",

  /** about-us */
  SOCIAL_RESPONSIBILITY: "/cdn2/site/intro-videos/about-us_csr_v2.mp4",
  OUR_WORLD: "/cdn2/site/intro-videos/our_world.mp4",
  OUR_TEAM: "",
};

export const AR_PRIVACY_ROUTE = 'article/publishing';

export const SOCIALS_LIST: {type: string, link: string}[] = [
  // { type: "facebook", link: "https://www.facebook.com/prometeiagroup" },
  // { type: "twitter", link: "https://twitter.com/PrometeiaGroup" },
  { type: "linkedin", link: "https://www.linkedin.com/company/prometeia/mycompany/" },
  { type: "youtube", link: "https://www.youtube.com/channel/UCbz97h9v96HGofLJiZrScEw" },
  { type: "instagram", link: "https://www.instagram.com/prometeiagroup/" },
];

export const FOOTER_DATA_SPA: ISitePageLink[] = [
  { label: "Company Information", route: "article/company-information" },
  { label: "Code of Ethics & Model 231", route: "article/ethics" },
  { label: "Whistleblowing", route: "article/whistleblowing-prometeia-spa" },
  { label: "Privacy Policy", route: "article/privacy-policy" },
  { label: "Cookies settings and policy", route: "article/cookies" },
  { label: "Quality policy", route: "article/quality" },
];

export const FOOTER_DATA_SIM: ISitePageLink[] = [
  { label: "Company Information", route: "article/company-information-sim" },
  { label: "Code of Ethics & Model 231", route: "article/ethics-sim" },
  { label: "Whistleblowing", route: "article/whistleblowing-prometeia-sim" },
  { label: "Privacy Policy", route: "article/privacy-policy-sim" },
  { label: "Cookies settings and policy", route: "article/cookies-sim" },
];

export const FOOTER_DATA_SIM__ITA: ISitePageLink[] = [
  { label: "Statuto", url: "https://www.prometeia.3logic.it/cdn/FILES/footer/Statuto%2028_04_2023.pdf" },
];

export enum CONTACT_US_EMAILS {
  SPA_HEADER = 'media@prometeia.com',
  SPA_FOOTER = 'media@prometeia.com',
  SIM = 'info@prometeiaadvisor.com',
}

export const DISCOVER_OUR_TRIBE: ISitePageMediaSection = {
  items: [
    {
      type: "text",
      size: "half",
      title: "Discover more about the way we work",
      theme: "electric-blue",
      link: {
        label: "Our Tribe",
        route: "/careers/our-tribe",
      }
    },
    {
      type: "image",
      size: "half",
      url: "/cdn2/site/careers/Discover_Our_Tribe.png",
      title: "",
    }
  ]
}

export const RESERVED_AREAS_IT: ISitePageMediaSection = {
  items: [
    /** SPA - start */
    {
      type: 'text',
      size: 'full',
      title: 'Appia',
      body: `<p>Piattaforma digitale a misura di PMI che integra dati, scenari ed insight a supporto delle scelte operative e strategiche per l’import delle imprese, con l’analisi dei prezzi di oltre 70 input produttivi</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_1_Appia.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_1_Appia.png',
      link: {
        label: 'Login',
        url: 'https://home-appia.prometeia.com/',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Banking & Markets Data Hub',
      body: `<p>Le nostre analisi e previsioni forniscono dashboard e aggiornamenti personalizzati su e per il settore bancario, i mercati, gli intermediari finanziari, gli aggregati macrofinanziari e i trend del risparmio</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_2_Banking_&_Markets_Data_Hub.png',
      link: {
        label: 'Login',
        url: 'https://banking-marketsdatahub.prometeia.it/login/',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Macro Intelligence',
      body: `<p>Analisi, previsioni e scenari macroeconomici a confronto su misura per gli utenti, con dashboard per effettuare le proprie simulazioni e repository di documenti e report dedicati</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_3_Macro_Intelligence.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_3_Macro_Intelligence.png',
      link: {
        label: 'Login',
        url: 'https://macro.prometeia.it/MacroIntelligence/#/login',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Settori Industriali, Economie Locali & Commodity',
      body: `<p>Repository online con le nostre analisi dei settori e di 170 microsettori dell'economia italiana, le previsioni economiche per le regioni e province italiane, gli andamenti delle materie prime</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_4_Settori_Industriali,_Economie_Locali.png',
      link: {
        label: 'Login',
        route: 'reserved-area',
      }
    },
    /** SPA - end */

    /** SIM - start */
    {
      type: 'text',
      size: 'full',
      title: 'Asset Management Platform',
      body: `<p>Piattaforma web di dialogo tra Prometeia Advisor Sim e gli asset manager, che comunicano gli aggiornamenti della propria gamma di prodotti e ricevono le ultime su attività di selezione e prodotti UCITS</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_5_Asset_Management_Platform.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_5_Asset_Management_Platform.png',
      link: {
        label: 'Login',
        url: 'https://am.advisor.prometeia.net/flex/cm/pages/CommunityLoginOut.php/L/EN/BL/aHR0cDovL2FtLmFkdmlzb3IucHJvbWV0ZWlhLm5ldC9mbGV4L2NtL3BhZ2VzL1NlcnZlQkxPQi5waHAvU1VfL2hvbWU%3D',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Fondi pensione',
      body: `<p>Realizziamo analisi di portafoglio e report patrimoniali customizzati per fondi pensione e casse di previdenza, oltre a strumenti a supporto del risk management dell’ente</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_6_Fondi_Pensione.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_6_Fondi_Pensione.png',
      link: {
        label: 'Login',
        route: 'reserved-area',
      }
    }
    /** SIM - end */
  ]
}
export const RESERVED_AREAS_EN: ISitePageMediaSection = {
  items: [
    /** SPA - start */
    {
      type: 'text',
      size: 'full',
      title: 'Appia',
      body: `<p>A digital platform designed specifically for SMEs that integrates data, scenarios, and insights to support both operational and strategic import decisions. It includes analysis of prices for over 70 industrial inputs</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_1_Appia.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_1_Appia.png',
      link: {
        label: 'Login',
        url: 'https://home-appia.prometeia.com/',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Banking & Markets Data Hub',
      body: `<p>We provide analyses and forecasts, featuring customized dashboards and updates, about and for the banking sector, markets and financial intermediaries, macro-financial aggregates, and savings trends</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_2_Banking_&_Markets_Data_Hub.png',
      link: {
        label: 'Login',
        url: 'https://banking-marketsdatahub.prometeia.it/login/',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Macro Intelligence',
      body: `<p>Tailor-made macroeconomic analyses, forecasts, and scenarios, with dashboards for users’ own simulations and a repository of dedicated documents and reports</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_3_Macro_Intelligence.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_3_Macro_Intelligence.png',
      link: {
        label: 'Login',
        url: 'https://macro.prometeia.it/MacroIntelligence/#/login',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Industrial Sectors, Regional Economies & Commodities',
      body: `<p>Online repository with our analyses of sectors and 170 micro-sectors of the Italian economy, economic forecasts for the regions and provinces of Italy, and trends in commodities</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_4_Settori_Industriali,_Economie_Locali.png',
      link: {
        label: 'Login',
        route: 'reserved-area',
      }
    },
    /** SPA - end */

    /** SIM - start */
    {
      type: 'text',
      size: 'full',
      title: 'Asset Management Platform',
      body: `<p>A web platform facilitating communication between Prometeia Advisor Sim and asset managers, allowing for the exchange of product range updates and the latest information regarding UCITS product and selection activities</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_5_Asset_Management_Platform.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_5_Asset_Management_Platform.png',
      link: {
        label: 'Login',
        url: 'https://am.advisor.prometeia.net/flex/cm/pages/CommunityLoginOut.php/L/EN/BL/aHR0cDovL2FtLmFkdmlzb3IucHJvbWV0ZWlhLm5ldC9mbGV4L2NtL3BhZ2VzL1NlcnZlQkxPQi5waHAvU1VfL2hvbWU%3D',
      }
    },
    {
      type: 'text',
      size: 'full',
      title: 'Pension Funds',
      body: `<p>We provide customized portfolio analyses and asset reports for pension and social security funds, along with tools to support the entity’s risk management</p>`,
      url: '/cdn2/site/reserved-areas/Area_riservata_6_Fondi_Pensione.png',
      cover: '/cdn2/site/reserved-areas/Sfondo_6_Fondi_Pensione.png',
      link: {
        label: 'Login',
        route: 'reserved-area',
      }
    }
    /** SIM - end */
  ]
}
