import { Component } from '@angular/core';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { LongArrowDownComponent } from "../../../components/long-arrow-down/long-arrow-down.component";
import { MediaSectionComponent } from "../../../components/media-section/media-section.component";
import { RoutesToPagesComponent } from "../../../components/routes-to-pages/routes-to-pages.component";
import { LocaleEnum } from '../../../models/common';
import { ISimPage } from '../../../models/sim';
import { PromCardsListComponent } from "../../../components/prom-cards-list/prom-cards-list.component";
import { SectionContactUsSimComponent } from "../../../components/section-contact-us-sim/section-contact-us-sim.component";
import { LinksListComponent } from "../../../components/links-list/links-list.component";
import { VIDEO_SOURCES } from '../../../models/constants';

const BASE_ROUTE: 'sim-page' = 'sim-page';

@Component({
  selector: 'app-sim-page',
  templateUrl: './sim-page.component.html',
  styleUrl: './sim-page.component.scss',
  standalone: true,
  imports: [LongArrowDownComponent, MediaSectionComponent, RoutesToPagesComponent, PromCardsListComponent, SectionContactUsSimComponent, LinksListComponent],
})
export class SimPageComponent extends BaseSitePageComponent {

  override readonly pageRoot: any = BASE_ROUTE;
  readonly videoSrc = VIDEO_SOURCES.SIM_PAGE;

  override async loadPage() {
    // console.log(BASE_ROUTE, "->", this.slug);

    // this.pageData = SIM_PAGE__MOCK_DATA;
    // this.pageData.mediaSection.items[0].theme = 'sim-red';

    try {
      const [pageData, consulenceItems] = await Promise.all([
        this.api.simPages.get({ baseRoute: this.pageRoot, slug: this.slug || '' }),
        this.api.simPages.list({ baseRoute: this.pageRoot })
      ]);
      this.pageData = pageData;
      this.pageData.consulence.items = consulenceItems.filter((c: {slug: string}) => c.slug !== this.slug);

      this.formatPage();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    }
  }
}

const SIM_PAGE__MOCK_DATA: ISimPage = {
  root: "careers", // fake
  baseRoute: "sim-page",
  locale: LocaleEnum.IT,
  slug: "sim-page",
  title: `Performance
          & Risk Analysis`,
  subtitle: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod",

  body: `Lʼapproccio alla misurazione e alla valutazione delle
        performance e dei rischi si caratterizza per il supporto
        consulenziale al disegno di un processo personalizzato
        sulle esigenze del Cliente e per una tecnologia
        proprietaria robusta e flessibile.`,

  cards: {
    items: [
      {
        title: "Data Analysis Services",
        body: "Facilitiamo la riconciliazione dei portafogli e la gestione professionale delle basi dati, di mercato e specialistiche (es. ESG), al fine di garantirne affidabilità e tempestività, imprescindibili per un risk management di qualità.",
        download: {
          label: "play",
          url: "/cdn2/upload/2024/9/4d4bdf37-22cf-4756-8765-27b961280fc0.png"
        }
      },
      {
        title: "Performance and Risk Measurement & Attribution",
        body: "Realizziamo con modelli proprietari la misurazione, la contribuzione e lʼattribuzione della redditività e dei rischi di portafoglio, fornendo una reportistica personalizzata per gli Organi decisionali, le figure manageriali e quelle operative."
      },
      {
        title: "Analisi degli strumenti gestiti e dei mandati di gestione",
        body: "Supportiamo il monitoraggio degli strumenti gestiti in portafoglio e dei mandati di gestione attraverso specifici modelli proprietari quantitativi di valutazione della performance, assoluta e relativa, anche rispetto a parametri specifici (es. ESG)."
      },
      {
        title: "Risk Analysis",
        body: "Realizziamo la scomposizione dei portafogli per fattori di rischio, la stima delle sensitivity e gli stress test. Anche a seguito della evoluzione normativa (es. IORP II), supportiamo i Clienti nella individuazione e misurazione di rischi diversi rispetto a quelli più specifici degli investimenti sui mercati finanziari (es. rischi ESG)."
      }
    ]
  },

  consulence: {
    title: "Altri servizi di consulenza",
    items: [
      { title: "Asset Allocation Strategica", slug: "strategic-asset-allocation" },
      { title: "Asset Allocation Tattica", slug: "tactic-asset-allocation" },
      { title: "ALM", slug: "alm" },
      { title: "Selezione gestori e prodotti gestiti", slug: "selection-of-managers-and-managed-products" },
      { title: "Risk Management", slug: "risk-management" },
      { title: "Investment Process Engineering", slug: "investment-process-engineering" },
      { title: "RTO e Mediazione", slug: "rto-and-mediation" },
      { title: "Sostenibilità", slug: "sustainability" },
    ],
    links: [
      {
        label: "Arbitro per le controversie finanziarie",
        url: "/sim/it/article/ombudsman"
      }
    ]
  },

  mediaSection: {
    items: [
      {
        type: "text",
        size: "full",
        title: "Learn more about Prometeia Advisor Sim",
        download: {
          label: "Company Profile",
          url: "/cdn2/upload/2024/9/ab1dc25d-b8c0-479c-a346-2f616f0533b1.pdf"
        },
      }
    ]
  },
}

