export enum LocaleEnum {
    IT = 'it',
    EN = 'en'
}

export const SUPPORTED_LANGS = Object.values(LocaleEnum);

export const DEFAULT_LANG = LocaleEnum.IT;

export enum NewsCategoriesEnum {
    Ideas = 'ideas',
    News = 'news',
    CaseStudies = 'case-studies',
    Events = 'events',
    Awards = 'awards',
}

export enum NewsTypesEnum {
    Article = 'article',
    Video = 'video',
    Podcast = 'podcast',
    Report = 'report',
    Dataviz = 'dataviz',
}

export enum NewsTypesWithIconEnum {
    Video = 'video',
    Podcast = 'podcast',
    Report = 'report',
    Dataviz = 'dataviz',
}

export type ButtonTheme = 'deep-blue' | 'fluo-green' | 'fluo-yellow';


export interface ISitePageLink {
    label: string;
    url?: string;
    route?: string;
}

export interface ISitePageCard {
    title: string;
    body: string;
    link?: ISitePageLink;
    download?: ISitePageLink;
}

export interface ISitePageFlippingCard {
    title: string;
    body: string;
    cover?: string;
}

export interface ISitePageReference {
    slug: string;
    title: string;


    /** for Careers */

    jobArea?: string;
    jobCategory?: string;
    jobOffice?: string;
}

export interface IMediaSectionItem {
    type: 'text' | 'video' | 'image' | 'text+image' | 'text+link';

    /** default: "full" */
    size?: 'half' | 'full' | 'third';
    
    title: string;
    body?: string;

    download?: ISitePageLink;

    /** external URL or internal route */
    link?: ISitePageLink;

    /** video or image URL */
    url?: string;
    
    /** cover image for video in URL */
    cover?: string;
}

export interface INewsReference extends ISitePageReference {
    category: string;
    types?: NewsTypesWithIconEnum[];

    heroImage?: string;
    eventDate?: Date;
}

export interface IReport {
    title: string,
    body: string,
    img: string,
    route: string,
}

export interface INewslettersItem {
    title: string;
    slug: string;
    body: string;
    img: string;

    isOpen?: boolean;
}

export interface ISelectorOptions {
    name: string;
    code?: number;
    selected?: boolean;
}

export interface ISelector {
    name: string;
    options: ISelectorOptions[];
}
