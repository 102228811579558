import { AfterViewInit, Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { ISitePageCard } from '../../models/common';
import { PromCardComponent } from "../prom-card/prom-card.component";
import { sleep } from '../../services/utilities.service';
import { SCREEN_SIZE_MOBILE_VERTICAL } from '../../models/constants';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Debounce } from 'lodash-decorators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prom-cards-list',
  templateUrl: './prom-cards-list.component.html',
  styleUrl: './prom-cards-list.component.scss',
  standalone: true,
  imports: [PromCardComponent],
})
export class PromCardsListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() cards: ISitePageCard[] = [];
  @Input() size: 'full' | 'half' | 'third' = 'half';
  @Input() arrowColor: 'blue' | 'green' = 'blue';
  @Input() titleColor: 'default' | 'green' = 'default';
  @Input() theme: 'default' | 'yellow' | 'red' = 'default';

  /** used for pages with multiple prom-cards-list */
  @Input() sectionID?: string;

  private longestTextIndex: number = -1;
  private longestTextNumber: number = 0;

  /** used for debug */
  private longestTitle: string = '';

  calculatedHeight: number = 0;

  activatedRouteParamMapSubscription?: Subscription;
  routerEventsSubscription?: Subscription;
  langChangeSubscription?: Subscription;

  loading: boolean = false;

  constructor (
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((_) => {
      this.findTallestCard();
    });

    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.findTallestCard();
      }
    });

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.findTallestCard();
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
    this.routerEventsSubscription?.unsubscribe();
    this.langChangeSubscription?.unsubscribe();
  }

  @Debounce(1)
  private findTallestCard(): string {
    this.loading = true;

    this.longestTextIndex = -1;
    this.longestTextNumber = 0;
    this.longestTitle = '';
    this.calculatedHeight = 0;

    this.cards?.forEach((item, index) => {
      const textLen = (item.title.length * 2.7) + (item.body.length * 1.6);
      // console.log("textLen", textLen, item.title, "\n\t", item.body);
      if (textLen > this.longestTextNumber) {
        this.longestTitle = item.title;

        this.longestTextNumber = textLen;
        this.longestTextIndex = index;
      }
    });

    this.loading = false;

    return this.longestTitle;
  }
    
  ngAfterViewInit() {
    // console.log("ngAfterViewInit");
    this.setCardsHeight();
  }

  ngOnChanges() {
    // console.log("ngOnChanges");
    this.setCardsHeight();
  }

  @Debounce(10)
  @HostListener('window:resize', ['$event'])
  private async setCardsHeight(): Promise<void> {
    do {
      await sleep(1);
    } while (this.loading || this.longestTextIndex == -1);

    const screenWidth = window.screen.width;
    if (screenWidth <= SCREEN_SIZE_MOBILE_VERTICAL) {
      this.calculatedHeight = 0;
      return;
    }

    const tallestCard = this.sectionID ? document.getElementById(`single-prom-card-${this.longestTextIndex}-${this.sectionID}`) : document.getElementById(`single-prom-card-${this.longestTextIndex}`) as HTMLElement | null;

    // console.log(this.longestTitle, "---" , tallestCard?.getElementsByClassName("p-card--title")[0]?.textContent);

    if (tallestCard) {
      /** if tallest card title and "longestTitle" are not the same, we must re-check all the data */
      if (tallestCard.getElementsByClassName("p-card--title")[0]?.textContent != this.longestTitle) {
        this.findTallestCard();
        return await this.setCardsHeight();
      }

      // console.log("card's calculatedHeight:", tallestCard.clientHeight, this.longestTitle, "---", this.sectionID);
      
      if (this.size === 'third') {
        /* -80 to account for vertical padding (40px on top and 40px on bottom) */
        this.calculatedHeight = tallestCard.clientHeight - 80;
      } else if (this.size === 'half') {
        /* -110 to account for vertical padding (60px on top and 60px on bottom, minus a little offset) */
        this.calculatedHeight = tallestCard.clientHeight - 110;
      } else {
        /** if size === 'full', then calculatedHeight will be ignored */
      }
    }
  }
}
