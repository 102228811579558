import { IHeaderRow } from "./header";
import { ISitePageMediaSection } from "./pages";

export const DEFAULT_VIDEO_WIDTH = 720;
export const DEFAULT_VIDEO_HEIGHT = 685;

export const MOBILE_BREAKPOINT = 1024;

/** /cdn2/site/intro-videos/FILE_NAME.mp4 */
export const VIDEO_SOURCES: {
  HOME: string;
  SIM_HOME: string;
  SIM_PAGE: string;
  OUR_TRIBE: string;
  SOCIAL_RESPONSIBILITY: string;
  OUR_WORLD: string;
  OUR_TEAM: string;
} = {
  HOME: "/cdn2/site/intro-videos/homepage.mp4",

  SIM_HOME: "/cdn2/site/intro-videos/homepage.mp4",
  SIM_PAGE: "/cdn2/site/intro-videos/sim-services.mp4",

  /** careers */
  OUR_TRIBE: "https://www.prometeia.com/cdn/careers/Video-Intro_2.mp4",

  /** about-us */
  SOCIAL_RESPONSIBILITY: "/cdn2/site/intro-videos/about-us_csr.mp4",
  OUR_WORLD: "/assets/videos/SOL_cover.mp4",
  OUR_TEAM: "/assets/videos/EXP_cover.mp4",
};

export const AR_PRIVACY_ROUTE = 'article/publishing';

export const DISCOVER_OUR_TRIBE: ISitePageMediaSection = {
  items: [
    {
      type: "text",
      size: "half",
      title: "Discover more about the way we work",
      theme: "electric-blue",
      link: {
        label: "Our Tribe",
        route: "/careers/our-tribe",
      }
    },
    {
      type: "image",
      size: "half",
      url: "/assets/images/bg-customers.png",
      title: "",
    }
  ]
}

export const RESERVED_AREAS: ISitePageMediaSection = {
    items: [
      /** SPA - start */
      {
        type: 'text',
        size: 'full',
        title: 'Appia',
        body: `<p>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla sed
              quam in orci scelerisque dignissim.</p>`,
        url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
        cover: '/assets/images/tmp_AR_Appia.png',
        link: {
          label: 'Login',
          url: 'https://home-appia.prometeia.com/',
        }
      },
      {
        type: 'text',
        size: 'full',
        title: 'Banking & Markets Data Hub',
        body: `<p>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla sed
              quam in orci scelerisque dignissim.</p>`,
        url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
        link: {
          label: 'Login',
          url: 'https://banking-marketsdatahub.prometeia.it/login/',
        }
      },
      {
        type: 'text',
        size: 'full',
        title: 'Macro Intelligence',
        body: `<p>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla sed
              quam in orci scelerisque dignissim.</p>`,
        url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
        cover: '/assets/images/tmp_AR_Macro_Intelligence.png',
        link: {
          label: 'Login',
          url: 'https://macro.prometeia.it/MacroIntelligence/#/login',
        }
      },
      {
        type: 'text',
        size: 'full',
        title: 'Settori Industriali, Economie Locali & Commodity',
        body: `<p>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla sed
              quam in orci scelerisque dignissim.</p>`,
        url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
        link: {
          label: 'Login',
          route: 'reserved-area',
        }
      },
      /** SPA - end */

      /** SIM - start */
      {
        type: 'text',
        size: 'full',
        title: 'Asset Management Platform',
        body: `<p>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla sed
              quam in orci scelerisque dignissim.</p>`,
        url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
        cover: '/assets/images/tmp_AR_SIM_Asset.png',
        link: {
          label: 'Login',
          url: 'https://am.advisor.prometeia.net/flex/cm/pages/CommunityLoginOut.php/L/EN/BL/aHR0cDovL2FtLmFkdmlzb3IucHJvbWV0ZWlhLm5ldC9mbGV4L2NtL3BhZ2VzL1NlcnZlQkxPQi5waHAvU1VfL2hvbWU%3D',
        }
      },
      {
        type: 'text',
        size: 'full',
        title: 'Fondi pensione',
        body: `<p>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla sed
              quam in orci scelerisque dignissim.</p>`,
        url: 'https://appia.prometeia.com/storage/assets/images/MP-01.png',
        cover: '/assets/images/tmp_AR_SIM_Pensioni.png',
        link: {
          label: 'Login',
          route: 'reserved-area',
        }
      }
      /** SIM - end */
    ]
  }


export const HEADER_DATA__MOCK: IHeaderRow[] = [
    {slug: 'expertise', label: 'EXPERTISE', children: [
        {slug: 'exp_rpc',  label: 'Risk, Planning & Control', children: [
            {slug: 'advanced-analytics', label: 'Advanced Analytics & Modeling'},
            {slug: 'exp_rpc_2', label: 'ALM & Liquidity & Treasury Risk '},
            {slug: 'exp_rpc_3', label: 'Credit Intelligence, Optimization & Strategy'},
            {slug: 'exp_rpc_4', label: 'Credit Risk & Credit Decision'},
            {slug: 'exp_rpc_5', label: 'Cyber & Operational Risks'},
            {slug: 'exp_rpc_6', label: 'ESG & Sustainability for Risk Managers'},
            {slug: 'exp_rpc_7', label: 'Pricing & Financial Engineering'},
            {slug: 'exp_rpc_8', label: 'Risk & Performance Integration'},
        ]},
        {slug: 'exp_wmfd', label: 'Wealth Management & Financial Distribution', children: [
            {slug: 'exp_wmfd_1', label: 'ESG & Sustainability for Wealth Managers'},
            {slug: 'exp_wmfd_2', label: 'Front Office Processes'},
            {slug: 'exp_wmfd_3', label: 'Market Analysis'},
            {slug: 'exp_wmfd_4', label: 'Product Governance & Innovation'},
            {slug: 'exp_wmfd_5', label: 'Regulation & Compliance'},
            {slug: 'exp_wmfd_6', label: 'Service Models'},
            {slug: 'exp_wmfd_7', label: 'Training & Change Management'},
        ]},
        {slug: 'exp_rbir', label: 'Research, BI & Reporting', children: [
            {slug: 'exp_rbir_1', label: 'Banking'},
            {slug: 'exp_rbir_2', label: 'Commodities'},
            {slug: 'exp_rbir_3', label: 'ESG & Climate Risk'},
            {slug: 'exp_rbir_4', label: 'Financial Services Analysis'},
            {slug: 'exp_rbir_5', label: 'Global Forecasting'},
            {slug: 'exp_rbir_6', label: 'Industry & Supply Chains'},
            {slug: 'exp_rbir_7', label: 'Macro & Regulatory Scenarios'},
        ]},
        {slug: 'exp_ds',   label: 'Data Science', children: [
            {slug: 'exp_ds_1', label: 'AI for Legal'},
            {slug: 'exp_ds_2', label: 'Data Driven Strategy'},
            {slug: 'exp_ds_3', label: 'Data Literacy'},
            {slug: 'exp_ds_4', label: 'Digital Marketing & CVM'},
            {slug: 'exp_ds_5', label: 'Generative AI Advisory & Adoption'},
            {slug: 'exp_ds_6', label: 'Intelligent Process Automation'},
            {slug: 'exp_ds_7', label: 'Payment Fraud Detection'},
            {slug: 'exp_ds_8', label: 'Synthetic Data Generation'},
        ]},
        {slug: 'exp_sacf', label: 'Strategic Advisory & Corporate Finance', children: [
            {slug: 'exp_sacf_1', label: 'Strategic Advisory & Transformation'},
            {slug: 'exp_sacf_2', label: 'Merger & Acquisitions'},
        ]},
    ]},
    {slug: 'solutions', label: 'SOLUTIONS', children: [
        {slug: 'sol_sui',  label: 'Suites', children: [
            {slug: 'sol_sui_1', label: 'Anti Money Laundering'},
            {slug: 'sol_sui_2', label: 'Asset Management'},
            {slug: 'sol_sui_3', label: 'Balance Sheet Risk Management'},
            {slug: 'sol_sui_4', label: 'Banking-Corporate Advisory'},
            {slug: 'sol_sui_5', label: 'Insurance & Protection'},
            {slug: 'sol_sui_6', label: 'Loan Origination & Credit Decision'},
            {slug: 'sol_sui_7', label: 'Model Development, Validation and Governance'},
            {slug: 'sol_sui_8', label: 'Operational & IT Risk Management'},
            {slug: 'sol_sui_9', label: 'Risk & Financial Planning'},
            {slug: 'sol_sui_10', label: 'Wealth Management'},
        ]},
        {slug: 'sol_ds',   label: 'Data Services', children: [
            {slug: 'sol_ds_1', label: 'ESG Data'},
            {slug: 'sol_ds_2', label: 'Financial Data'},
        ]},
        {slug: 'sol_ai',   label: 'AI Services', children: [
            {slug: 'sol_ai_1', label: 'Automatic Car Damage Estimation'},
            {slug: 'sol_ai_2', label: 'Insurance Fraud Detection'},
            {slug: 'sol_ai_3', label: 'Intelligent Document Processing'},
            {slug: 'sol_ai_4', label: 'Transactional Analytics'},
        ]},
        {slug: 'sol_da',   label: 'Delivery Approach', children: [
            {slug: 'sol_da_1', label: 'SAAS & Cloud'},
        ]},
    ] },
    {slug: 'customers', label: 'CUSTOMERS', children: [
        {slug: 'cus_main', label: '', children: [
            {slug: 'cus_main_1', label: 'Asset Managers'},
            {slug: 'cus_main_2', label: 'Banks'},
            {slug: 'cus_main_3', label: 'Corporations'},
            {slug: 'cus_main_4', label: 'Insurance'},
            {slug: 'cus_main_5', label: 'Public Sector'},
            {slug: 'cus_main_6', label: 'Wealth Managers & Financial Advisors'},
        ]},
    ] },
    {slug: 'careers', label: 'CAREERS', children: [
        {slug: 'car_main', label: '', children: [
            {slug: 'car_main_1', label: 'Our Tribe'},
            {slug: 'car_main_2', label: 'Open Positions'},
        ]},
    ] },
    {slug: 'about_us', label: 'ABOUT US', children: [
        {slug: 'abo_main', label: '', children: [
            {slug: 'abo_main_1', label: 'Insights'},
            {slug: 'abo_main_2', label: 'Our World'},
            {slug: 'abo_main_3', label: 'Corporate Social Responsibility'},
        ]},
    ] },
    {slug: 'sim', label: 'ADVISOR SIM', url: 'https://www.prometeia.com/sim'},
];
