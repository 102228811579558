import { Injectable } from '@angular/core';
import { HeaderDataService } from './header-data.service';
import { SEOService } from './seo.service';
import { Debounce } from 'lodash-decorators';
import { beautifyString, sleep } from './utilities.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor(
    private readonly headerDataService: HeaderDataService,
    private readonly seo: SEOService,
    private readonly gaService: GoogleAnalyticsService,
  ) { }

  public update(url: string) {
    const urlArray = url.split("/").filter(Boolean);
    urlArray.forEach((item, index) => urlArray[index] = item.toLowerCase().split("?")[0]);
    // console.log(urlArray);

    if (urlArray[0] === 'sim') {
      const [sim, locale, baseRoute, slug, subSlug] = urlArray;
      
      // console.log(locale, baseRoute, slug, subSlug);

      if (baseRoute === "archive" && slug === "anteo") {
        this.setTitle("", false, url, "Anteo");
      } else if (baseRoute === "archive" && slug === "in-formation-paths") {
        this.setTitle("", false, url, "Information Paths");
      } else if (baseRoute === "page" && slug) {
        this.setTitle("", false, url, beautifyString(slug));
      } else if (baseRoute === "article") {
        this.setTitle(this.getArticleTitle(slug), true, url);
      } else {
        this.setTitle(subSlug ?? slug ?? baseRoute ?? "SIM Home", !subSlug && !slug && !baseRoute, url);
      }
    } else {
      const [locale, baseRoute, slug, subSlug, articleID] = urlArray;
      
      // console.log(locale, baseRoute, slug, subSlug);

      if (baseRoute === "careers" && slug === "open-positions" && subSlug) {
        this.setTitle(slug, false, url, undefined, subSlug);
      } else if (baseRoute === "about-us" && slug === "insights" && subSlug === "article") {
        const cleanedArticleID = articleID.slice(0, articleID.lastIndexOf("-"));
        this.setTitle(slug, false, url, undefined, cleanedArticleID);
      } else if (baseRoute === "article") {
        this.setTitle(this.getArticleTitle(slug), true, url);
      } else {
        this.setTitle(subSlug ?? slug ?? baseRoute ?? "Home", !subSlug && !slug && !baseRoute, url);
      }
    }
  }
   
  private getArticleTitle(slug: string) {
    switch (slug.toLowerCase()) {
      case "cookies": return "Cookie Settings & Policy";
      case "ethics":  return "Code of Ethics & Model 231";
      case "quality": return "Quality Policy";
      default:
        if (slug.toLowerCase().endsWith("-sim"))
          return beautifyString(slug).replace(" Sim", " SIM");
        else
          return beautifyString(slug);
    }
  }

  private async setTitle(slug: string, isHome: boolean = false, url: string, forcedTitle?: string, articleID?: string) {
    const pageLabel: string = forcedTitle ?? (isHome ? ("Prometeia | " + slug) : (await this.headerDataService.findLabelBySlug(slug) ?? beautifyString(slug) ?? "Prometeia")) + (articleID ? " | " + beautifyString(articleID) : "");

    // console.log("setTitle:", "\n\tslug", slug, "\n\tisHome", isHome, "\n\turl", url, "\n\tforcedTitle", forcedTitle, "\n\tpageLabel", pageLabel);

    const isTitleChanged = document.title !== pageLabel;
    // console.log("isTitleChanged", isTitleChanged, pageLabel, "------", document.title);

    document.title = pageLabel;
    this.seo.updateTitle(pageLabel);
    if (slug === "Home") {
      this.seo.addHomeDescription();
    } else {
      this.seo.removeDescription();
    }

    // if (isTitleChanged) {
    //   this.pageView(url, pageLabel);
    // } /* else {
    //   console.log("Title not changed");
    // } */
  }

  // @Debounce(500)
  // private pageView(url: string, pageLabel: string) {
  //   // console.log("pageView", url, pageLabel);
  //   /** https://www.npmjs.com/package/ngx-google-analytics */
  //   this.gaService.pageView(pageLabel);
  // }
}
