import { Component, Input, OnInit } from '@angular/core';
import { ISitePageFlippingCard } from '../../models/common';

@Component({
  selector: 'app-flipping-card',
  templateUrl: './flipping-card.component.html',
  styleUrl: './flipping-card.component.scss',
  standalone: true,
  imports: [],
})
export class FlippingCardComponent implements OnInit {
  @Input() card?: ISitePageFlippingCard;
  @Input() cardIndex: number = -1;

  get cardFrontTitle() {
    return this.card?.title?.includes('&') ? this.card?.title : this.card?.title?.replace(/ /g, '<br>');
  }

  ngOnInit(): void {
    this.formatCard();
  }

  private formatCard() {
    if (!this.card?.title || !this.card.title.includes('&')) return;
    this.card.title = this.card?.title?.replace(/ &/g, '<br>&');
  }
}
