import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
  standalone: true
})
export class KebabCasePipe implements PipeTransform {

  transform(value: string | undefined, ...args: unknown[]): string {
    if (typeof value !== 'string') {
      return '';
    }

    if (args?.length && args[0] === 'remove') {
      return value.toLowerCase().replace(/-/g, ' ');
    }

    return value.toLowerCase().replace(/\s+/g, '-');
  }

}
