@if (isBannerVisible && !isCookiesPage) {
    <div class="block-all"></div>
    
    <div class="privacy-and-cookies {{bannerOpenStateClass}}">
        <div class="privacy-and-cookies--content">
            <div class="flex-vertical-centered">
                <div class="privacy-and-cookies--main">
                    <div class="privacy-and-cookies--title">
                        <p class="font__inter_bold text--deep-blue">{{ 'COOKIES.TITLE' | translate }}</p>
                    </div>
                    <div class="privacy-and-cookies--text">
                        <p class="text--on-light">
                            {{ 'COOKIES.TEXT' | translate }}
                            <a class="text--electric-blue" [routerLink]="['/', translate.currentLang, 'article', 'cookies']" target="_blank">link</a>.
                        </p>
                    </div>
                </div>
    
                <div>
                    <div class="privacy-and-cookies--simple-links">
                        <div class="privacy-and-cookies--simple-links--preferences">
                            <a class="text--on-light font__inter_regular interactive underline capslock" (click)="preferences()">
                                {{ 'COOKIES.SETTINGS' | translate }}
                            </a>
                        </div>
                    </div>
                    
                    <div class="privacy-and-cookies--buttons">
                        <button class="privacy-and-cookies--buttons--accept capslock interactive" (click)="accept()">
                            {{ 'COOKIES.ACCEPT' | translate }}
                        </button>
        
                        <button class="privacy-and-cookies--buttons--necessary-cookies-only capslock interactive" (click)="accept(true)">
                            {{ 'COOKIES.CONTINUE_WITH_MIN' | translate }}
                        </button>
                    </div>
                </div>
            </div>

    
            <!-- <div class="privacy-and-cookies--links">
                <a class="text--green privacy-and-cookies--links--purposes-link" href="#">Finalità</a>
                <p>|</p>
                <a class="text--green privacy-and-cookies--links--partners-link" href="#">Partner</a>
                <p>|</p>
                <a class="text--green privacy-and-cookies--links--legitimate-link" href="#">Legittimo interesse</a>
            </div> -->
        </div>
    </div>
}

@if (isDetailVisible || cookieService.forceReopenCookieModal) {
    <div class="pac-overlay"></div>
    <div class="pac-detail">
        <div class="pac-detail--content">
            <div class="close-icon interactive" (click)="closeDetail()">
                <mat-icon aria-hidden="false" aria-label="close" fontIcon="close"></mat-icon>
            </div>
            
            <div class="top">
                <img class="logo" src="{{logo_scuro}}">
                <h3 class="font__inter_medium text--deep-blue title">
                    {{ 'COOKIES.BANNER_TITLE' | translate }}
                </h3>
                <!-- <div class="close interactive" (click)="closeDetail()">
                    <img class="icon-box-chiudi_x interactive" src="../../../assets/icons-svg/svg-icon-X-chiusura_scura.svg">
                </div> -->
            </div>
    
            <div class="cookies-list font__inter_regular">
                <div class="cookies-list--item">
                    @let isOpen0 = isCookieDetailOpen(0);
                    <div class="flex-row flex-row--space-between">
                        <div class="flex-row">
                            <div class="control">
                                <p class="control--toggle interactive" (click)="toggleCookieDetailOpenState(0)">
                                    @if (!isOpen0) {
                                        <mat-icon class="toggle">expand_more</mat-icon>
                                    }
                                    @else {
                                        <mat-icon class="toggle">expand_less</mat-icon>
                                    }
                                </p>
                            </div>
                            <p class="cookie-group-label text--deep-blue bold interactive" (click)="toggleCookieDetailOpenState(0)">
                                {{ 'COOKIES.NECESSARY_COOKIES_TITLE' | translate }}
                            </p>
                        </div>

                        <div class="mat-slide-container">
                            <mat-slide-toggle [(ngModel)]="cookiePreferenceV2[0]" [disabled]="true"></mat-slide-toggle>
                        </div>
                    </div>
                    
                    @if (isOpen0) {
                        <p class="cookies-list--item--text justified">
                            {{ 'COOKIES.NECESSARY_COOKIES_TEXT' | translate }}
                        </p>
                        <div class="detail">
                            <!-- <p>Fornire accesso sicuro</p>
                            <p>Ricordare a che punto dell’ordine ci si trova</p> -->
        
                            @for (item of functionalCookies; let funcIndex = $index; track funcIndex;) {
                                <app-cookie-detail [item]="item"></app-cookie-detail>
                            }
                        </div>
                    }
                </div>
    
                <div class="cookies-list--item">
                    @let isOpen1 = isCookieDetailOpen(1);
                    <div class="flex-row flex-row--space-between">
                        <div class="flex-row">
                            <div class="control">
                                <p class="control--toggle interactive" (click)="toggleCookieDetailOpenState(1)">
                                    @if (!isOpen1) {
                                        <mat-icon class="toggle">expand_more</mat-icon>
                                    }
                                    @else {
                                        <mat-icon class="toggle">expand_less</mat-icon>
                                    }
                                </p>
                            </div>
                            <p class="cookie-group-label text--deep-blue bold interactive" (click)="toggleCookieDetailOpenState(1)">
                                {{ 'COOKIES.STAT_COOKIES_TITLE' | translate }}
                            </p>
                        </div>

                        <!-- <div>
                            <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(1) > 0}" (click)="setCookiePreference(1, true)">{{'YES' | translate}}</div>
                            <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(1) < 0}" (click)="setCookiePreference(1, false)">{{'NO' | translate}}</div>
                        </div> -->

                        <div class="mat-slide-container">
                            <mat-slide-toggle [(ngModel)]="cookiePreferenceV2[1]" (click)="updateSubCookiePreference(1)"></mat-slide-toggle>
                        </div>
                    </div>

                    @if (isOpen1) {
                        <p class="cookies-list--item--text justified">
                            {{ 'COOKIES.STAT_COOKIES_TEXT' | translate }} 
                        </p>
                        <div class="detail">
                            <!-- <p>Ricordare i dati di accesso</p>
                            <p>Ricordare cosa c’è nel carrello</p>
                            <p>Assicurare che il sito abbia un aspetto coerente</p> -->
        
                            @for (item of performanceCookies; let perfIndex = $index; track perfIndex;) {
                                <app-cookie-detail [item]="item" (editSingleCookiePref)="updateParentCookiePreference(1, $event)"></app-cookie-detail>
                            }
                        </div>
                    }
                </div>
    
                <div class="cookies-list--item">
                    @let isOpen2 = isCookieDetailOpen(2);
                    <div class="flex-row flex-row--space-between">
                        <div class="flex-row">
                            <div class="control">
                                <p class="control--toggle interactive" (click)="toggleCookieDetailOpenState(2)">
                                    @if (!isOpen2) {
                                        <mat-icon class="toggle">expand_more</mat-icon>
                                    }
                                    @else {
                                        <mat-icon class="toggle">expand_less</mat-icon>
                                    }
                                </p>
                            </div>
                            <p class="cookie-group-label text--deep-blue bold interactive" (click)="toggleCookieDetailOpenState(2)">
                                {{ 'COOKIES.MARKETING_COOKIES_TITLE' | translate }}
                            </p>
                        </div>

                        <!-- <div>
                            <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(2) > 0}" (click)="setCookiePreference(2, true)">{{'YES' | translate}}</div>
                            <div [ngClass]="{'cookie-button control--button capslock': true, 'selected': isCookieAccepted(2) < 0}" (click)="setCookiePreference(2, false)">{{'NO' | translate}}</div>
                        </div> -->

                        <div class="mat-slide-container">
                            <mat-slide-toggle [(ngModel)]="cookiePreferenceV2[2]" (click)="updateSubCookiePreference(2)"></mat-slide-toggle>
                        </div>
                    </div>

                    <!-- <ul>
                        <li><p>IP anonimizzati</p></li>
                        <li><p>accettazione emendamento Google (Google Analytics Data Processing Amendment)</p></li>
                        <li><p>nessuna meta profilazione (remarketing, signals etc.)</p></li>
                    </ul> -->
                    @if (isOpen2) {
                        <p class="cookies-list--item--text justified">
                            {{ 'COOKIES.MARKETING_COOKIES_TEXT' | translate }}
                        </p> 
                        <div class="detail">
                            <!-- <p>Ricordare i dati di accesso</p>
                            <p>Ricordare cosa c’è nel carrello</p>
                            <p>Assicurare che il sito abbia un aspetto coerente</p> -->

                            @for (item of advertisingCookies; let advIndex = $index; track advIndex;) {
                                <app-cookie-detail [item]="item" (editSingleCookiePref)="updateParentCookiePreference(2, $event)"></app-cookie-detail>
                            }
                        </div>
                    }
                </div>
            </div>
    
            <div class="bottom">
                <div class="update-preferences-button cookie-button" (click)="send()">
                    <p class="font__inter_medium">
                        {{ 'COOKIES.SEND_PREF' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
}
