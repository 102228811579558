import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { scrollToTop, getYoutubeID } from '../services/utilities.service';
import { Subscription } from 'rxjs';
import { IMediaSectionItem } from '../models/common';
import { PageRoots } from '../models/pages';
import { TranslateService } from '@ngx-translate/core';

const ROOT_SLUG: any = '';

@Component({
  selector: 'app-base-site-page',
  template: '',
  standalone: true,
  imports: [],
})
export class BaseSitePageComponent {

  /** will be overridden in children */
  readonly pageRoot: PageRoots = ROOT_SLUG;

  get slug() {
    return this.activatedRoute.snapshot.paramMap.get('slug');
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }

  activatedRouteParamMapSubscription?: Subscription;

  loading: boolean = false;
  pageData?: any;
  pageDataErrorCode?: number;

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly api: ApiService,
    protected readonly translate: TranslateService,
  ) { }

  ngOnInit(): void {
    scrollToTop();
    
    this.activatedRouteParamMapSubscription = this.activatedRoute.paramMap.subscribe((paramMap) => {
      if (paramMap.has('slug')) {
        this.loadPage();
        scrollToTop();
      }

      if (paramMap.has('lang')) {
        const lang = paramMap.get('lang');
        if (lang) {
          this.translate.use(lang);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteParamMapSubscription?.unsubscribe();
  }

  async loadPage() {
    // console.log(ROOT_SLUG, "->", this.activatedRoute.snapshot.paramMap.get('slug'));

    try {
      this.pageData = await this.api.pages.get({
        root: this.pageRoot,
        slug: this.slug || ''
      });

      this.formatPage();
    } catch (ex: any) {
      console.error(ex);
      this.pageData = undefined;
      this.pageDataErrorCode = ex.status;
    }
  }

  protected formatPage() {
    this.formatMainTitle();
    this.editYoutubeVideoLinks();
  }

  private formatMainTitle() {
    if (!this.pageData?.title || !this.pageData?.title?.includes('&')) return;
    this.pageData.title = this.pageData.title?.replace(/ &/g, '<br>&');
    // console.log(this.pageData.title);
  }

  private editYoutubeVideoLinks() {
    this.pageData?.mediaSection?.items.forEach((item: IMediaSectionItem) => {
      if (item.type === 'video') {
        // console.log("YT ID:", getYoutubeID(item.url));
        item.url = `https://www.youtube-nocookie.com/embed/${getYoutubeID(item.url)}`;
      }
    });
  }
}
