import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BaseSitePageComponent } from '../../base-site-page.component';
import { ISitePageNews, PageRoots } from '../../../models/pages';
import { SectionNewsHeadlinesComponent } from "../../../components/section-news-headlines/section-news-headlines.component";
import { PromAnimatedButtonComponent } from "../../../components/prom-animated-button/prom-animated-button.component";
import { SectionContactUsComponent } from "../../../components/section-contact-us/section-contact-us.component";
import { NewslettersComponent } from "../../../components/newsletters/newsletters.component";
import { IShipSelectorSelection, SelectorShipComponent } from "../../../components/selector-ship/selector-ship.component";
import { ISelector, NewsCategoriesEnum, NewsTypesEnum } from '../../../models/common';
import { beautifyString } from '../../../services/utilities.service';
import { DotsLoaderComponent } from "../../../components/dots-loader/dots-loader.component";
import { PapersListComponent } from "../../../components/papers-list/papers-list.component";
import { INITIAL_NEWS_LIMIT, NEWS_LIMIT_INCREMENT } from '../../../models/constants';

const ROOT_SLUG: PageRoots = 'about-us';

@Component({
  selector: 'app-insights-page',
  templateUrl: './insights-page.component.html',
  styleUrl: './insights-page.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatProgressSpinnerModule, SectionNewsHeadlinesComponent, PromAnimatedButtonComponent, SectionContactUsComponent, NewslettersComponent, SelectorShipComponent, DotsLoaderComponent, PapersListComponent],
})
export class InsightsPageComponent extends BaseSitePageComponent implements OnInit {

  override readonly pageRoot: PageRoots = ROOT_SLUG;

  override get slug() {
    return "insights";
  }

  loadingNews = false;

  selectors: ISelector[] = [
    { name: 'Topic', options: [] },
    { name: 'Category', options: Object.values(NewsCategoriesEnum).map(category => ({ name: beautifyString(category) })) },
    { name: 'Type', options: Object.values(NewsTypesEnum).map(type => ({ name: beautifyString(type) })) },
  ];

  newsList?: ISitePageNews = { items: [] }

  override ngOnInit(): void {
    this.staticSlugInit();

    this.loadNews();
    this.loadTopics();
  }

  async loadTopics() {
    try {
      const topics: string[] = await this.api.pages.insightsTopics();

      this.selectors[0].options = topics.map((topic) => ({ name: topic }));
    } catch (ex) {
      console.error(ex);
      this.selectors[0].options = [];
    }
  }

  newsLimit: number = 0;
  canLoadMore: boolean = true;
  lastSelectedTags: {topics?: string, categories?: string, types?: string} = {};
  lastStringSearch?: string;
  async loadNews(skip: number = 0, limit: number = INITIAL_NEWS_LIMIT) {
    this.loadingNews = true;

    try {
      const filters = this.lastStringSearch ? { skip, limit: limit + 1, q: this.lastStringSearch } : { skip, limit: limit + 1, ...this.lastSelectedTags };
      const news: ISitePageNews = await this.api.pages.insights(filters);
      this.newsLimit = Math.min(limit, news.items.length) + skip;
      this.canLoadMore = limit < news.items.length;

      if (!this.newsList) {
        this.newsList = news;
      } else {
        this.newsList.items = this.newsList.items.concat(news.items);
      }
      
      if (this.canLoadMore) {
        this.newsList.items.pop();
      }
    } catch (ex) {
      console.error(ex);
      this.canLoadMore = false;
    } finally {
      this.loadingNews = false;
    }
  }

  onLoadMoreClick() {
    this.loadingNews = true;

    this.loadNews(this.newsList?.items?.length, NEWS_LIMIT_INCREMENT);
  }

  onTagsSelectionChanged(event: IShipSelectorSelection[]) {
    this.lastSelectedTags = this.selectionToQueryParams(event);
    // console.log(event, this.selectionToQueryParams(event));
    this.reloadNews();
  }

  onStringSearch(event: string) {
    this.lastStringSearch = event;
    this.lastSelectedTags = {};
    this.selectors.forEach((s) => s.options.map((o) => o.selected = false));
    this.reloadNews();
  }

  onClearStringSearch() {
    this.lastStringSearch = undefined;
    this.reloadNews();
  }

  private reloadNews(): void {
    if (this.newsList) {
      this.newsList.items = [];
    }
    this.loadNews();
  }


  private selectionToQueryParams(selection: IShipSelectorSelection[]): {topics?: string, categories?: string, types?: string} {
    const validSelections = selection.filter((s: IShipSelectorSelection) => s.selected.length > 0);
    const res: any = {};
    validSelections.forEach((s: IShipSelectorSelection) => {
      const name = this.qweryParamsNameConversion(s.name);
      res[`${name}`] = s.selected.join(';');

      if (name === "categories" || name === "types") {
        res[`${name}`] = res[`${name}`].replaceAll(" ", "-").toLowerCase();
      }
    });
    return res;
  }

  private qweryParamsNameConversion(name: string): string {
    switch (name) {
      case "Topic": return "topics";
      case "Category": return "categories";
      case "Type": return "types";
      default: return "";
    }
  }
}

// const staticPageData: Partial<IAboutUsPageInsights> = {
//   category: 'Insights',
//   title: 'Explore perspectives, imagine possibilities, shape the future',

//   newsletters: {
//     items: [
//       {
//         title: "Trending Topics",
//         slug: "trending-topics",
//         body: "NEWSLETTERS.TRENDING_TOPICS_TEXT | translate",
//         img: "https://www.prometeia.3logic.it/cdn/tmp/trending_topics.png"
//       },
      
//       {
//         title: "FinTech News",
//         slug: "risk-news",
//         body: "NEWSLETTERS.FIN_TECH_NEWS_TEXT | translate",
//         img: "https://www.prometeia.3logic.it/cdn/upload/2022/10/1b72d911-0878-47d0-ac29-6ca0398b6b48.png"
//       },
      
//       {
//         title: "Anteo",
//         slug: "anteo",
//         body: "NEWSLETTERS.ANTEO_TEXT | translate",
//         img: "https://www.prometeia.3logic.it/cdn/tmp/anteo.png"
//       }
//     ]
//   }
// };
