<div class="footer">
    <div class="footer-content footer-content--top row">
        <div class="col first-column">
            <div class="logo-and-socials">
                <div class="logo-wrap">
                    <img class="logo" src="assets/images/logo_chiaro.png" [routerLink]="['/' + currentLang]"/>
                </div>
    
                <div class="socials">
                    @for (soc of socialsList; track $index) {
                        <a class="socials--link" href="{{soc.link}}" target="_blank">
                            <img src="/assets/icons/SOCIAL_{{soc.type}}.png" alt="soc.type">
                        </a>
                    }
                </div>
        
                <div class="btn-container contact-us">
                    <app-prom-animated-button [glowOnClick]="true" (click)="openContactDialog()">
                        {{'GENERAL.CONTACT_US' | translate | uppercase}}
                    </app-prom-animated-button>
                </div>
            </div>

            <div class="btn-container reserved-area">
                <!-- @if (showLogout) {
                    <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="logout()">
                        {{'AR.TITLE' | translate | uppercase}}
                    </app-prom-animated-button>
                }
                @else if (!isLoggedAR && !showLogout) {
                    <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="login()">
                        {{'AR.TITLE' | translate | uppercase}}
                    </app-prom-animated-button>
                }
                @else {
                    <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="resevedArea()">
                        {{'AR.TITLE' | translate | uppercase}}
                    </app-prom-animated-button>
                } -->

                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" [routerLink]="['/' + currentLang, 'reserved-areas']">
                    {{'AR.TITLE' | translate | uppercase}}
                </app-prom-animated-button>
            </div>
        </div>

        @if (headerData?.length) {
            <div class="col site-pages">
                @for (fl of filteredHeaderData; track $index) {
                    <div class="first-level-wrap {{fl.slug}}">
                        <p class="first-level--label">
                            {{fl.label | uppercase}}
                        </p>
    
                        <div [ngClass]="{'flex-row': fl.slug === 'expertise'}">
                            <div [ngClass]="{'second-level': true, 'active': false}">
                                <div [ngClass]="{'second-level-content': true}">
                                    @for (sl of getFirstLevelChildren(fl); track $index) {
                                        <div [ngClass]="{'second-level-column': true, 'grid-item': fl.slug === 'expertise'}">
                                            @if (sl.label) {
                                                <p class="second-level--label" [innerHTML]="sl.label | uppercase"></p>
                                            }
            
                                            <ul class="second-level-navigation-list">
                                                @for (element of sl.children; track element.slug) {
                                                    @if (element.slug) {
                                                        <li class="third-level-wrap" [routerLink]="['/' + currentLang, fl.slug, element.slug]">
                                                            <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                            @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                                <a class="third-level--label">{{labelPiece}}</a>
                                                            }
                                                        </li>
                                                    }
                                                    @else {
                                                        <li class="third-level-wrap">
                                                            <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                            @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                                <a class="third-level--label">{{labelPiece}}</a>
                                                            }
                                                        </li>
                                                    }
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
    
                            @if (fl.slug === 'expertise') {
                                <div [ngClass]="{'second-level': true, 'active': false}">
                                    <div [ngClass]="{'second-level-content': true}">
                                        @for (sl of getFirstLevelChildren(fl, 'second-half'); track $index) {
                                            <div [ngClass]="{'second-level-column': true, 'grid-item': fl.slug === 'expertise'}">
                                                @if (sl.label) {
                                                    <p class="second-level--label" [innerHTML]="sl.label | uppercase"></p>
                                                }
                
                                                <ul class="second-level-navigation-list">
                                                    @for (element of sl.children; track element.slug) {
                                                        @if (element.slug) {
                                                            <li class="third-level-wrap" [routerLink]="['/' + currentLang, fl.slug, element.slug]">
                                                                <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                                @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                                    <a class="third-level--label">{{labelPiece}}</a>
                                                                }
                                                            </li>
                                                        }
                                                        @else {
                                                            <li class="third-level-wrap">
                                                                <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                                @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                                    <a class="third-level--label">{{labelPiece}}</a>
                                                                }
                                                            </li>
                                                        }
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

                <div class="first-level-wrap others">
                    @for (fl of shortHeaderData; track $index) {
                        <p class="first-level--label">
                            @if (fl.children?.length) {
                                {{fl.label | uppercase}}
                            }
                            @else if (fl.url) {
                                <a href="{{fl.url}}">
                                    {{fl.label | uppercase}}
                                    <img class="external-link" src="/assets/icons/external_link__green.svg" alt="">
                                </a>
                            }
                        </p>
    
                        <div [ngClass]="{'second-level': true, 'active': false}">
                            <div [ngClass]="{'second-level-content': true}">
                                @for (sl of fl.children; track $index) {
                                    <div class="second-level-column">
                                        @if (sl.label) {
                                            <p class="second-level--label" [innerHTML]="sl.label | uppercase"></p>
                                        }
        
                                        <ul class="second-level-navigation-list">
                                            @for (element of sl.children; track element.slug) {
                                                @if (element.slug) {
                                                    <li class="third-level-wrap" [routerLink]="['/' + currentLang, fl.slug, element.slug]">
                                                        <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                        @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                            <a class="third-level--label">{{labelPiece}}</a>
                                                        }
                                                    </li>
                                                }
                                                @else {
                                                    <li class="third-level-wrap">
                                                        <!-- <a class="third-level--label" [innerHTML]="element.label"></a> -->
                                                        @for (labelPiece of getThirdLevelLabel(element); track $index) {
                                                            <a class="third-level--label">{{labelPiece}}</a>
                                                        }
                                                    </li>
                                                }
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>

    <div class="footer-content separator"><hr></div>

    <div class="footer-content footer-content--bottom flex-row flex-row--space-between">
        <div class="copyright">
            <p>{{currentYear}} © Prometeia All rights reserved</p>
            <p>Prometeia s.p.a. P.IVA 03118330376</p>
        </div>

        <div class="flex-row">
            @if (footerData?.length) {
                <div class="footer-links">
                    @for (footerLink of footerData; track $index) {
                        <p class="grid-item">
                            <a href="/{{currentLang}}/{{footerLink.route}}" target="_blank">{{footerLink.label}}</a>
                            <img src="/assets/icons/external_link__green.svg" alt="" class="external-link">
                        </p>
                    }
                </div>
            }

            <div class="award-logo-wrap">
                <img class="logo" src="/assets/images/LEED_badge_NEG.png"/>
            </div>

            <div class="actions">
                <div class="scroll-to-top" (click)="scrollToTop()">
                    <!-- <mat-icon class="scroll-to-top--icon" aria-hidden="false" aria-label="scroll to top" fontIcon="expand_less"></mat-icon> -->
                    <div class="scroll-to-top--icon arrow">
                        <img class="simplified-arrow-up" src="/assets/icons/down_simplified_arrow__green.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile-footer">
    <div class="footer-content">
        <div class="logo-wrap">
            <img class="logo" src="assets/images/logo_chiaro.png" [routerLink]="['/' + currentLang]"/>
        </div>

        <div class="socials">
            @for (soc of socialsList; track $index) {
                <a class="socials--link" href="{{soc.link}}" target="_blank">
                    <img src="/assets/icons/SOCIAL_{{soc.type}}.png" alt="">
                </a>
            }
        </div>

        <div class="btn-container contact-us">
            <app-prom-animated-button [glowOnClick]="true" (click)="openContactDialog()">
                {{'GENERAL.CONTACT_US' | translate | uppercase}}
            </app-prom-animated-button>
        </div>

        @if (headerData?.length) {
            <div class="site-pages" #sitePages>
                @for (fl of headerData; track $index) {
                    @if (fl.children?.length) {
                        <p (click)="toggleSitePages($index, sitePages)" class="site-pages--label">
                            {{fl.label | uppercase}}
                            @if ($index === activeSitePagesFirstLevelIndex) {
                                <mat-icon class="open-state-icon" fontIcon="expand_less"></mat-icon>
                            }
                            @else {
                                <mat-icon class="open-state-icon" fontIcon="expand_more"></mat-icon>
                            }
                        </p>
                    }
                    @else if (fl.url) {
                        <p class="site-pages--label">
                            <a href="{{fl.url}}">
                                {{fl.label | uppercase}}
                                <img class="external-link" src="/assets/icons/external_link__green.svg" alt="">
                            </a>
                        </p>
                    }

                    <div [ngClass]="{'second-level': true, 'active': $index === activeSitePagesFirstLevelIndex}">
                        <div [ngClass]="{'second-level-content': true}">
                            @for (sl of fl.children; track $index) {
                                <div class="second-level-column">
                                    @if (sl.label) {
                                        <p class="second-level--label" [innerHTML]="sl.label | uppercase"></p>
                                    }

                                    <ul class="second-level-navigation-list">
                                        @for (element of sl.children; track element.slug) {
                                            @if (element.slug) {
                                                <li class="third-level-wrap" [routerLink]="['/' + currentLang, fl.slug, element.slug]" (click)="activeSitePagesFirstLevelIndex = undefined;">
                                                    <a class="third-level--label" [innerHTML]="element.label"></a>
                                                </li>
                                            }
                                            @else {
                                                <li class="third-level-wrap">
                                                    <a class="third-level--label" [innerHTML]="element.label"></a>
                                                </li>
                                            }
                                        }
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        }

        <div class="btn-container reserved-area">
            <!-- @if (showLogout) {
                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="logout()">
                    {{'AR.TITLE' | translate | uppercase}} out
                </app-prom-animated-button>
            }
            @else if (!isLoggedAR && !showLogout) {
                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="login()">
                    {{'AR.TITLE' | translate | uppercase}}
                </app-prom-animated-button>
            }
            @else {
                <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" (click)="resevedArea()">
                    {{'AR.TITLE' | translate | uppercase}}
                </app-prom-animated-button>
            } -->

            <app-prom-animated-button [arrow]="'right'" [bgColor]="'dark'" [routerLink]="['/' + currentLang, 'reserved-areas']">
                {{'AR.TITLE' | translate | uppercase}}
            </app-prom-animated-button>
        </div>

        @if (footerData?.length) {
            <div class="footer-links">
                @for (footerLink of footerData; track $index) {
                    <p>
                        <a href="/{{currentLang}}/{{footerLink.route}}" target="_blank">{{footerLink.label}}</a>
                        <img src="/assets/icons/external_link__green.svg" alt="" class="external-link">
                    </p>
                }
            </div>
        }

        <div class="award-logo-wrap">
            <img class="logo" src="/assets/images/LEED_badge_NEG.png"/>
        </div>

        <div class="copyright">
            <p>{{currentYear}} © Prometeia All rights reserved</p>
            <p>Prometeia s.p.a. P.IVA 03118330376</p>
        </div>
    </div>
</div>
