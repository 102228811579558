import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { PromAnimatedButtonComponent } from "../prom-animated-button/prom-animated-button.component";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dialog-notice',
  templateUrl: './dialog-notice.component.html',
  styleUrl: './dialog-notice.component.scss',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule, TranslateModule, PromAnimatedButtonComponent],
})
export class DialogNoticeComponent {

  data?: any;
  
  get canOnlyClose(): boolean {
    return this.data?.canOnlyClose ?? false;
  }

  constructor(
    public readonly dialogRef: MatDialogRef<DialogNoticeComponent>
  ) {
    this.data = inject(MAT_DIALOG_DATA);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
