@if (card) {
    <div class="p-card default-card-border size-{{size}} theme--{{theme}} {{(card.download || size === 'full') ? 'with-arrow' : ''}}" data-aos="fade-up">
        <div class="p-card--header">
            <h4 class="p-card--title">{{card.title}}</h4>
        </div>
        
        <div class="p-card--content">
            @if (card.body.startsWith('<p>')) {
                <div class="inner-html--p-card--body p-card--body" [innerHTML]="card.body"></div>
            }
            @else {
                <div class="inner-html--p-card--body p-card--body"><p [innerHTML]="card.body"></p></div>
            }
        </div>

        @if (card.download || size === 'full') {
            <div class="p-card--footer">
                @if (card.download) {
                    <div class="download-icon download-icon-{{arrowColor}}">
                        <div class="arrow-wrap">
                            <a [href]="card.download.url" target="_blank" [download]="card.download.label">
                                <img class="arrow" src="/assets/icons/right_arrow__{{arrowColor}}.svg" alt="download_arrow">
                            </a>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}
