@if (pageData) {
    <div class="page insights-article {{categoryCssClass}}">
        <div class="cover-colored-filter"></div>
        
        <div class="article-and-related">
            <div class="title-box"></div>

            <div class="article-content">
                <div class="title"><h1 [innerHTML]="pageData.title"></h1></div>
                <div class="subtitle"><h2 [innerHTML]="pageData.subtitle"></h2></div>
    
                <div class="body inner-html inner-html--insights-article" data-aos="fade-up" [innerHTML]="pageData.body"></div>
                
                @if (pageData.attachment) {
                    <div class="download-btn">
                        <a [href]="pageData.attachment" target="_blank" download>
                            <app-prom-animated-button class="button" [theme]="'deep-blue'" [bgColor]="'light'" [arrow]="'down'">
                                {{'GENERAL.DOWNLOAD' | translate | uppercase}}
                            </app-prom-animated-button>
                        </a>
                    </div>
                }
            </div>
    
            <div class="related-wrapper">
                <div class="related-list"></div>
            </div>
        </div>
    </div>
}
@else {
    <div class="page-loading"></div>
}
